import { Tabs, Tab, styled, Box } from "@mui/material";

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(15),
  color: theme.palette.primary.main,
  paddingLeft: 40,
  paddingRight: 40,
  borderBottom: "1px solid #e0e0e0",
}));

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export { CustomTabs, CustomTab, TabPanel };
