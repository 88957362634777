import { createSlice } from "@reduxjs/toolkit";
import { LocalStorageService } from "services/LocalStorageService";
import { initializeApp } from "./authSlice";
const service = new LocalStorageService();

export interface SettingsState {
  selectedCountryId: number | null;
}

const initialState: SettingsState = {
  selectedCountryId: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSelectedCountry: (state, action) => {
      service.setItem("selectedCountry", action.payload);
      state.selectedCountryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeApp.fulfilled, (state, action) => {
      const { selectedCountryId } = action.payload;
      state.selectedCountryId = selectedCountryId;
    });
  },
});

export const { setSelectedCountry } = settingsSlice.actions;

export default settingsSlice;
