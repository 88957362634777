import { Box, Button, Card, Modal, TextField, Typography, useTheme } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import chaletApi from "api/chaletApi";
import toast from "react-hot-toast";

type CreateDriverModalProps = {
  handleClose: () => void;
  isOpen: boolean;
};

type CreateDriverForm = {
  name: string;
  phone: string;
  email: string;
  tmpPassword: string;
};

const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

const validationSchema = yup.object({
  name: yup.string().required("Nombre es requerido"),
  phone: yup.string().max(9, "8 digits max").phone("SV", "numero invalido").required("telefono requerido"),
  email: yup.string().email("Email invalido").required("Email es requerido"),
  tmpPassword: yup.string().required("Contraseña es requerida"),
});

const CreateDepositModal: React.FC<CreateDriverModalProps> = ({ handleClose, isOpen }) => {
  const [createDriver] = chaletApi.useCreateDriverMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateDriverForm>({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      tmpPassword: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const createNewDriver = async (newDriver: CreateDriverForm) => {
    try {
      await createDriver({
        name: newDriver.name,
        phone: newDriver.phone,
        email: newDriver.email,
        password: newDriver.tmpPassword,
      })
        .unwrap()
        .then(() => {
          handleClose();
          reset();

          toast.success("Driver creado exitosamente");
        })
        .catch(() => {
          toast.error("Error al crear driver");
        });
    } catch (err) {
      toast.error("Error al crear driver");
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        elevation={4}
        sx={{
          p: 3,
          width: "100%",
          maxWidth: 500,
          mx: 2,
          boxShadow: 3,
        }}
      >
        <Box component="form" onSubmit={handleSubmit(createNewDriver)} noValidate>
          <Typography variant="h5" mb={2} textAlign="center">
            Crear nuevo driver
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              required
              fullWidth
              label="Nombre"
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <TextField
              type="tel"
              inputMode="numeric"
              required
              fullWidth
              label="Teléfono"
              {...register("phone")}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              onChange={(event) => {
                const { value } = event.target;
                event.target.value = MaskNumber(value);
              }}
            />
            <TextField
              required
              fullWidth
              label="Email"
              type="email"
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <TextField
              required
              fullWidth
              label="Contraseña temporal"
              {...register("tmpPassword")}
              error={!!errors.tmpPassword}
              helperText={errors.tmpPassword?.message}
            />
            <Button type="submit" variant="contained" color="primary">
              Crear driver
            </Button>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

export default CreateDepositModal;
