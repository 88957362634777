import { configureStore } from "@reduxjs/toolkit";
import chaletApi from "../api/chaletApi";
import authSlice from "./authSlice";
import settingsSlice from "./settingsSlice";
import sectorsSlice from "./sectorsSlice";
import branchSlice from "./branchSlice";
import shopSlice from "./shopSlice";
import modalSlice from "./modalSlice";
import moduleSlice from "./moduleSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    settings: settingsSlice.reducer,
    sectors: sectorsSlice.reducer,
    branch: branchSlice.reducer,
    shop: shopSlice.reducer,
    modalSlice: modalSlice.reducer,
    module: moduleSlice.reducer,
    [chaletApi.reducerPath]: chaletApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }).concat(chaletApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
