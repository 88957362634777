import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.locale("es");
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const toCurrencyFormat = (n: number) => {
  return formatter.format(n);
};

export const toDate = (str: string) => {
  return new Date(str);
};

export const formatDateWithTime = (dateString: string) => {
  return dayjs(dateString).format("DD/MM/YYYY hh:mm a");
};

export const formatTime = (timestamp: number) => {
  const formattedTime = dayjs.unix(timestamp).utc().format("hh:mm A");
  return formattedTime;
};

export const formatCustomDate = (fechaOriginal: string) => {
  const fecha = dayjs(fechaOriginal);

  dayjs.locale("es");

  const fechaFormateada = fecha.format("DD [de] MMM, h:mm A");

  return fechaFormateada;
};

export const formatDate = (isoDate: string) => {
  const formattedDate = dayjs(isoDate).utc().startOf("day").format("MM-DD-YYYY");
  return formattedDate;
};
