import { useEffect, useState } from "react";
import io from "socket.io-client";
import { Order } from "types/Order";
import shopIcon from "../assets/ic_store.svg";
import DriverIcon from "../assets/ic_marker_driver.svg";

type MapType = {
  id: string;
  coordinates: [number, number];
  icon?: string;
};

type Location = {
  latitude: number;
  longitude: number;
};

const getSocket = () => {
  return io(process.env.REACT_APP_BASE_URL as string);
};

export const useDeliverySocket = (order: Order) => {
  const destinationLocation: MapType = {
    id: "destination",
    coordinates: [order.route.destination.location.coordinates[0], order.route.destination.location.coordinates[1]],
  };

  const branchLocation: MapType = {
    id: "branch",
    coordinates: [order.branch.location.coordinates[0], order.branch.location.coordinates[1]],
    icon: shopIcon,
  };

  const [driverMapMarker, setDriverMapMarker] = useState<MapType | null>(null);
  const [socket, setSocket] = useState<any>(null);
  const isDelivery = order.currentOrderProcessStep.correlative === 5;
  const mapMarkers: MapType[] = [destinationLocation, branchLocation];

  useEffect(() => {
    const socket = getSocket();
    socket.on("connect", () => {
      setSocket(socket);
      if (isDelivery) {
        console.log("connected, waiting for driver location");
        listenDriverUpdates(order.id, socket);
      }
    });

    return () => {
      socket.disconnect();
      console.log("Socket disconnected");
    };
  }, [order]);

  const onDriverUpdate = (locationUpdate: Location) => {
    setDriverMapMarker({
      id: "driver",

      coordinates: [locationUpdate.longitude, locationUpdate.latitude] as [number, number],
      icon: DriverIcon,
    });
  };

  const listenDriverUpdates = (orderId: number, socket: any) => {
    console.log("listening for driver updates on order", "delivery_" + orderId);
    socket.emit("join", "delivery_" + orderId);
    socket.on("update_driver_location", (location: Location) => {
      onDriverUpdate(location);
    });
  };

  if (driverMapMarker) {
    mapMarkers.push(driverMapMarker);
  }

  return mapMarkers;
};
