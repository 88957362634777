import React, { useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { OrderDetail } from "types/Order";
import { toCurrencyFormat } from "utils/formatter";
import { Delete, Edit } from "@mui/icons-material";
import { EditOrderForm } from "./EditOrderForm";
import chaletApi from "api/chaletApi";
import toast from "react-hot-toast";
type OrderDetailsProps = {
  details: OrderDetail[];
};
export const OrderDetailsView: React.FC<OrderDetailsProps> = ({ details }) => {
  const [editItems, setEditItems] = useState<number[]>([]);
  const [deleteOrderDetail] = chaletApi.useDeleteProductInOrderMutation();

  const groupedVariations: Record<number, Record<string, { value: string; addedValue: number }[]>> = {};
  details.forEach((orderDetail) => {
    if (!groupedVariations[orderDetail.id]) {
      groupedVariations[orderDetail.id] = {};
    }
    orderDetail.variations?.forEach((variation) => {
      const variationName = variation.option.variation.name;
      if (!groupedVariations[orderDetail.id][variationName]) {
        groupedVariations[orderDetail.id][variationName] = [];
      }
      groupedVariations[orderDetail.id][variationName].push(variation.option);
    });
  });

  const handleEdit = (itemId: number) => {
    setEditItems((prevEditItems) =>
      prevEditItems.includes(itemId) ? prevEditItems.filter((id) => id !== itemId) : [...prevEditItems, itemId],
    );
  };

  const handleDelete = async (itemId: number) => {
    try {
      await deleteOrderDetail(itemId);

      toast.success("Producto eliminado de la orden");
    } catch (error) {
      toast.error("Error al eliminar el producto de la orden");
    }
  };

  return (
    <>
      {details.map((orderDetail) => (
        <Box display="flex" textAlign="left" key={orderDetail.id}>
          <Box flex={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box display={"flex"} key={orderDetail.id}>
                <Box flex={1}>
                  <Typography sx={{ fontSize: 14 }} fontWeight={"bold"} gutterBottom>
                    {orderDetail.name} ({toCurrencyFormat(orderDetail.price)}) x {orderDetail.quantity}
                  </Typography>

                  {orderDetail.variations && orderDetail.variations.length > 0 && (
                    <div>
                      {Object.entries(groupedVariations[orderDetail.id]).map(([variationName, options]) => (
                        <Typography key={variationName} sx={{ fontSize: 12 }} color="text.secondary">
                          {variationName}:{" "}
                          {options.map((option, index) => `${option.value} ($${option.addedValue})`).join(", ")}
                        </Typography>
                      ))}
                    </div>
                  )}
                  <Typography sx={{ fontSize: 12 }} color={"text.secondary"} gutterBottom>
                    Notas: {orderDetail.notes ? orderDetail.notes : "Sin notas adicionales"}
                  </Typography>
                </Box>
              </Box>
              {!editItems.includes(orderDetail.id) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flex: 1,
                    gap: 1,
                    p: 2,
                  }}
                >
                  <Button variant="outlined" color="primary" onClick={() => handleEdit(orderDetail.id)}>
                    <Edit />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDelete(orderDetail.id)}
                    disabled={details.length <= 1}
                  >
                    <Delete />
                  </Button>
                </Box>
              ) : (
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flex: 1,
                    p: 2,
                  }}
                >
                  <Button variant="outlined" color="primary" onClick={() => handleEdit(orderDetail.id)}>
                    Cancelar
                  </Button>
                </Container>
              )}
            </Box>
            {editItems.includes(orderDetail.id) && orderDetail.variations && (
              <EditOrderForm onClick={() => handleEdit(orderDetail.id)} detail={orderDetail} key={orderDetail.id} />
            )}
          </Box>
        </Box>
      ))}
    </>
  );
};
