import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EF3D32",
      light: "#EF3D3277",
    },
    secondary: {
      main: "#01869E",
    },
    text: {
      primary: "#333333",
    },
    common: {
      black: "#333333",
      white: "#FFFFFF",
    },
    background: {
      default: "#E5E5E5",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    body1: {},
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: { textTransform: "none" },
      },
    },
  },
});

export default theme;
