import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Sector } from "types/Sector";
import { BranchCard } from "./BranchCard";

type InvoicesGeneratedProps = {
  sector: Sector;
};

const InvoicesGenerated: React.FC<InvoicesGeneratedProps> = ({ sector }) => {
  const branches = sector.branches;

  if (!branches) return <CircularProgress />;

  return (
    <Box display="flex" flex={1}>
      <Box flex={1}>
        <Typography variant="h4" mb={2}>
          Facturas generadas por sucursal
        </Typography>

        <Box mt={2}>
          {branches.map((branch) => (
            <BranchCard key={branch.id} branch={branch} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default InvoicesGenerated;
