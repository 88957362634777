import { GoogleMap, Marker } from "@react-google-maps/api";
import { useDeliverySocket } from "hooks/useDeliverySocket";
import React, { useEffect, useMemo, useState } from "react";
import { Order } from "types/Order";

type MapMarker = {
  id: string;
  coordinates: [number, number];
  icon?: string;
};

type Props = {
  order: Order;
  isEditingLocation: boolean;
  onDestinationMarkerUpdate: (marker: MapMarker) => void;
};

export const OrderMap: React.FC<Props> = ({ order, isEditingLocation, onDestinationMarkerUpdate }) => {
  const mapMarkers = useDeliverySocket(order);

  const [destinationMarker, setDestinationMarker] = useState<MapMarker | null>(null);

  const finalMarkers = useMemo<MapMarker[]>(() => {
    return mapMarkers.map((marker) => {
      if (marker.id === "destination" && destinationMarker) {
        return { ...marker, ...destinationMarker };
      }
      return marker;
    });
  }, [mapMarkers, destinationMarker]);

  useEffect(() => {
    if (destinationMarker) {
      onDestinationMarkerUpdate(destinationMarker);
    }
  }, [destinationMarker, onDestinationMarkerUpdate]);

  const center = useMemo(
    () => ({
      lat: 14.04127919725104,
      lng: -88.93810918198251,
    }),
    [],
  );

  const handleMarkerDragEnd = (event: google.maps.MapMouseEvent, markerId: string) => {
    if (markerId !== "destination") return;
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();
    if (lat != null && lng != null) {
      setDestinationMarker({
        id: "destination",
        coordinates: [lng, lat],
        icon: "/static/media/ic_store.2bf7e6127270355c6b1a75814b0af546.svg",
      });
    }
  };

  return (
    <GoogleMap mapContainerStyle={{ width: "100%", height: "500px" }} center={center} zoom={14}>
      {finalMarkers.map((marker) => (
        <Marker
          key={marker.id}
          position={{ lat: marker.coordinates[1], lng: marker.coordinates[0] }}
          icon={marker.icon}
          draggable={isEditingLocation && marker.id === "destination"}
          onDragEnd={(event) => handleMarkerDragEnd(event, marker.id)}
        />
      ))}
    </GoogleMap>
  );
};
