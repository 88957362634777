import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { OrderDetail } from "types/Order";
import { Box, Button, TextField } from "@mui/material";
import chaletApi from "api/chaletApi";

type EditOrderFormProps = {
  detail: OrderDetail;
  onClick: () => void;
};

export const EditOrderForm: React.FC<EditOrderFormProps> = ({ detail, onClick }) => {
  const [updateOrder] = chaletApi.useUpdateOrderDetailByIdMutation();
  const [quantity, setQuantity] = useState(detail.quantity);

  const schema = yup.object().shape({
    quantity: yup
      .number()
      .required("La cantidad es requerida")
      .positive("La cantidad debe ser positiva")
      .integer("La cantidad debe ser un número entero"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("quantity", quantity);
  }, [quantity, setValue]);

  const onSubmit = (data: { quantity: number }) => {
    updateOrder({
      id: detail.id,
      body: {
        productId: detail.product.id,
        quantity: data.quantity,
        notes: detail.notes,
        name: detail.product.name,
        productPrice: detail.product.price,
        variations: detail.variations,
      },
    })
      .unwrap()
      .then(() => {
        setQuantity(data.quantity);

        toast.success("Cantidad actualizada");

        onClick();
      })
      .catch(() => {
        toast.error("Error al actualizar la cantidad");
      });
  };

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={2}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{ mt: 1 }}
    >
      <TextField
        label="Cantidad"
        variant="outlined"
        fullWidth
        type="number"
        {...register("quantity")}
        error={!!errors.quantity}
        helperText={errors.quantity?.message}
        value={quantity}
        onChange={(e) => setQuantity(parseInt(e.target.value))}
      />
      <Button type="submit" variant="contained">
        Guardar
      </Button>
    </Box>
  );
};
