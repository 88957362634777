import React, { useState } from "react";
import { CloudUpload, Delete, Description, Title } from "@mui/icons-material";
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import chaletApi from "api/chaletApi";

type CreateProductCategory = {
  name: string;
  description: string;
};

type CreateProductCategoryProps = {
  productTypeId: number;
  handleClose: () => void;
};

const schema = yup.object().shape({
  name: yup.string().required("El título es requerido"),
  description: yup.string().required("La descripción es requerida"),
});

const CreateProductCategory: React.FC<CreateProductCategoryProps> = ({ productTypeId, handleClose }) => {
  const [createProductCategory, { isLoading }] = chaletApi.useCreateProductCategoryMutation();

  const [image, setImage] = useState<File | undefined>(undefined);
  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateProductCategory>({
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: yupResolver(schema),
  });

  const handleIconImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const clearIconImage = () => {
    setImage(undefined);
    setPreviewImage(undefined);
  };

  const handleCreateProductCategory = async (data: CreateProductCategory) => {
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("image", image as Blob);
    formData.append("productTypeId", productTypeId.toString());

    toast.promise(createProductCategory(formData).unwrap(), {
      loading: "Creando tipo de producto de sector...",
      success: "Tipo de producto de sector creado exitosamente",
      error: "Error al crear el tipo de producto de sector",
    });

    clearIconImage();

    reset();

    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        component="form"
        onSubmit={handleSubmit(handleCreateProductCategory)}
        noValidate
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: 150,
              height: 150,
            }}
          >
            <Avatar
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: 2,
              }}
              src={previewImage}
            >
              {!previewImage && (
                <Button
                  component="label"
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                  }}
                >
                  <CloudUpload style={{ color: "white" }} />
                  <input type="file" onChange={handleIconImage} style={{ display: "none" }} />
                </Button>
              )}
            </Avatar>
            {previewImage && (
              <IconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "error.main",
                }}
                onClick={clearIconImage}
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nombre"
                variant="outlined"
                fullWidth
                required
                {...register("name")}
                error={!!errors.name}
                helperText={errors.name?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Title />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descripción"
                variant="outlined"
                fullWidth
                required
                {...register("description")}
                error={!!errors.description}
                helperText={errors.description?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          type="submit"
          variant="contained"
          sx={{
            mt: 4,
            width: "50%",
            alignSelf: "center",
            mb: 4,
          }}
          disabled={!previewImage || isLoading}
        >
          Crear
        </Button>
      </Box>
    </>
  );
};

export default CreateProductCategory;
