import { Box, Typography, useTheme } from "@mui/material";
import { GoogleMap, InfoWindow, Marker, Polygon } from "@react-google-maps/api";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Shift } from "types/Shift";
import ActiveDrivers from "../ActiveDrivers";
import chaletApi from "api/chaletApi";
import { useDriversSocket } from "hooks";
import driverIcon from "../../../../../../../../assets/ic_marker_driver.svg";
import { Sector } from "types/Sector";
import { DriverSchedule } from "types/DriverSchedule";

type Props = {
  shift: Shift;
  sector: Sector;
};

interface MapType {
  driverId: number;
  coordinates: [number, number];
}

export const DriversMap: React.FC<Props> = ({ shift, sector }) => {
  const { data: driverSchedule } = chaletApi.useGetDriverSchedulesQuery(shift.id);
  const [mapCenter, setMapCenter] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [mapMarkers, setMapMarkers] = useState<MapType[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<DriverSchedule | null>(null);
  const locations = useDriversSocket(driverSchedule || [], sector.id);
  const theme = useTheme();

  const sectorPolygon = sector.area.map((point) => ({
    lat: point[1],
    lng: point[0],
  }));
  const mapRef = useRef<any>(null);

  useEffect(() => {
    console.log("1");
    const center = sectorPolygon.reduce(
      (acc, { lat, lng }) => {
        acc.lat += lat;
        acc.lng += lng;
        return acc;
      },
      { lat: 0, lng: 0 },
    );

    const data = {
      lat: center.lat / sectorPolygon.length,
      lng: center.lng / sectorPolygon.length,
    };
    if (driverSchedule) {
      const markers = driverSchedule.map(({ driver }) => ({
        driverId: driver.id,
        coordinates: driver.lastKnownLocation.coordinates,
      }));
      setMapMarkers(markers);
    }

    setMapCenter(data);
  }, [driverSchedule]);

  useEffect(() => {
    console.log("2");
    if (locations) {
      setMapMarkers((prevMarkers) => {
        const existingMarkerIndex = prevMarkers.findIndex((marker) => marker.driverId === locations.driverId);
        if (existingMarkerIndex !== -1) {
          const updatedMarkers = [...prevMarkers];
          updatedMarkers[existingMarkerIndex] = locations;
          return updatedMarkers;
        } else {
          return [...prevMarkers, locations];
        }
      });
    }
  }, [locations]);

  const infoDriver = useMemo(() => {
    if (selectedDriver) {
      return mapMarkers.find((map) => map.driverId === selectedDriver.driverId);
    }
    return null;
  }, [selectedDriver]);

  const handleMarkerClick = (driverId: number) => {
    const driver = driverSchedule ? driverSchedule.find((d) => d.driverId === driverId) : null;
    if (driver) {
      setSelectedDriver(driver);
    }
  };
  console.log("DriversMap.tsx", mapMarkers);

  const handleCenterChanged = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      setMapCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
    }
  };

  if (!mapCenter) {
    return null;
  }
  return (
    <GoogleMap
      options={{ fullscreenControl: false, streetViewControl: false }}
      center={mapCenter}
      zoom={12}
      mapContainerStyle={{ width: "100%", height: "600px" }}
      onDragEnd={handleCenterChanged}
      onLoad={(map) => {
        mapRef.current = map;
      }}
    >
      {mapMarkers.map((marker) => (
        <Marker
          key={marker.driverId}
          position={{ lat: marker.coordinates[1], lng: marker.coordinates[0] }}
          icon={driverIcon}
          onClick={() => handleMarkerClick(marker.driverId)}
        />
      ))}

      <Polygon
        options={{
          fillColor: "rgba(239, 61, 50, 0.35)",
          strokeColor: theme.palette.primary.main,
        }}
        editable={false}
        draggable={false}
        path={sectorPolygon}
      />
      <Box
        sx={{
          display: "flex",
          maxWidth: "90%",
          overflowX: "auto",
          position: "absolute",
          bottom: 0,
          p: 1,
        }}
      >
        {driverSchedule && <ActiveDrivers driverSchedule={driverSchedule} setSelectedDriver={setSelectedDriver} />}
      </Box>

      {selectedDriver && infoDriver && (
        <InfoWindow
          key={selectedDriver.driver.id}
          position={{
            lat: infoDriver.coordinates[1],
            lng: infoDriver.coordinates[0],
          }}
          onCloseClick={() => setSelectedDriver(null)}
        >
          <>
            <Typography variant="h6">{selectedDriver.driver.user.name}</Typography>
          </>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};
