import React, { useEffect, useMemo, useRef, useState } from "react";
import { io } from "socket.io-client";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, Paper, Card, IconButton, CircularProgress } from "@mui/material";
import chaletApi from "api/chaletApi";
import { Message } from "types/Message";

interface ChatComponentProps {
  orderId: string;
  userId: string;
  onClose: () => void;
}

const ChatComponent: React.FC<ChatComponentProps> = ({ orderId, onClose, userId }) => {
  const [getChatMessages, { isLoading, data }] = chaletApi.useLazyGetChatMessagesQuery();
  const [messages, setMessages] = useState<Message[]>([]);
  const socket = useMemo(() => {
    const socket = io(process.env.REACT_APP_BASE_URL as string);
    return socket;
  }, []);

  const participantsById = useMemo(() => {
    const clientUser = data?.data.order.user;
    const driverUser = data?.data.order.route.driver?.user;
    if (driverUser && clientUser) {
      const participants = new Map<number, { name: string; role: string }>();
      participants.set(clientUser.id, { name: clientUser.name, role: "Cliente" });
      participants.set(driverUser.id, { name: driverUser.name, role: "Repartidor" });
      return participants;
    } else {
      return new Map<number, { name: string; role: string }>();
    }
  }, [data]);
  console.log("participantsById", participantsById);

  const chatBoxRef = useRef<HTMLDivElement>(null);

  const handleNewMessage = (newMessage: Message) => {
    setMessages((prev) => [...prev, newMessage]);
  };

  useEffect(() => {
    socket.on("connect", () => {
      socket.emit("join_order_chat", { orderId, userId });
      socket.on("newMessage", handleNewMessage);
    });
    return () => {
      socket.off("newMessage", handleNewMessage);
    };
  }, [orderId, userId, socket]);

  useEffect(() => {
    getChatMessages(orderId)
      .unwrap()
      .then((data) => {
        setMessages(data.data.messages);
      })
      .catch((error) => {
        console.error("Error al cargar el historial del chat:", error);
      });
  }, [orderId, userId, getChatMessages]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 600,
        backgroundColor: "#fff",
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      <Card elevation={3}>
        <Box
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            height: "500px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Typography variant="h5" sx={{ textAlign: "center", color: "#ef3d32" }}>
              Chat en Tiempo Real
            </Typography>
            <IconButton onClick={onClose} sx={{ color: "#ef3d32" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Paper
            ref={chatBoxRef}
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              backgroundColor: "#f5f5f5",
              borderRadius: 2,
              padding: 2,
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              marginBottom: 2,
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                }}
                padding={4}
              >
                <Box>
                  <CircularProgress size={100} color="primary" />
                </Box>
              </Box>
            ) : messages.length > 0 ? (
              messages.map((msg, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: 1.5,
                  }}
                >
                  <Box
                    sx={{
                      padding: 1.5,
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      borderBottomRightRadius: "16px",
                      backgroundColor: "#e0e0e0",
                      color: "#333",
                      maxWidth: "75%",
                      wordWrap: "break-word",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.875rem",
                        marginBottom: 0.5,
                        color: "#333",
                      }}
                    >
                      {participantsById.get(parseInt(msg.userId))?.name} -{" "}
                      {participantsById.get(parseInt(msg.userId))?.role}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: "1rem" }}>
                      {msg.message}
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                No hay mensajes en el chat
              </Typography>
            )}
          </Paper>
        </Box>
      </Card>
    </Box>
  );
};

export default ChatComponent;
