import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSelectedCountry } from "redux/settingsSlice";

const useSelectedCountry = () => {
  const { selectedCountryId } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const changeCountry = (newCountryId: number) => {
    dispatch(setSelectedCountry(newCountryId));
  };
  return {
    selectedCountryId,
    changeCountry,
  };
};

export default useSelectedCountry;
