import {
  Toolbar,
  Typography,
  Box,
  Avatar,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Button,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useUser from "hooks/useUser";
import useSelectedCountry from "hooks/useSelectedCountry";
import chaletApi from "api/chaletApi";
import { useEffect } from "react";
import { ChaletAdminRoutes, Roles } from "constants/index";

/* interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  appbarwidth: number;
} */

/* const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, appbarwidth }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${appbarwidth}px)`,
    marginLeft: `${appbarwidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
})); */

const AppBarComponent = styled(MuiAppBar)(({ theme }) => ({}));

type AppBarTypes = {
  children: React.ReactNode;
  setIsOpen: (isOpen: boolean) => void;
  width: number;
};

const AppBar = ({ children, width }: AppBarTypes) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { selectedCountryId, changeCountry } = useSelectedCountry();
  const { data: countries } = chaletApi.useGetCountriesQuery();

  const handleChange = (event: SelectChangeEvent) => {
    changeCountry(Number(event.target.value as string));
  };

  useEffect(() => {
    if (countries && countries.length > 0) {
      changeCountry(countries[0].id);
    }
  }, [countries]);

  const goTo = (route: string) => () => {
    navigate(route, { replace: false });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBarComponent sx={{ zIndex: 3 }}>
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              <Button
                onClick={goTo("/admin/")}
                sx={{
                  textTransform: "none",
                }}
              >
                <Typography variant="h6" sx={{ color: "white" }}>
                  El Chalet Admin
                </Typography>
              </Button>

              <Grid
                item
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                {user?.sectorWork.role.key === Roles.CHALETADMIN ? (
                  ChaletAdminRoutes.map(({ name, route }) => {
                    return (
                      <Button
                        key={route}
                        variant="text"
                        onClick={goTo(route)}
                        style={{
                          color: "white",
                          fontSize: 16,
                          textTransform: "none",
                        }}
                      >
                        {name}
                      </Button>
                    );
                  })
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Box>
                <FormControl>
                  <Select
                    sx={{ color: "white" }}
                    value={String(selectedCountryId)}
                    label="Country"
                    onChange={handleChange}
                  >
                    {countries &&
                      countries.map(({ id, name }) => {
                        return (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ bgcolor: "#212121" }}>{user?.name.charAt(0)}</Avatar>
                <Typography sx={{ color: "white" }}>{user?.name}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBarComponent>
      <Box paddingTop={5} flex={1}>
        {children}
      </Box>
    </Box>
  );
};

export default AppBar;
