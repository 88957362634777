import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Grid, Button, Card, Stack, Typography, Box, Snackbar, Alert } from "@mui/material";
import { FormTextInput } from "components/common";
import useSelectedSector from "hooks/useSelectedSector";
import chaletApi from "api/chaletApi";
import { handleApiError } from "utils";
import { DriverWithUser } from "types/Driver";
import { User } from "types/User";

const driverSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  phone: yup.string().required("Phone is required"),
  email: yup.string().email("Email must be a valid format").required("Email is required"),
  password: yup.string().required("Password is required"),
});

type Props = {
  driver?: DriverWithUser;
  onCancel: () => void;
};

type DriverFormType = Omit<
  DriverWithUser,
  "id" | "createdAt" | "updatedAt" | "user" | "isBusy" | "fcmToken" | "lastKnownLocation" | "currentDeliveryOfferId"
> &
  Pick<User, "name" | "phone" | "email"> & {
    password: string;
  };

const CreateEditDriver: React.FC<Props> = ({ driver, onCancel }) => {
  const { selectedSectorId } = useSelectedSector();
  const [createDriver, createResult] = chaletApi.useCreateDriverMutation();
  const [open, setOpen] = useState(false);
  const useYupValidationResolver = (validationSchema: yup.ObjectSchema<DriverFormType>) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {},
            ),
          };
        }
      },
      [validationSchema],
    );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<DriverFormType>({
    defaultValues: driver
      ? {
          ...driver,
          password: "",
        }
      : {
          name: "",
          email: "",
          phone: "",
          password: "",
        },
    resolver: useYupValidationResolver(driverSchema),
  });

  const onSubmit = async (data: DriverFormType) => {
    console.log("Driver DATA: ", data);
    try {
      await createDriver(data).unwrap();
      onCancel();
    } catch (error) {
      console.log("Error", error);
      setOpen(true);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const errorMessage = useMemo(() => {
    return handleApiError(createResult.error);
  }, [createResult]);

  console.log(errors);
  return (
    <>
      <Grid container alignItems="center" justifyContent="center" height="100%">
        <Grid item sm={12} md={8} height="80%" justifyContent="center">
          <Card
            sx={{
              top: "10%",
              bottom: "10%",
              height: "100%",
              p: 2,
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
              <Stack sx={{ height: "100%" }} gap={2}>
                <Box>
                  <Typography variant="h5">Create Driver</Typography>
                </Box>
                <Stack gap={2} flex={1} sx={{ overflow: "scroll" }}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item sm={12}>
                      <Stack gap={1}>
                        <FormTextInput control={control} label="Name" gridSize={12} name="name" />
                        <FormTextInput control={control} label="Email" gridSize={12} name="email" />
                        <FormTextInput control={control} label="Phone" gridSize={12} name="phone" />
                        <FormTextInput control={control} label="Password" gridSize={12} name="password" />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Stack alignItems="center" justifyContent="flex-end" pb={2} gap={2} direction="row">
                  <Button type="submit" variant="outlined" color="primary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateEditDriver;
