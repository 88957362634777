import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import React, { useState, useEffect } from "react";

type Props = {
  originCoordinates: google.maps.LatLngLiteral | null;
  destinationCoordinates: google.maps.LatLngLiteral | null;
};

export const TravelMap: React.FC<Props> = ({ originCoordinates, destinationCoordinates }) => {
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);

  useEffect(() => {
    if (originCoordinates && destinationCoordinates) {
      const directionsService = new google.maps.DirectionsService();

      directionsService.route(
        {
          origin: originCoordinates,
          destination: destinationCoordinates,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error("Error fetching directions", status);
          }
        },
      );
    }
  }, [originCoordinates, destinationCoordinates]);

  return (
    <GoogleMap
      center={originCoordinates || { lat: 14.040564740651831, lng: -88.9359117588296 }}
      zoom={15}
      mapContainerStyle={{ height: "700px", width: "100%" }}
    >
      {directions && <DirectionsRenderer directions={directions} />}
    </GoogleMap>
  );
};
