import { Card, CardMedia, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { ProductType } from "types/ProductType";
import ProductCategoriesModal from "./ProductCategories/ProductCategoriesModal";

type SectorProductTypeCardProps = {
  productTypes: ProductType[];
};

const SectorProductTypeCard: React.FC<SectorProductTypeCardProps> = ({ productTypes }) => {
  const { palette } = useTheme();

  const [isOpenModal, setIsModalOpen] = useState(false);
  const [selectedProductTypeId, setSelectedProductTypeId] = useState<number | null>(null);

  const handleOpen = (productId: number) => {
    setSelectedProductTypeId(productId);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedProductTypeId(null);
  };

  return (
    <>
      {productTypes.map((productType) => (
        <Card
          key={productType.id}
          elevation={0}
          sx={{
            padding: 1,
            width: 150,
            height: 50,
            borderRadius: "12px",
            backgroundColor: "#F1DEDC",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            ":hover": {
              boxShadow: "0 0 0 0px #fff, 0 0 0 2px #ef3d32",
              cursor: "pointer",
            },
          }}
          onClick={() => handleOpen(productType.id)}
        >
          <CardMedia
            component="img"
            sx={{
              width: 40,
              height: 40,
              borderRadius: "10%",
            }}
            image={productType.icon}
            alt={productType.name}
          />
          <Typography fontWeight="bold" textAlign="center" color={palette.primary.main} fontSize="14px" flexGrow={1}>
            {productType.name}
          </Typography>
        </Card>
      ))}
      {selectedProductTypeId !== null && (
        <ProductCategoriesModal isOpen={isOpenModal} handleClose={handleClose} productTypeId={selectedProductTypeId} />
      )}
    </>
  );
};

export default SectorProductTypeCard;
