import { Alert, Box, Button, Card, CircularProgress, Modal, Typography, useTheme } from "@mui/material";
import chaletApi from "api/chaletApi";
import { FormTextInput } from "components/common";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { DriverWithUser } from "types/Driver";
import * as yup from "yup";
import DriverDepositView from "./DriverDepositView";
import toast from "react-hot-toast";

type CreateDepositModalProps = {
  handleClose: () => void;
  isOpen: boolean;
};

type SearchDriverForm = {
  id: string;
};

const validationSchema = yup.object({
  id: yup.number().min(0).required("required"),
});

const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async (data: any) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );

const CreateDepositModal: React.FC<CreateDepositModalProps> = ({ handleClose, isOpen }) => {
  const { palette } = useTheme();
  const [findDriverById, result] = chaletApi.useLazyFindDriverByIdQuery();
  const [searchStatus, setSearchStatus] = useState<"success" | "error" | null>(null);
  const [driver, setDriver] = useState<DriverWithUser | null>(null);

  const { control, handleSubmit, getValues, setValue } = useForm<SearchDriverForm>({
    defaultValues: {
      id: "",
    },
    resolver: useYupValidationResolver(validationSchema),
  });

  const values = getValues();

  const checkDriverExistence = async (data: SearchDriverForm) => {
    console.log("Searching " + data.id);
    try {
      const driverId = parseInt(data.id);
      if (!driverId) {
        return;
      }
      const findDriverResult = await findDriverById(driverId).unwrap();
      console.log(findDriverResult);
      setSearchStatus("success");
      setDriver(findDriverResult);
    } catch (error) {
      console.log(error);
      setSearchStatus("error");
    }
  };

  const onNewSearch = () => {
    setSearchStatus(null);
    setDriver(null);
    setValue("id", "");
  };

  const onDepositCompleted = () => {
    onNewSearch();
    toast.success("Deposito completado con exito");
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card elevation={4} sx={{ padding: 2, minWidth: 600 }}>
          <form onSubmit={handleSubmit(checkDriverExistence)} style={{ height: "100%" }}>
            <Box display="flex" gap={1} flexDirection="column">
              <Typography variant="h5" mb={1} textAlign="center">
                Realizar deposito de driver
              </Typography>
              <Box display="flex" flexDirection="row" alignItems="center" gap={4}>
                <FormTextInput
                  control={control}
                  label="ID"
                  gridSize={12}
                  name="id"
                  sx={{ flex: 1 }}
                  disabled={searchStatus != null}
                />
                {searchStatus === "success" && (
                  <Button
                    variant="contained"
                    onClick={onNewSearch}
                    sx={{
                      mt: 4,
                      alignSelf: "center",
                      mb: 4,
                    }}
                  >
                    Nueva busqueda
                  </Button>
                )}
                {searchStatus === "error" && (
                  <Button
                    variant="contained"
                    onClick={onNewSearch}
                    sx={{
                      mt: 4,
                      alignSelf: "center",
                      mb: 4,
                    }}
                  >
                    Nueva busqueda
                  </Button>
                )}
                {searchStatus === null && (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      mt: 4,
                      alignSelf: "center",
                      mb: 4,
                    }}
                  >
                    Buscar repartidor
                  </Button>
                )}
              </Box>
            </Box>
          </form>
          {result.isFetching ? (
            <Box display="flex" flex={1} justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {searchStatus === "error" && !result.isFetching && (
                <Alert severity="error">Usuario con ID ({values.id}) no encontrado</Alert>
              )}
              {searchStatus === "success" && !result.isLoading && driver && (
                <DriverDepositView driver={driver} onComplete={onDepositCompleted} />
              )}
            </>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default CreateDepositModal;
