import { useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import chaletApi from "api/chaletApi";
import { Branch } from "types/Branch";
import useSelectedSector from "hooks/useSelectedSector";
import { SubAppBar } from "components/partials";
import useSelectedBranch from "hooks/useSelectedBranch";
import { DashboardTab } from "./DashboardTab/DashboardTab";
import {
  Orders,
  Clients,
  Invoicing,
  Notifications,
  Dashboard,
  CustomerService,
  DevicesPage,
  ProductsServices,
  TransportDrivers,
  TravelsTab,
  VehicleTypes,
  DriversTab,
  SectorSchedule,
  DriversContainer,
  BranchesTab,
  DeliveriesTab,
  AffiliatePage,
  InvoicesGenerated,
} from "./DashboardTab/RightContainer";
import { ProtectedRoute } from "components/common";
import { ChaletRoles } from "hooks";

export default function SectorPage() {
  const params = useParams();
  const { data, isLoading } = chaletApi.useGetSectorQuery(Number(params?.id) ?? 0);
  const { changeSector } = useSelectedSector();
  const { selectedBranch, changeBranch } = useSelectedBranch();

  useEffect(() => {
    if (data) {
      changeSector(data);
    }
  }, [data]);

  const handleChangeBranches = (branch: Branch) => {
    changeBranch(branch);
  };

  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  if (data) {
    return (
      <>
        <SubAppBar data={data}>
          <Routes>
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute allowedRoles={[ChaletRoles.admin, ChaletRoles.sectorAdmin]}>
                  <DashboardTab />
                </ProtectedRoute>
              }
            >
              //nested routes
              <Route element={<ProtectedRoute allowedRoles={[ChaletRoles.admin, ChaletRoles.sectorAdmin]} />}>
                <Route index element={<Dashboard />} />
                <Route path="ordenes" element={<Orders sector={data} />} />
                <Route path="clientes" element={<Clients />} />
                <Route path="devices" element={<DevicesPage />} />
                <Route path="notificaciones" element={<Notifications />} />
                <Route path="facturacion" element={<Invoicing sector={data} />} />
                <Route path="facturas-generadas" element={<InvoicesGenerated sector={data} />} />
                <Route path="servicio-al-cliente" element={<CustomerService />} />
                <Route path="drivers" element={<DriversContainer />} />
                <Route path="activeOrders" element={<DeliveriesTab sector={data} />} />
                <Route path="liveDrivers" element={<DriversTab sector={data} />} />
                <Route path="shifts" element={<SectorSchedule />} />
                <Route
                  path="branches"
                  element={
                    <BranchesTab sector={data} selectedBranch={selectedBranch} changeBranch={handleChangeBranches} />
                  }
                />
                <Route path="products-services/*" element={<ProductsServices />} />
                <Route path="products-services/affiliate/:id" element={<AffiliatePage />} />
                //Chalet Ride
                <Route path="travels" element={<TravelsTab />} />
                <Route path="transportDrivers" element={<TransportDrivers />} />
                <Route path="vehicleTypes" element={<VehicleTypes />} />
              </Route>
            </Route>
          </Routes>
        </SubAppBar>
      </>
    );
  }
  return null;
}
