import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Sector } from "types/Sector";

export enum ModuleType {
  Orders = "Orders",
  Travels = "Travels",
}

export interface ModuleState {
  selectedModule: ModuleType;
}

const initialState: ModuleState = {
  selectedModule: ModuleType.Orders,
};

export const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    setSelectedModule: (state, action: PayloadAction<ModuleType>) => {
      state.selectedModule = action.payload;
    },
  },
});

export const { setSelectedModule } = moduleSlice.actions;

export default moduleSlice;
