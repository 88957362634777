import { useEffect, useState } from "react";
import { logOut } from "redux/authSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export enum ChaletRoles {
  admin = "chaletadmin",
  sectorAdmin = "sectoradmin",
  customerService = "customerservice",
}

const useUser = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [role, setRole] = useState<ChaletRoles | undefined>(undefined);
  const [sectorId, setSectorId] = useState<number | undefined>(undefined);
  const isLoggedIn = user !== null;

  useEffect(() => {
    if (user && user.sectorWork) {
      const { role: userRole } = user.sectorWork;
      switch (userRole?.key) {
        case "chaletadmin":
          setRole(ChaletRoles.admin);
          break;
        case "sectoradmin":
          setRole(ChaletRoles.sectorAdmin);
          setSectorId(user.sectorWork.sector.id);
          break;
        case "customerservice":
          setRole(ChaletRoles.customerService);
          setSectorId(user.sectorWork.sector.id);
          break;

        default:
          dispatch(logOut());
          break;
      }
    }
  }, [user]);

  return {
    isLoggedIn,
    user,
    role,
    sectorId,
  };
};

export default useUser;
