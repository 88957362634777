import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { formatDate, toCurrencyFormat } from "utils/formatter";
import { Order } from "types/Order";
import { Invoices } from "types/Invoices";
import InvoiceDetail from "./InvoiceDetail";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf",
      fontWeight: "semibold",
    },
    {
      src: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 24,
  },
  header: {
    flexDirection: "row",
    marginBottom: 24,
  },
  logo: {
    width: 50,
    height: 50,
  },
  documentInfo: {
    marginLeft: "auto",
    marginRight: 24,
    alignItems: "flex-end",
    color: "#fac4c1",
  },
  companyInfo: {
    marginLeft: 12,
    justifyContent: "flex-end",
  },
  companyText: {
    fontSize: 12,
    marginBottom: 4,
    color: "#EF3D32",
  },
  recipientText: {
    fontSize: 12,
    marginBottom: 4,
  },
  table: {
    display: "flex",
    width: "auto",
  },
  tableRow: {
    flexDirection: "row",
    paddingTop: 4,
    paddingBottom: 4,
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderBottomColor: "#EF3D32", // Primary color for header underline
    borderBottomWidth: 2,
    alignItems: "center",
    padding: 4,
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 10,
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  totalContainer: {
    marginTop: 24,
    textAlign: "right",
    paddingRight: 8,
  },
  totalText: {
    fontSize: 12,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    color: "#EF3D32",
  },
  ordersDetailsContainer: {
    paddingTop: 24,
    textAlign: "right",
    paddingRight: 8,
  },
  orderDetailsText: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Open Sans",
  },
});

type props = {
  invoice: Invoices;
};

export const InvoiceDocument: React.FC<props> = ({ invoice }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image
          style={styles.logo}
          src="https://res.cloudinary.com/dhzvygj7m/image/upload/v1657914936/ShopApp/Asset_1.png"
        />
        <View style={styles.companyInfo}>
          <Text style={styles.companyText}>El Chalet</Text>
          <Text style={styles.recipientText}>Plataforma Integral de Gestión Comercial</Text>
          <Text style={styles.recipientText}>Teléfono: 7237-5695</Text>
        </View>
        <View style={styles.documentInfo}></View>
      </View>

      <View style={styles.header}>
        <Image style={styles.logo} src={invoice.billingDetail.branch.profileImage} />
        <View style={styles.companyInfo}>
          <Text style={styles.recipientText}>Nombre o razón social: {invoice.billingDetail.name}</Text>
          <Text style={styles.recipientText}>Dirección Fiscal: {invoice.billingDetail.address}</Text>
          <Text style={styles.recipientText}>Documento Único de Identidad: {invoice.billingDetail.dui}</Text>
          <Text style={styles.recipientText}>Número de Identificación Tributaria: {invoice.billingDetail.nit}</Text>
          <Text style={styles.recipientText}>Número de Registro de Contribuyente: {invoice.billingDetail.nrc}</Text>
          <Text style={styles.recipientText}>Ordenes facturadas desde: {formatDate(invoice.fromDateOrders)}</Text>
          <Text style={styles.recipientText}>Ordenes facturadas hasta: {formatDate(invoice.toDateOrders)}</Text>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>ID</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Hora pedido</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Hora entrega</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Total</Text>
          </View>
        </View>
      </View>

      {invoice.orders.map((order) => {
        return <InvoiceDetail key={order.id} order={order} />;
      })}

      <InvoiceFooter orders={invoice.orders} invoice={invoice} />
    </Page>
  </Document>
);

type InvoiceFooterProps = {
  orders: Order[];
  invoice: Invoices;
};

const InvoiceFooter = ({ orders, invoice }: InvoiceFooterProps) => {
  const deliveryFee = invoice.subtotal * 0.1;
  const tax = deliveryFee * 0.13;
  const totalDeliveryFee = deliveryFee + tax;

  return (
    <>
      <View style={styles.ordersDetailsContainer}>
        <Text style={styles.orderDetailsText}>{orders.length} Ordenes</Text>
      </View>
      <View style={styles.totalContainer}>
        <Text style={styles.totalText}>Total ventas: {toCurrencyFormat(invoice.subtotal)} </Text>
        <Text style={styles.totalText}>Comisión (10%): {toCurrencyFormat(deliveryFee)}</Text>
        <Text style={styles.totalText}>Comisión IVA: {toCurrencyFormat(invoice.tax)}</Text>
        <Text style={styles.totalText}>Comisión + IVA: {toCurrencyFormat(totalDeliveryFee)}</Text>
        <Text style={styles.totalText}>Total a depositar: {toCurrencyFormat(invoice.total)}</Text>
      </View>
    </>
  );
};
