// hooks/useShiftForm.ts
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export const useCustomForm = <T extends Record<string, any>>(
  defaultValues: T,
  schema: yup.ObjectSchema<T>,
) => {
  const useYupValidationResolver = (validationSchema: yup.ObjectSchema<T>) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {},
            ),
          };
        }
      },
      [validationSchema],
    );
  const formMethods = useForm<T>({
    defaultValues: defaultValues as any,
    resolver: useYupValidationResolver(schema),
  });

  return {
    ...formMethods,
  };
};
