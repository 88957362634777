import React from "react";
import { TextField, Button, Grid, Box, Typography, InputAdornment, Checkbox, FormControlLabel } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useAppSelector } from "redux/hooks";
import chaletApi from "api/chaletApi";
import { Message, Send, Title } from "@mui/icons-material";

const schema = yup
  .object({
    title: yup.string().required("El título es requerido"),
    payload: yup.string().required("La payload es necesaria"),
    message: yup.string().required("El mensaje es requerido"),
  })
  .required();

type FormInputType = {
  title: string;
  message: string;
  payload: string;
};

// branchPromo | productPromo,
const defaultPayload = {
  sectorId: "",
  branchId: "",
  type: "",
  title: "",
  message: "",
  image: "",
};

export const Notifications: React.FC = () => {
  const { selectedSectorId } = useAppSelector((state) => state.sectors);
  const [checked, setChecked] = React.useState(false);
  const [createCustomNotificationBySector] = chaletApi.useCreateCustomNotificationBySectorIdMutation();

  console.log(selectedSectorId);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputType>({
    defaultValues: {
      payload: JSON.stringify(defaultPayload),
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormInputType> = (data) => {
    const type = checked ? "all" : "sector";
    createCustomNotificationBySector({
      sectorId: selectedSectorId,
      title: data.title,
      message: data.message,
      payload: data.payload,
      type,
    })
      .unwrap()
      .then(() => {
        toast.success("Notificación enviada correctamente");
      })
      .catch((error) => {
        toast.error("Error al enviar la notificación");
      });
  };

  return (
    <Box
      p={4}
      sx={{
        bgcolor: "background.paper",
        boxShadow: 3,
        borderRadius: 2,
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          fontWeight: 700,
          color: "text.primary",
        }}
      >
        Enviar una nueva notificación
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="center" spacing={1} mt={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Título"
              {...register("title")}
              error={!!errors.title}
              helperText={errors.title?.message}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Title />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={6}>
            <FormControlLabel
              label="Enviar a todos"
              control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Mensaje"
              {...register("message")}
              error={!!errors.message}
              helperText={errors.message?.message}
              variant="outlined"
              multiline
              rows={4}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Message />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Payload"
              {...register("payload")}
              error={!!errors.payload}
              helperText={errors.payload?.message}
              variant="outlined"
              multiline
              rows={8}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Message />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} mt={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              startIcon={<Send />}
              sx={{
                py: 1.5,
                fontSize: "1rem",
                textTransform: "none",
              }}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
