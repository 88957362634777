import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSelectedSector } from "redux/sectorsSlice";
import { Sector } from "types/Sector";

const useSelectedSector = () => {
  const { selectedSectorId, selectedSectorTz } = useAppSelector((state) => state.sectors);
  const dispatch = useAppDispatch();

  const changeSector = (newSector: Sector) => {
    dispatch(setSelectedSector(newSector));
  };
  return {
    selectedSectorId: Number(selectedSectorId),
    timezone: selectedSectorTz,
    changeSector,
  };
};

export default useSelectedSector;
