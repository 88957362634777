import { Alert, CircularProgress, Card, CardContent, CardMedia, Typography, TextField, Box } from "@mui/material";
import chaletApi from "api/chaletApi";
import React, { forwardRef, useState } from "react";
import { VirtuosoGrid } from "react-virtuoso";
import { styled } from "@mui/material/styles";
import { UserWithDevice } from "types/User";

// Card styles
const CardContainer = styled(Card)({
  margin: 10,
  width: 300,
});

const DeviceList = styled(Box)({
  display: "flex",
  overflowX: "scroll",
  padding: 5,
  whiteSpace: "nowrap",
});

const gridComponents: any = {
  List: forwardRef(({ style, children, ...props }: any, ref: any) => (
    <div
      ref={ref}
      {...props}
      style={{
        display: "flex",
        flexWrap: "wrap",
        ...style,
      }}
    >
      {children}
    </div>
  )),
  Item: ({ children, ...props }: any) => (
    <div
      {...props}
      style={{
        width: "33%",
        display: "flex",
        flex: "none",
        alignContent: "stretch",
      }}
    >
      {children}
    </div>
  ),
};

const ItemWrapper = ({ children, ...props }: any) => (
  <div
    {...props}
    style={{
      display: "flex",
      flex: 1,
      padding: "1rem 1rem",
      whiteSpace: "nowrap",
    }}
  >
    {children}
  </div>
);

// Main Component
export const Clients = () => {
  const { data, isLoading, error } = chaletApi.useGetAllUsersQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [platformFilter, setPlatformFilter] = useState("");

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">Error: {JSON.stringify(error)}</Alert>;
  }
  const users = data?.data;
  if (users) {
    // Filter users by search term and platform
    const filteredUsers = users.filter((user) => {
      const matchesSearch =
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) || user.id.toString().includes(searchTerm);
      const matchesPlatform = platformFilter ? user.devices.some((device) => device.platform === platformFilter) : true;
      return matchesSearch && matchesPlatform;
    });

    return (
      <>
        <Box display="flex" flex={1} width="100%" height="100%" flexDirection="column" alignItems="center">
          <TextField
            label="Search by Name or ID"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <TextField
            label="Filter by Platform"
            variant="outlined"
            size="small"
            select
            value={platformFilter}
            onChange={(e) => setPlatformFilter(e.target.value)}
            SelectProps={{
              native: true,
            }}
          >
            <option value="">All</option>
            <option value="android">Android</option>
            <option value="ios">iOS</option>
          </TextField>
          <VirtuosoGrid
            style={{ height: "80vh", width: "100%" }}
            totalCount={filteredUsers.length}
            components={gridComponents}
            itemContent={(index) => (
              <ItemWrapper>
                <UserCard user={filteredUsers[index]} />
              </ItemWrapper>
            )}
          />
        </Box>
      </>
    );
  }
  return null;
};

// UserCard Component to display each user's information
const UserCard = ({ user }: { user: UserWithDevice }) => {
  return (
    <CardContainer>
      {user.profileImage && <CardMedia component="img" alt={user.name} height="140" image={user.profileImage} />}
      <CardContent>
        <Typography variant="h6">{user.name}</Typography>
        <Typography variant="body2">Email: {user.email}</Typography>
        <Typography variant="body2">Phone: {user.phone}</Typography>
        <Typography variant="body2">ID: {user.id}</Typography>

        {/* Horizontal scrollable device list */}
        <DeviceList>
          {user.devices.map((device) => (
            <Box
              key={device.id}
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{
                minWidth: "100px",
                border: "1px solid #ddd",
                borderRadius: 5,
                padding: 5,
              }}
            >
              <Typography variant="body2">Platform: {device.platform || "N/A"}</Typography>
              <Typography variant="body2">Token: {device.fcmToken?.slice(0, 10)}...</Typography>
            </Box>
          ))}
        </DeviceList>
      </CardContent>
    </CardContainer>
  );
};
