import React, { useState } from "react";
import { CloudUpload, Delete, Description, Title } from "@mui/icons-material";
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import chaletApi from "api/chaletApi";
import { ProductCategory } from "types/ProductCategory";

type UpdateProductCategory = {
  name: string;
  description: string;
};

type UpdateProductCategoryProps = {
  handleClose: () => void;
  productCategory: ProductCategory;
};

const schema = yup.object().shape({
  name: yup.string().required("El título es requerido"),
  description: yup.string().required("La descripción es requerida"),
});

const UpdateProductCategory: React.FC<UpdateProductCategoryProps> = ({ handleClose, productCategory }) => {
  const [updateProductCategory, { isLoading }] = chaletApi.useUpdateProductCategoryMutation();

  const [image, setImage] = useState<File | undefined>(undefined);
  const [previewImage, setPreviewImage] = useState<string | undefined>(productCategory.image);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateProductCategory>({
    defaultValues: {
      name: productCategory.name,
      description: productCategory.description,
    },
    resolver: yupResolver(schema),
  });

  const handleIconImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const clearIconImage = () => {
    setImage(undefined);
    setPreviewImage(undefined);
  };

  const handleUpdateProductCategory = async (data: UpdateProductCategory) => {
    const formData = new FormData();

    if (image) {
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("image", image);
      toast.promise(
        updateProductCategory({
          body: formData,
          id: productCategory.id,
        }).unwrap(),
        {
          loading: "Actualizando la categoría de producto...",
          success: "Categoría de producto actualizada exitosamente",
          error: "Error al actualizar la categoría de producto",
        },
      );

      clearIconImage();

      reset();

      handleClose();
    } else {
      toast.promise(
        updateProductCategory({
          body: {
            name: data.name,
            description: data.description,
          },
          id: productCategory.id,
        }).unwrap(),
        {
          loading: "Actualizando la categoría de producto...",
          success: "Categoría de producto actualizada exitosamente",
          error: "Error al actualizar la categoría de producto",
        },
      );

      clearIconImage();

      reset();

      handleClose();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      component="form"
      onSubmit={handleSubmit(handleUpdateProductCategory)}
      noValidate
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: 150,
            height: 150,
          }}
        >
          <Avatar
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: 2,
            }}
            src={previewImage}
          >
            {!previewImage && (
              <Button
                component="label"
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <CloudUpload style={{ color: "white" }} />
                <input type="file" onChange={handleIconImage} style={{ display: "none" }} />
              </Button>
            )}
          </Avatar>
          {previewImage && (
            <IconButton
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "error.main",
              }}
              onClick={clearIconImage}
            >
              <Delete />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              variant="outlined"
              fullWidth
              required
              defaultValue={productCategory.name}
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Title />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Descripción"
              variant="outlined"
              fullWidth
              required
              defaultValue={productCategory.description}
              {...register("description")}
              error={!!errors.description}
              helperText={errors.description?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Description />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Button
        type="submit"
        variant="contained"
        sx={{
          mt: 4,
          width: "50%",
          alignSelf: "center",
          mb: 4,
        }}
        disabled={isLoading}
      >
        Actualizar
      </Button>
    </Box>
  );
};

export default UpdateProductCategory;
