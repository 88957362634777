import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { LineChart } from "@mui/x-charts";
import dayjs from "dayjs";
import { Order } from "types/Order";

type props = {
  orders: Order[];
};

export const CompletedOrdersChart = ({ orders }: props) => {
  const orderCountsByDate: { [date: string]: number } = orders.reduce(
    (acc, order) => {
      const date = dayjs(order.createdAt).format("YYYY-MM-DD");
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date]++;
      return acc;
    },
    {} as { [date: string]: number },
  );

  console.log(orderCountsByDate);
  const dates = Object.keys(orderCountsByDate).sort();
  const orderCounts = dates.map((date) => orderCountsByDate[date]);

  const xAxisData = dates.map((date) => dayjs(date).toDate());

  const seriesData = [orderCounts];

  return (
    <Stack
      mt={1}
      sx={{
        backgroundColor: grey[100],
        padding: 2,
        borderRadius: 8,
        height: 400,
      }}
    >
      <Typography
        sx={{ fontSize: "1.2rem", fontWeight: "600", textAlign: "center" }}
      >
        Distribución de Órdenes Completadas
      </Typography>
      <LineChart
        xAxis={[
          {
            label: "Date",
            data: xAxisData,
            tickInterval: xAxisData,
            scaleType: "time",
            valueFormatter: (date: Date) => dayjs(date).format("MMM D"),
          },
        ]}
        yAxis={[{ label: "Número de ordenes" }]}
        series={[
          {
            label: "Ordenes completadas",
            data: seriesData[0],
            color: "#0277bd",
          },
        ]}
        height={400}
      />
    </Stack>
  );
};
