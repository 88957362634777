import { Paper, Typography, useTheme } from "@mui/material";
import { amber, lightBlue, orange, purple, red } from "@mui/material/colors";
import {
  AccessTime,
  Cancel,
  Check,
  DoDisturb,
  Store,
} from "@mui/icons-material";

type props = {
  completed: number;
  active: number;
  canceled: number;
  rejected: number;
  totalBranches: number;
  totalSales: number;
};

export const CardsInformation: React.FC<props> = ({
  completed,
  active,
  canceled,
  rejected,
  totalBranches,
}) => {
  const { palette } = useTheme();

  return (
    <Paper
      elevation={0}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Paper
        style={{
          width: "200px",
          borderRadius: 8,
          padding: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: `linear-gradient(to bottom, ${amber[400]}, ${amber[800]})`,
          color: palette.common.white,
        }}
        elevation={3}
      >
        <AccessTime style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography>Órdenes Activas</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {active}
        </Typography>
      </Paper>

      <Paper
        style={{
          width: "200px",
          borderRadius: 8,
          padding: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: `linear-gradient(to bottom, ${lightBlue[400]}, ${lightBlue[800]})`,
          color: palette.common.white,
        }}
        elevation={3}
      >
        <Check style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography>Órdenes Completadas</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {completed}
        </Typography>
      </Paper>

      <Paper
        style={{
          width: "200px",
          borderRadius: 8,
          padding: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: palette.common.white,
          background: `linear-gradient(to bottom, ${red[400]}, ${red[800]})`,
        }}
        elevation={3}
      >
        <Cancel style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography>Órdenes Canceladas</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {canceled}
        </Typography>
      </Paper>
      <Paper
        style={{
          width: "200px",
          borderRadius: 8,
          padding: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: palette.common.white,
          background: `linear-gradient(to bottom, ${orange[400]}, ${orange[800]})`,
        }}
        elevation={3}
      >
        <DoDisturb style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography>Órdenes Rechazadas</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {rejected}
        </Typography>
      </Paper>
      <Paper
        style={{
          width: "200px",
          borderRadius: 8,
          padding: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: `linear-gradient(to bottom, ${purple[400]}, ${purple[800]})`,
          color: palette.common.white,
        }}
        elevation={3}
      >
        <Store style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography>Total de Sucursales</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {totalBranches}
        </Typography>
      </Paper>
    </Paper>
  );
};
