import * as React from "react";
import { toast } from "react-hot-toast";
import { CircularProgress, Card, CardContent, Typography, Divider, Box } from "@mui/material";
import { Branch } from "types/Branch";
import { formatDate } from "utils/formatter";
import { BranchSummaryPDFDownloadLink } from "../InvoiceDocument/BranchSummaryPDFDownloadLink";
import InvoiceService from "services/InvoiceService";

type branchOrdersTableProps = {
  branch: Branch;
};

export const BranchTable: React.FC<branchOrdersTableProps> = ({ branch }) => {
  const { data, isError, error, isFetching } = InvoiceService.useGetInvoicesByBillingDetailIdQuery(
    branch.billingDetail.id,
  );

  React.useEffect(() => {
    if (isError) {
      toast("Error obteniendo órdenes: " + JSON.stringify(error));
    }
  }, [isError, error]);

  if (isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (!data || !data.data.length) {
    return (
      <Typography variant="body1" color="textSecondary" align="center" mt={2}>
        No hay datos disponibles
      </Typography>
    );
  }

  return (
    <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={2} mt={2}>
      {data.data.map((invoice) => (
        <Card
          key={invoice.id}
          sx={{
            maxWidth: 600,
            boxShadow: 4,
            borderRadius: 3,
            border: "1px solid #e0e0e0",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "scale(1.02)",
            },
          }}
        >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Factura #{invoice.invoiceNumber}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body2" color="textSecondary">
              <strong>Fecha de emisión:</strong> {formatDate(invoice.issueDate)}
            </Typography>
            {/* Agregar campos fromDateOrders toDateOrders*/}
            <Typography variant="body2" color="textSecondary">
              <strong>Desde:</strong> {formatDate(invoice.fromDateOrders)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Hasta:</strong> {formatDate(invoice.toDateOrders)}
            </Typography>
          </CardContent>
          <BranchSummaryPDFDownloadLink invoice={invoice} />
        </Card>
      ))}
    </Box>
  );
};
