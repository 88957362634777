import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ModuleType, setSelectedModule } from "redux/moduleSlice";

const useSelectedModule = () => {
  const { selectedModule } = useAppSelector((state) => state.module);
  const dispatch = useAppDispatch();

  const changeModule = (module: ModuleType) => {
    dispatch(setSelectedModule(module));
  };
  return {
    selectedModule,
    changeModule,
  };
};

export default useSelectedModule;
