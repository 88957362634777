import React from "react";
import { store } from "redux/store";
import { Provider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type Props = { children: React.ReactNode };

function ReduxProvider({ children }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Provider store={store}>{children}</Provider>
    </LocalizationProvider>
  );
}

export default ReduxProvider;
