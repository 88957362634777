import { Box, Typography, useTheme } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payments";
import { useState } from "react";

import CreateDriverModal from "./CreateDriverModal";

const CreateDriverButton: React.FC = () => {
  const { palette } = useTheme();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Box>
        <Box
          onClick={handleOpenModal}
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderRadius="8px"
          padding="16px"
          sx={{
            cursor: "pointer",
            border: "1px solid",
            borderColor: palette.primary.main,
          }}
        >
          <PaymentIcon fontSize="large" style={{ color: palette.primary.main }} />
          <Typography
            color="background.paper"
            fontSize="large"
            fontWeight="medium"
            style={{ color: palette.primary.main }}
          >
            Crear Driver
          </Typography>
        </Box>
      </Box>
      <CreateDriverModal isOpen={isModalOpen} handleClose={closeModal} />
    </>
  );
};

export default CreateDriverButton;
