import { Button, Card, Paper, Stack, Typography, useTheme } from "@mui/material";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";

type Props = {
  result: any;
  sectorPolygon: any;
  mapCenter: any;
  showMessage: any;
  handleClose: any;
};

export default function OrdersMap({ result, sectorPolygon, mapCenter, showMessage, handleClose }: Props) {
  const theme = useTheme();
  return (
    <Card elevation={1}>
      <Stack>
        <GoogleMap mapContainerStyle={{ height: "700px", width: "100%" }} center={mapCenter} zoom={12}>
          <Polygon
            options={{
              fillColor: "rgba(239, 61, 50, 0.35)",
              strokeColor: theme.palette.primary.main,
            }}
            editable={false}
            draggable={false}
            path={sectorPolygon}
          />
          {result.data.map(({ id, route }: any) => (
            <Marker
              key={id}
              position={{
                lat: route.destination.location.coordinates[1],
                lng: route.destination.location.coordinates[0],
              }}
            />
          ))}

          {showMessage && result.data.length === 0 && (
            <Paper
              elevation={3}
              style={{
                padding: "20px",
                bottom: "20px",
                left: "50%",
                top: "50%",
                width: "fit-content",
                position: "relative",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>No hay órdenes activas en este momento.</Typography>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  mt: 2,
                }}
              >
                Confirmar
              </Button>
            </Paper>
          )}
        </GoogleMap>
      </Stack>
    </Card>
  );
}
