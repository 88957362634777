import { setSelectedBranch } from "redux/branchSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Branch } from "types/Branch";

const useSelectedBranch = () => {
  const { selectedBranch } = useAppSelector((state) => state.branch);
  const dispatch = useAppDispatch();

  const changeBranch = (branch: Branch) => {
    dispatch(setSelectedBranch(branch));
  };
  return {
    selectedBranch,
    changeBranch,
  };
};

export default useSelectedBranch;
