import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { DeliveryOffer } from "types/DeliveryOffer";

type CardProps = {
  offer: any;
  onSelect: (offer: DeliveryOffer) => void;
  elapsedMaxTime: number;
};

function OfferCard({ offer, onSelect, elapsedMaxTime }: CardProps) {
  const [currentTime, setCurrentTime] = useState(dayjs());

  const toleratedTime = elapsedMaxTime / 1000;
  const statusIcon = useMemo(() => {
    switch (offer.status) {
      case "active":
        return "🟡";
      case "accepted":
        return "🟢";
      case "rejected":
        return "🔴";
      default:
        return "🟠";
    }
  }, [offer.status]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs()); // Update the current time every second
    }, 1000);
    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  const remainingTime = useMemo(() => {
    const offerTime = dayjs(offer.createdAt);
    const diff = currentTime.diff(offerTime, "second");
    return diff > toleratedTime ? 0 : toleratedTime - diff;
  }, [currentTime, offer.createdAt, toleratedTime]);

  return (
    <Card elevation={1} sx={{ padding: 1, gap: 1 }} onClick={() => onSelect(offer)}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography>{offer.driver.user.name}</Typography>
        <Typography>{statusIcon}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box flex={1}>
          <LinearProgress variant="determinate" value={0.5} />
        </Box>
        <Box>
          <Typography>{remainingTime}s</Typography>
        </Box>
      </Stack>
      {offer.orders.map(({ order }: any) => {
        return (
          <Accordion variant="outlined">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>
                {order.branch.name} - {order.user.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget maximus est, id
                dignissim quam.
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Card>
  );
}

export default OfferCard;
