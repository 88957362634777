import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { FormTextInput } from "components/common";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import VehicleTypesService from "services/VehicleTypesService";
import { VehicleType } from "types/VehicleType";
import * as yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const VehicleTypeSchema = yup.object().shape({
  type: yup.string().required("Tipo es requerido"),
  rates: yup.number().required("la tarifa es requerida").min(1, "El número debe ser mayor o igual a 1"),
});

type VehicleTypeForm = Omit<VehicleType, "id">;

type props = {
  open: boolean;
  handleClose: () => void;
  vehicleType: VehicleType | null;
};
export const CreateEditVehicleType: React.FC<props> = ({ open, handleClose, vehicleType }) => {
  console.log("vehicle", vehicleType);

  const [isEditing, setIsEditing] = useState(false);
  const [saveVehicleType] = VehicleTypesService.useCreateVehicleTypeMutation();
  const [updateVehicleType] = VehicleTypesService.useEditVehicleTypeMutation();

  useEffect(() => {
    if (vehicleType) {
      setIsEditing(true);
    }
  }, [vehicleType]);

  const useYupValidationResolver = (validationSchema: yup.ObjectSchema<VehicleTypeForm>) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {},
            ),
          };
        }
      },
      [validationSchema],
    );

  const { control, handleSubmit } = useForm<VehicleTypeForm>({
    defaultValues: vehicleType
      ? {
          type: vehicleType?.type,
          rates: vehicleType?.rates,
        }
      : {
          type: "",
          rates: 0,
        },
    resolver: useYupValidationResolver(VehicleTypeSchema),
  });

  const onSubmit = async (data: VehicleTypeForm) => {
    if (isEditing && vehicleType) {
      const updateData = {
        id: vehicleType.id,
        type: data.type,
        rates: data.rates,
      };

      await updateVehicleType(updateData)
        .unwrap()
        .then(() => {
          toast.success("Tipo de vehiculo editado con exito");
          handleClose();
        })
        .catch(() => {
          toast.error("Ha ocurrido un error mientras se editaba el tipo de producto");
          handleClose();
        });
    } else {
      await saveVehicleType(data)
        .unwrap()
        .then(() => {
          toast.success("Tipo de vehiculo registrado con exito");
          handleClose();
        })
        .catch(() => {
          toast.error("Ha ocurrido un error mientras se registraba el tipo de producto");
          handleClose();
        });
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="vehicle-type" aria-describedby="create-edit-vehicle-type">
      <Box sx={style}>
        <Typography id="vehicle-type" variant="h6" component="h2">
          Crear tipo de vehículo
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <FormTextInput variant="outlined" control={control} label="Tipo" gridSize={12} name="type" />
            <FormTextInput variant="outlined" control={control} label="Tarifa" gridSize={12} name="rates" />
          </Stack>

          <Stack spacing={2} direction={"row"} marginTop={2} justifyContent={"center"}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button type="submit" variant="contained">
              {isEditing ? "Editar" : "Crear"}
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};
