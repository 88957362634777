import { Box, Stack, Typography, useTheme } from "@mui/material";
import { green, grey, orange } from "@mui/material/colors";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { toCurrencyFormat } from "utils/formatter";

type props = {
  totalSales: number;
  totalLossesforCancel: number;
  totalDelivery: number;
  totalLossesforReject: number;
};

export const ProfitsAndLossesChart = ({
  totalSales,
  totalLossesforCancel,
  totalLossesforReject,
  totalDelivery,
}: props) => {
  console.log(
    totalSales,
    totalLossesforCancel,
    totalLossesforReject,
    totalDelivery,
  );
  const { palette } = useTheme();
  return (
    <Stack
      mt={1}
      sx={{
        backgroundColor: grey[100],
        padding: 2,
        borderRadius: 8,
        height: 350,
      }}
    >
      <Typography
        sx={{ fontSize: "1.2rem", fontWeight: "600", textAlign: "center" }}
      >
        Distribución Financiera de Órdenes Completadas, Canceladas, Rechazadas y
        Enviadas
      </Typography>
      <Box display={"flex"} justifyContent={"start"}>
        <PieChart
          colors={[green[800], palette.primary.main, orange[900], " #01869E"]}
          series={[
            {
              arcLabel: (item) => `${toCurrencyFormat(item.value)}`,
              arcLabelMinAngle: 45,

              data: [
                {
                  id: 0,
                  value: totalSales,
                  label: "Ingresos por Órdenes Completadas",
                },
                {
                  id: 1,
                  value: totalLossesforCancel,
                  label: "Pérdidas por Ordenes Canceladas",
                },
                {
                  id: 2,
                  value: totalLossesforReject,
                  label: "Pérdidas por Órdenes Rechazadas",
                },
                { id: 3, value: totalDelivery, label: "Ingresos por Envíos" },
              ],
              highlightScope: { faded: "global", highlighted: "item" },
              faded: {
                innerRadius: 30,
                additionalRadius: -30,
                color: "gray",
              },
              innerRadius: 30,
              outerRadius: 130,
              paddingAngle: 2,
              cornerRadius: 5,
              cx: 130,
              cy: 130,
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold",
            },
          }}
          width={600}
          height={280}
        />
      </Box>
    </Stack>
  );
};
