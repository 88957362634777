import { Box, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { BarChart, mangoFusionPalette } from "@mui/x-charts";
import React from "react";
import { toCurrencyFormat } from "utils/formatter";

type DataItem = {
  sectorId: string;
  name: string;
  totalSales: number;
  totalDeliveries: number;
};

type props = {
  data: DataItem[];
};

const valueFormatter = (value: number | null) =>
  `${toCurrencyFormat(value ? value : 0)}`;
export const SectorSalesChart = ({ data }: props) => {
  return (
    <Stack
      sx={{
        backgroundColor: grey[100],
        padding: 2,
        borderRadius: 8,
        height: 400,
      }}
    >
      <Typography
        sx={{ fontSize: "1.2rem", fontWeight: "600", textAlign: "center" }}
      >
        Ventas y Envios totales por sector
      </Typography>
      <BarChart
        dataset={data}
        yAxis={[{ scaleType: "band", dataKey: "name" }]}
        series={[
          {
            dataKey: "totalSales",
            label: "Ventas Totales",
            valueFormatter,
            color: "#2e7d32",
          },
          {
            dataKey: "totalDeliveries",
            label: "Envios",
            valueFormatter,
            color: "#0277bd",
          },
        ]}
        borderRadius={5}
        colors={mangoFusionPalette}
        layout="horizontal"
        grid={{ vertical: true }}
        height={450}
      />
    </Stack>
  );
};
