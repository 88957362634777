import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import chaletApi from "api/chaletApi";
import { Sector } from "types/Sector";
import { CardOrders } from "./CardOrders";

type OrdersProps = {
  sector: Sector;
};

export const Orders: React.FC<OrdersProps> = ({ sector }) => {
  const [getOrdersBySector, result] = chaletApi.useLazyGetActiveOrdersBySectorQuery();

  useEffect(() => {
    getOrdersBySector(sector.id);
  }, [sector]);

  if (result.data) {
    return (
      <Box p={2}>
        {result.data.length > 0 ? (
          <Box
            sx={{
              maxHeight: "700px",
              overflowY: "auto",
              padding: 1,
              border: "1px solid #ddd",
              borderRadius: "8px",
            }}
          >
            <Grid container spacing={2}>
              {result.data.map((order: any) => (
                <Grid item xs={12} sm={6} md={4} key={order.id}>
                  <CardOrders order={order} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" style={{ width: "90vw", height: "auto" }}>
            <Typography variant="h6">No active orders</Typography>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={{ width: "90vw", height: "auto" }}>
      <Typography variant="h6">No active orders</Typography>
    </Box>
  );
};
