import { PrivateRoute } from "components/common";
import useUser from "hooks/useUser";
import AdminMenu from "./AdminMenu";

export default function AdminPage() {
  const { isLoggedIn } = useUser();
  return (
    <PrivateRoute isAuthenticated={isLoggedIn}>
      <AdminMenu />
    </PrivateRoute>
  );
}
