import { AddBox } from "@mui/icons-material";
import { Avatar, Box, Button, IconButton, Modal, Typography, styled } from "@mui/material";
import chaletApi from "api/chaletApi";
import { FormTextInput } from "components/common";
import { useCustomForm } from "hooks";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { pictureValidation } from "utils/pictureValidation";
import * as yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 496,
  height: 373,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "28px",
  padding: 4,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const affiliatesSchema = yup.object().shape({
  name: yup.string().required("El nombre de la tienda es requerido"),
});

type props = {
  open: boolean;
  handleClose: () => void;
  productTypeId: number;
};

const AffiliatesModal = ({ open, handleClose, productTypeId }: props) => {
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [previewProfileImage, setPreviewProfileImage] = useState<string | null>(null);

  const [createAffiliate] = chaletApi.useCreateAffiliateMutation();

  const handleChangeProfileImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      pictureValidation(file, setProfileImage, setPreviewProfileImage);
    }
  };

  const { handleSubmit, control, reset } = useCustomForm(
    {
      name: "",
    },
    affiliatesSchema,
  );

  const onSubmit = (data: any) => {
    try {
      if (profileImage) {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("productTypeId", String(productTypeId));
        formData.append("profileImage", profileImage);

        toast.promise(
          createAffiliate(formData).then(() => {
            onClose();
          }),
          {
            loading: "Guardando...",
            success: <b>Affiliado Creado</b>,
            error: <b>Ha ocurrido un error.</b>,
          },
        );
      } else {
        const affiliateData = {
          name: data.name,
          productTypeId: productTypeId,
        };

        toast.promise(
          createAffiliate(affiliateData).then(() => {
            onClose();
          }),
          {
            loading: "Guardando...",
            success: <b>Affiliado Creado</b>,
            error: <b>Ha ocurrido un error.</b>,
          },
        );
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.forEach((errorMessage: string) => toast.error(errorMessage));
      } else {
        toast.error("Error guardando producto");
      }
    }
  };

  const onClose = () => {
    setProfileImage(null);
    setPreviewProfileImage(null);
    reset();
    handleClose();
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
      <Box
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          ...style,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5">Crear nuevo afiliado</Typography>
          {previewProfileImage ? (
            <Avatar
              src={previewProfileImage}
              variant="square"
              sx={{
                width: 201,
                height: 205,
                backgroundColor: "#c7c7c7",
                borderRadius: "28px",
                m: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            />
          ) : (
            <IconButton
              sx={{
                width: 201,
                height: 205,
                backgroundColor: "#c7c7c7",
                borderRadius: "28px",
                m: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              component="label"
            >
              <VisuallyHiddenInput type="file" accept="image/*" onChange={handleChangeProfileImage} />
              <AddBox sx={{ width: 77, height: 77, color: "#534341" }} />
              <Typography color={"#534341"}>Imagen de perfil</Typography>
            </IconButton>
          )}

          <FormTextInput label="Nombre" control={control} gridSize={12} variant="outlined" name="name" />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end", mt: 4 }}>
          <Button variant="text" type="submit">
            Guardar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AffiliatesModal;
