import React, { useState } from "react";
import { Box, Card, Chip, Stack, Typography, useTheme } from "@mui/material";
import { formatDateWithTime } from "utils/formatter";
import { Order } from "types/Order";
import { SectorOrderModal } from "./ModalContent/SectorOrderModal";
import { ActiveOrderDetails } from "./ActiveOrderDetails";

type OrderItemProps = {
  order: Order;
};

export const CardActiveOrders: React.FC<OrderItemProps> = ({ order }) => {
  const { palette } = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          margin: 1,
          display: "flex",
          position: "relative",
          minWidth: "300px",
          height: "200px",
          cursor: "pointer",
        }}
        elevation={1}
        onClick={handleOpen}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            ml: 2,
            mr: 2,
            mt: 2,
          }}
        >
          <Box>
            <Typography textAlign="left" variant="subtitle2" color="primary">
              {formatDateWithTime(order.updatedAt)}
            </Typography>
          </Box>

          <Box display="flex" mt={1}>
            <Stack direction={"row"} display={"flex"} alignItems={"center"} spacing={1}>
              <Typography variant="subtitle2">Estado actual:</Typography>
              <Chip label={order.currentOrderProcessStep.name} color="primary" />
            </Stack>
          </Box>

          <Box
            mt={1}
            flex={1}
            sx={{
              overflowX: "hidden",
            }}
          >
            <Typography variant="subtitle2" mb={1} textAlign="center">
              Detalles de la orden
            </Typography>
            <Box flex={1} ml={1} mr={1}>
              <ActiveOrderDetails details={order.details} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            backgroundColor: palette.primary.main,
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2,
            borderRadius: "0px  0px 0px 10px",
          }}
        >
          <Typography color="white" fontWeight={600}>
            {order.id}
          </Typography>
        </Box>
      </Card>
      <SectorOrderModal order={order} handleClose={handleClose} isModalOpen={isModalOpen} />
    </>
  );
};
