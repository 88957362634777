import { Stack, useTheme } from "@mui/material";
import { GoogleMap, Polygon } from "@react-google-maps/api";
import React from "react";
import { Sector } from "types/Sector";

type props = {
  sectors: Sector[];
};

export const SectorsMap: React.FC<props> = ({ sectors }) => {
  const sectorPolygon = sectors.map((sector) => {
    return sector.area.map((point) => {
      return {
        lat: point[1],
        lng: point[0],
      };
    });
  });

  const theme = useTheme();

  return (
    <Stack
      p={2}
      gap={1}
      style={{ width: "90vw", height: "auto", alignItems: "center" }}
    >
      <GoogleMap
        mapContainerStyle={{ width: "1200px", height: "500px" }}
        center={{
          lat: 13.7942,
          lng: -88.8965,
        }}
        zoom={9}
      >
        {sectorPolygon.map((polygon, index) => (
          <Polygon
            key={index}
            path={polygon}
            options={{
              strokeColor: theme.palette.primary.main,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: theme.palette.primary.main,
              fillOpacity: 0.35,
            }}
          />
        ))}
      </GoogleMap>
    </Stack>
  );
};
