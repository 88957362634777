import { Box, Button, Card, MenuItem, Modal, Select, Typography } from "@mui/material";
import chaletApi from "api/chaletApi";
import useSelectedSector from "hooks/useSelectedSector";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { ProductType } from "types/ProductType";
import { handleApiError } from "utils";

type AddProductTypeModalProps = {
  handleClose: () => void;
  isOpen: boolean;
  productTypes: ProductType[] | undefined;
};

const AddProductTypeModal: React.FC<AddProductTypeModalProps> = ({ handleClose, isOpen, productTypes }) => {
  const [createProductType] = chaletApi.useCreateSectorProductTypeMutation();
  const { selectedSectorId } = useSelectedSector();
  const [isProductType, setIsProductType] = useState<string>("");

  const CreateSectorProductType = () => {
    createProductType({
      sectorId: selectedSectorId,
      productTypeId: isProductType,
    })
      .unwrap()
      .then(() => {
        toast.success("Tipo de producto de sector creado exitosamente");
        handleClose();
      })
      .catch((error) => {
        const message = handleApiError(error);
        toast.error(message);
      });
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box margin={8}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              height: "auto",
              width: "20vw",
            }}
            elevation={4}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                ml: 2,
                mr: 2,
                mt: 2,
              }}
            >
              <Typography variant="h5" mb={1} textAlign="center">
                Crear nuevo tipo de Producto
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Select
                  fullWidth
                  displayEmpty
                  value={isProductType}
                  onChange={(event) => setIsProductType(event.target.value as string)}
                  variant="outlined"
                  sx={{ mt: 3 }}
                >
                  <MenuItem value="" selected disabled>
                    Seleccionar un tipo
                  </MenuItem>
                  {productTypes?.map((productType) => (
                    <MenuItem key={productType.id} value={productType.id}>
                      {productType.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Button
                variant="contained"
                onClick={CreateSectorProductType}
                sx={{
                  mt: 4,
                  width: "50%",
                  alignSelf: "center",
                  mb: 4,
                }}
              >
                Crear
              </Button>
            </Box>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default AddProductTypeModal;
