import React, { useMemo } from "react";
import ShopsList from "./ShopsList";
import ProductTypes from "./ProductTypes";

const productTypes = [
  {
    id: 1,
    name: "Restaurantes",
    key: "food",
  },
  {
    id: 2,
    name: "Supermercados",
    key: "market",
  },
  {
    id: 3,
    name: "Tiendas",
    key: "shop",
  },
];

const ShopLayout = () => {
  const [value, setValue] = React.useState<string | null>(null);

  const renderCurrentTab = useMemo(() => {
    if (!value) return null;
    return <ShopsList type={value} />;
  }, [value]);

  const onChangeProductType = (key: string) => {
    setValue(key);
  };

  return (
    <>
      <ProductTypes onChangeProductType={onChangeProductType} />
      {renderCurrentTab}
    </>
  );
};

export default ShopLayout;
