import { BrowserRouter } from "react-router-dom";
import ReduxProvider from "providers/ReduxProvider";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import { LoadScript } from "@react-google-maps/api";
import { Toaster } from "react-hot-toast";

import { AdminApp } from "components/partials";

function App() {
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY as string}
      libraries={["drawing"]}
    >
      <ReduxProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Toaster />
            <AdminApp />
          </ThemeProvider>
        </BrowserRouter>
      </ReduxProvider>
    </LoadScript>
  );
}

export default App;
