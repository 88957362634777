import { createSlice } from "@reduxjs/toolkit";
import { Branch } from "types/Branch";

export interface branchSlice {
  selectedBranch: Branch | null;
}

const initialState: branchSlice = {
  selectedBranch: null,
};

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setSelectedBranch } = branchSlice.actions;

export default branchSlice;
