import DriverList from "./DriverList/DriversList";
import { DriverSchedule } from "types/DriverSchedule";

type Props = {
  driverSchedule: DriverSchedule[];
  setSelectedDriver: (driver: DriverSchedule) => void;
};
export default function ActiveDrivers({ driverSchedule, setSelectedDriver }: Props) {
  let content = null;

  if (driverSchedule) {
    content = <DriverList schedules={driverSchedule} setSelectedDriver={setSelectedDriver} />;
  }

  return content;
}
