import { useMemo, useState } from "react";
import {
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_TablePagination,
  MRT_ToolbarAlertBanner,
  MRT_Table,
  MRT_Row,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { Product } from "types/Product";
import { Avatar, Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { toCurrencyFormat } from "utils/formatter";
import { openAddProductModal, openEditProductModal } from "redux/modalSlice";
import chaletApi from "api/chaletApi";
import { AddProductModal } from "../AddProductModal";
import toast from "react-hot-toast";
import { useAppDispatch } from "redux/hooks";
import { EditProductModal } from "../EditProductModal";

type NewTable = {
  data: Product[];
  categoryId: number;
  productTypeId: number;
};

export const ProductsTable: React.FC<NewTable> = ({ data, categoryId, productTypeId }) => {
  const [productId, setProductId] = useState<number | null>(null);
  const dispatch = useAppDispatch();

  const columns = useMemo<MRT_ColumnDef<Product>[]>(
    () => [
      {
        accessorFn: (row) => `${row.name}`,
        id: "name",
        header: "Nombre",
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {row.original.image ? (
              <Avatar
                src={row.original.image}
                sx={{
                  aspectRatio: "1/1",
                  objectFit: "cover",
                  height: 30,
                  width: 30,
                }}
              ></Avatar>
            ) : (
              <Avatar
                sx={{
                  aspectRatio: "1/1",
                  objectFit: "cover",
                  height: 30,
                  width: 30,
                }}
              />
            )}

            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "description",
        header: "Descripcion",
        size: 250,
        Cell: ({ cell }) => (
          <Typography sx={{ maxWidth: "250px", wordWrap: "break-word" }}>{cell.getValue<string>()}</Typography>
        ),
      },
      {
        accessorKey: "price",
        header: "Precio",
        size: 150,
        Cell: ({ cell }) => <Typography>{toCurrencyFormat(cell.getValue<number>())}</Typography>,
      },
      {
        accessorKey: "isAvailable",
        header: "Disponible",
        size: 200,
        enableColumnFilter: false,
        Cell: ({ cell }) => <Typography>{cell.getValue<string>() ? "Si" : "No"}</Typography>,
      },
    ],
    [],
  );

  const [deleteProduct] = chaletApi.useDeleteShopProductMutation();
  const [updateIsAvailable] = chaletApi.useUpdateIsAvailableMutation();

  const handleUpdateProduct = (id: number) => {
    dispatch(openEditProductModal());
    setProductId(id);
  };

  const openDeleteConfirmModal = (row: MRT_Row<Product>) => {
    if (window.confirm("¿Estás seguro de eliminar este producto?")) {
      deleteProduct(row.original.id);
    }
  };

  const handleDesactivate = () => {
    const products = table.getSelectedRowModel().flatRows.map((row) => ({
      id: row.original.id,
      isAvailable: row.original.isAvailable,
    }));

    updateIsAvailable({ products })
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          table.toggleAllRowsSelected(false);
          toast.success("Productos Actualizados");
        } else {
          toast.error("Ha ocurrido un error, intentalo de nuevo");
          table.toggleAllRowsSelected(false);
        }
      })
      .catch((error: any) => {
        toast.error("Ha ocurrido un error: " + error.message);
      });
  };

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      pagination: { pageSize: 15, pageIndex: 0 },
      showGlobalFilter: true,
      showColumnFilters: false,
      density: "compact",
    },
    enableFilters: true,
    enableRowSelection: true,
    enableEditing: true,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15],
      variant: "outlined",
    },
    muiToolbarAlertBannerProps: {
      color: "info",
    },
    muiTableHeadProps: { sx: { boxShadow: "none" } },
    muiTableContainerProps: { sx: { maxWidth: 600 } },
    paginationDisplayMode: "pages",
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.common.white,
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
        fontWeight: "normal",
      },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => handleUpdateProduct(row.original.id)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  return (
    <Box sx={{ m: "5px" }}>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          p: "8px",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToggleFiltersButton table={table} />
        </Box>
        <Box>
          <Box sx={{ display: "flex", gap: "0.5rem" }}>
            <Button
              startIcon={<Add />}
              variant="contained"
              color="success"
              onClick={() => {
                dispatch(openAddProductModal());
              }}
            >
              Crear Producto
            </Button>
            <Button
              color="error"
              disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
              onClick={handleDesactivate}
              variant="contained"
            >
              Activar/Desactivar
            </Button>
          </Box>
        </Box>
      </Box>

      <MRT_Table table={table} />

      <MRT_ToolbarAlertBanner stackAlertBanner table={table} />
      <MRT_TablePagination table={table} />
      {productId && <EditProductModal productId={productId} resetProductId={() => setProductId(null)} />}
      <AddProductModal categoryId={categoryId} productTypeId={productTypeId} />
    </Box>
  );
};
