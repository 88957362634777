import React, { useState } from "react";
import { AddBox } from "@mui/icons-material";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { AddSectorProductTypeModal } from "./Modal";
import chaletApi from "api/chaletApi";
import SectorProductTypeCard from "./SectorProductTypeCard";

const SectorProductType: React.FC = () => {
  const { palette } = useTheme();
  const { data: productTypes } = chaletApi.useGetProductTypesQuery();

  const [isOpenModal, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      sx={{
        marginTop: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          color: palette.primary.main,
        }}
      >
        Tipos de producto disponibles
      </Typography>
      {productTypes && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "flex-start",
          }}
        >
          <SectorProductTypeCard productTypes={productTypes.data} />
          <Card
            elevation={0}
            sx={{
              padding: 1,
              width: 150,
              height: 50,
              borderRadius: "12px",
              backgroundColor: "#F1DEDC",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              ":hover": {
                boxShadow: "0 0 0 0px #fff, 0 0 0 2px #ef3d32",
                cursor: "pointer",
              },
            }}
            onClick={handleOpen}
          >
            <AddBox
              sx={{
                width: 44,
                height: 44,
                color: palette.primary.main,
                marginRight: "10px",
              }}
            />
            <Box>
              <Typography
                sx={{
                  color: palette.primary.main,
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                Nuevo tipo de producto
              </Typography>
            </Box>
          </Card>
        </Box>
      )}
      <AddSectorProductTypeModal handleClose={handleClose} isOpen={isOpenModal} />
    </Box>
  );
};

export default SectorProductType;
