import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, CardContent, TextField, Grid, Typography, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import chaletApi from "api/chaletApi";
import { Alert } from "components/common";
import { handleApiError } from "utils";
import useUser, { ChaletRoles } from "hooks/useUser";

export default function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { isLoading, error }] = chaletApi.useLoginMutation();
  const [open, setOpen] = React.useState(false);
  const userHook = useUser();

  useEffect(() => {
    const { sectorId, isLoggedIn, role } = userHook;
    if (isLoggedIn) {
      if (role === ChaletRoles.admin) {
        navigate("/admin/");
      } else if (role === ChaletRoles.sectorAdmin) {
        navigate(`/admin/sectors/${sectorId}/activeOrders`);
      } else if (role === ChaletRoles.customerService) {
        navigate(`/admin/sectors/${sectorId}/activeOrders`);
      }
    }
  }, [userHook]);

  const handleLoginClick = async () => {
    try {
      await login({ email, password }).unwrap();
    } catch (error) {
      setOpen(true);
      console.log("Error", error);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const errorMessage = useMemo(() => {
    return handleApiError(error);
  }, [error]);

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
      <Card style={{ width: 400 }}>
        <CardContent>
          <Typography variant="h4" gutterBottom align="center">
            Login
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleLoginClick();
            }}
          >
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              style={{ marginTop: 20 }}
            >
              {isLoading ? "Logging in..." : "Login"}
            </Button>
          </form>
        </CardContent>
      </Card>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
