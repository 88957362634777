export const ChaletAdminRoutes = [
  {
    id: 0,
    name: "Inicio",
    route: "/admin/",
  },
  {
    id: 1,
    name: "Sectors",
    route: "/admin/sectors",
  },
];
