import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSelectedShop } from "redux/shopSlice";
import { Shop } from "types/Shop";

const useSelectedShop = () => {
  const { selectedShop } = useAppSelector((state) => state.shop);
  const dispatch = useAppDispatch();

  const changeShop = (shop: Shop) => {
    dispatch(setSelectedShop(shop));
  };
  return {
    selectedShop,
    changeShop,
  };
};

export default useSelectedShop;
