import React from "react";
import { Box, Divider, useTheme } from "@mui/material";
import { AffiliatesCard } from "./AffiliatesCard";
import { NewAffiliate } from "./NewAffiliate";
import { ProductTypeCard } from "../ProductType/ProductTypeCard";
import { filteredShops } from "../Products";
import { Shop } from "types/Shop";

type AffiliateListProps = {
  SectorTypeWithShops: filteredShops;
  onSelectAffiliate: (shop: Shop) => void;
};

export const AffiliatesList: React.FC<AffiliateListProps> = ({ SectorTypeWithShops, onSelectAffiliate }) => {
  const { palette } = useTheme();

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <ProductTypeCard key={SectorTypeWithShops.id} productType={SectorTypeWithShops.productType} />

        {SectorTypeWithShops.shops.map((shop) => (
          <AffiliatesCard key={shop.id} shop={shop} onSelect={onSelectAffiliate} />
        ))}

        <NewAffiliate productTypeId={SectorTypeWithShops.productType.id} />
      </Box>
      <Divider
        sx={{
          marginLeft: 5,
          marginRight: 5,
          backgroundColor: palette.primary.main,
        }}
        orientation="vertical"
        flexItem
      />
    </>
  );
};
