import { Box, Button, Stack, TextField } from "@mui/material";
import chaletApi from "api/chaletApi";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { VariationOption } from "types/Order";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

type props = {
  variationId: number;
  variationOption?: VariationOption;
  correlativeVariation?: number;
  onClose: () => void;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  value: Yup.number()
    .typeError("Debe ser un número")
    .required("El valor es requerido")
    .min(0, "El valor debe ser mayor o igual a cero"),
});

type FormValues = {
  name: string;
  value: number;
};

export const AddVariationOptionForm: React.FC<props> = ({
  variationId,
  variationOption,
  onClose,
  correlativeVariation,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [createVariationOption] = chaletApi.useSaveProductVariationOptionMutation();
  const [updateVariationOption] = chaletApi.useUpdateProductVariationOptionMutation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: variationOption?.value || "",
      value: variationOption?.addedValue || 0,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data);
    if (isEditing && variationOption) {
      //editMode
      const optionData = {
        value: data.name,
        addedValue: data.value,
      };

      updateVariationOption({ id: variationOption.id, body: optionData })
        .unwrap()
        .then((payload) => {
          if (payload.success) {
            onClose();
          } else {
            toast.error("Ha ocurrido un error");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      //Save Mode
      const optionData = {
        value: data.name,
        addedValue: data.value,
        correlative: correlativeVariation,
        variationId: variationId,
        isDeleted: false,
      };

      createVariationOption(optionData);
    }
  };

  useEffect(() => {
    if (variationOption) {
      setIsEditing(true);
    }
  }, [variationOption]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box mt={1} display="flex" flexDirection="row" gap={4}>
          <Box flex={1}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nombre"
                  placeholder="Pan Integral"
                  type="text"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Valor Incrementado"
                  placeholder="$1.00"
                  type="number"
                  error={!!errors.value}
                  helperText={errors.value?.message}
                />
              )}
            />
          </Box>
        </Box>

        <Stack direction={"row"} spacing={1} display={"flex"} justifyContent={"center"} mt={1}>
          <Button variant="contained" type="submit">
            Guardar
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
        </Stack>
      </Box>
    </form>
  );
};
