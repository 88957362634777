import { Box, Button } from "@mui/material";
import useSelectedCountry from "hooks/useSelectedCountry";
import SectorsList from "./SectorsList";
import { useState } from "react";
import { CreateSectorForm } from "./CreateSectorForm";
import { Sector } from "types/Sector";
import { useNavigate } from "react-router-dom";
import { setSelectedSector } from "redux/sectorsSlice";
import { Add, PlusOne } from "@mui/icons-material";
import { useAppDispatch } from "redux/hooks";
import useSelectedSector from "hooks/useSelectedSector";

export default function SectorsPage() {
  const navigate = useNavigate();
  const { changeSector } = useSelectedSector();
  const { selectedCountryId } = useSelectedCountry();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const handleClose = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };

  const onSectorSelected = (sector: Sector) => {
    navigate("/admin/sectors/" + sector.id + "/dashboard");
    changeSector(sector);
  };

  return (
    <>
      <Box p={4} display={"flex"} justifyContent={"center"}>
        {selectedCountryId ? (
          <Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                sx={{ display: "flex", justifyContent: "center" }}
                variant="contained"
                onClick={() => setIsCreateModalOpen(true)}
                startIcon={<Add />}
              >
                Nuevo sector
              </Button>
            </Box>
            <SectorsList onSectorSelected={onSectorSelected} countryId={selectedCountryId} />
          </Box>
        ) : (
          <h1>select country</h1>
        )}
      </Box>

      {selectedCountryId && (
        <CreateSectorForm
          handleClose={handleClose}
          countryId={selectedCountryId}
          isCreateModalOpen={isCreateModalOpen}
        />
      )}
    </>
  );
}
