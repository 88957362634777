import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import Storefront from "@mui/icons-material/Storefront";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Sector } from "types/Sector";
import { Branch } from "types/Branch";
import { useState } from "react";
import CreateEditBranch from "./CreateEditBranch";
import BranchesMap from "./BranchesMap";

type Props = {
  selectedBranch: Branch | null;
  sector: Sector;
  changeBranch: (branch: Branch) => void;
};

export default function BranchesTab({ sector, changeBranch, selectedBranch }: Props) {
  const branches = sector.branches;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddBranch = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item sm={3}>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleAddBranch}>
                <ListItemIcon>
                  <PlusOneIcon />
                </ListItemIcon>
                <ListItemText primary="Add Branch" />
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <Box
              sx={{
                height: "700px",
                overflow: "auto",
              }}
            >
              {branches?.map((branch) => {
                const { name, id } = branch;
                return (
                  <ListItem key={id} disablePadding>
                    <ListItemButton onClick={() => changeBranch(branch)}>
                      <ListItemIcon>
                        <Storefront />
                      </ListItemIcon>
                      <ListItemText primary={name} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </Box>
          </List>
        </Grid>
        <Grid item sm={9}>
          {branches && (
            <BranchesMap
              changeBranch={changeBranch}
              selectedBranch={selectedBranch}
              sector={sector}
              branches={branches}
            />
          )}
        </Grid>
      </Grid>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <>
          <CreateEditBranch branch={null} onCancel={handleCloseModal} />
        </>
      </Modal>
    </>
  );
}
