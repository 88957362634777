import chaletApi from "api/chaletApi";
import { ChaletApiResponse } from "types/ChaletApiResponse";
import { Travel } from "types/Travel";
import { VehicleType } from "types/VehicleType";

const VehicleTypesService = chaletApi.injectEndpoints({
  endpoints: (builder) => ({
    getVehicleTypes: builder.query<VehicleType[], void>({
      query: () => ({
        method: "GET",
        url: `/vehicle-types/`,
      }),
      providesTags: ["vehicleType"],
      transformResponse: (response: ChaletApiResponse<VehicleType[]>) => response.data,
    }),
    getVehicleTypesById: builder.query<VehicleType[], number>({
      query: (id) => ({
        method: "GET",
        url: `/vehicle-types/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<VehicleType[]>) => response.data,
    }),
    createVehicleType: builder.mutation<VehicleType, Partial<VehicleType>>({
      query: (body) => ({
        method: "POST",
        url: `/vehicle-types`,
        body,
      }),
      invalidatesTags: ["vehicleType"],
    }),
    editVehicleType: builder.mutation<VehicleType, Partial<VehicleType>>({
      query: (body) => ({
        method: "PUT",
        url: `/vehicle-types/${body.id}`,
        body,
      }),
      invalidatesTags: ["vehicleType"],
    }),
    deleteVehicleType: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `/vehicle-types/${id}`,
      }),
      invalidatesTags: ["vehicleType"],
    }),
  }),
  overrideExisting: false,
});

export default VehicleTypesService;
