import React from "react";
import { pdf } from "@react-pdf/renderer";
import { Box, Button } from "@mui/material";
import ReceiptLong from "@mui/icons-material/ReceiptLong";

import { InvoiceDocument } from "./InvoiceDocument";
import { Invoices } from "types/Invoices";

type BranchSummaryProps = {
  invoice: Invoices;
};

export const BranchSummaryPDFDownloadLink: React.FC<BranchSummaryProps> = ({ invoice }) => {
  console.log({
    fromDate: invoice.fromDateOrders,
    toDate: invoice.toDateOrders,
    invoice: invoice,
    billingDetail: invoice.billingDetail,
  });

  const openPdfInNewTab = async () => {
    const blob = await pdf(<InvoiceDocument invoice={invoice} />).toBlob();

    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  return (
    <Box pb={2}>
      <Button size="large" onClick={openPdfInNewTab} startIcon={<ReceiptLong />}>
        Ver PDF
      </Button>
    </Box>
  );
};
