import { Warning } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const UnAuthorizedPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Warning color="error" sx={{ fontSize: 80 }} />
      </Box>
      <Typography variant="h4" component="h1" gutterBottom>
        No tienes autorización para acceder a esta página
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
        Por favor, contacta al administrador si crees que esto es un error.
      </Typography>
      <Box>
        <Button variant="contained" color="primary" onClick={handleGoBack}>
          Volver atrás
        </Button>
      </Box>
    </Container>
  );
};

export default UnAuthorizedPage;
