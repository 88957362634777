import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { AddProductType } from "./ProductType/AddProductType";
import { AffiliatesList } from "./Affiliates/AffiliatesList";
import useSelectedSector from "hooks/useSelectedSector";
import chaletApi from "api/chaletApi";
import { Shop } from "types/Shop";
import { ProductType } from "types/ProductType";

import { useNavigate } from "react-router-dom";
import useSelectedShop from "hooks/useSelectedShop";

export type filteredShops = {
  shops: Shop[];
  id: number;
  sectorId: number;
  productTypeId: number;
  productType: ProductType;
};

export const Products = () => {
  const { selectedSectorId } = useSelectedSector();
  const { changeShop } = useSelectedShop();
  const { data: sectorProductTypes } = chaletApi.useGetSectorProductTypesQuery(selectedSectorId);
  const { data: shops } = chaletApi.useGetAllShopsQuery();

  const [isOpenModal, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleAffiliateSelected = (affiliate: Shop) => {
    navigate(`/admin/sectors/${selectedSectorId}/dashboard/products-services/affiliate/${affiliate.id}`);
    changeShop(affiliate);
  };

  // Filter shops by ProductTypeId
  const filteredShops = useMemo(() => {
    if (!sectorProductTypes || !shops) return [];
    return sectorProductTypes.map((sectorProductType) => {
      const shopsWithType = shops.filter((shop) => shop.productTypeId === sectorProductType.productTypeId);
      return { ...sectorProductType, shops: shopsWithType };
    });
  }, [sectorProductTypes, shops]);

  return (
    <Box display={"flex"} flexDirection={"row"} sx={{ pt: 2 }}>
      {filteredShops?.map((filteredShops) => (
        <AffiliatesList
          key={filteredShops.id}
          SectorTypeWithShops={filteredShops}
          onSelectAffiliate={handleAffiliateSelected}
        />
      ))}
      <AddProductType />
    </Box>
  );
};
