import { Avatar, Box, Card, Container, Divider, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import chaletApi from "api/chaletApi";
import React, { useEffect, useState } from "react";
import { CategoriesTable } from "../AffiliatePage/Tables/CategoriesTable";
import { useParams } from "react-router-dom";
import useSelectedShop from "hooks/useSelectedShop";
import { AddCategoryModal } from "./AddCategoryModal";
import { AddBox } from "@mui/icons-material";

export const AffiliatePage = () => {
  const { id } = useParams();
  const shopId = Number(id) || 0;

  const { selectedShop, changeShop } = useSelectedShop();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [getShopMenus, result] = chaletApi.useLazyGetCompleteMenuQuery();
  const [createMenu] = chaletApi.useCreateMenuMutation();
  const { palette } = useTheme();

  useEffect(() => {
    if (shopId) {
      getShopMenus(shopId);
    }
  }, [selectedShop]);

  useEffect(() => {
    if (result.data && result.data.length > 0) {
      changeShop(result.data[currentTab].shop);
    }
  }, [result.data, currentTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const createFirstMenu = () => {
    if (shopId) {
      const body = {
        name: "Menu",
        start: 0,
        end: 86400,
        shopId: shopId,
      };
      createMenu({ body });
    }
  };

  if (result.data && result.data.length > 0) {
    const currentMenu = result.data[currentTab];
    return (
      <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
        <Box display="flex" gap={1} m={2} flexDirection="row" alignItems="center">
          <Avatar src={selectedShop?.profileImage} sx={{ width: 45, height: 45 }} />
          <Typography variant="h5">{selectedShop?.name}</Typography>
        </Box>
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider", margin: 2 }}>
            <Tabs value={currentTab} onChange={handleChange}>
              {result.data?.map((menu) => {
                return <Tab key={menu.name} label={menu.name} />;
              })}
            </Tabs>
          </Box>
          {currentMenu && (
            <CategoriesTable data={currentMenu.menuCategories} productTypeId={currentMenu.shop.productTypeId} />
          )}

          {currentMenu && (
            <AddCategoryModal selectedMenu={currentMenu} correlative={currentMenu.menuCategories.length + 1} />
          )}
        </>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
        <Box display="flex" gap={1} m={2} flexDirection="row" alignItems="center">
          <Avatar src={selectedShop?.profileImage} sx={{ width: 45, height: 45 }} />
          <Typography variant="h5">{selectedShop?.name}</Typography>
        </Box>
        <Divider sx={{ borderBottom: 1, margin: 2 }} />

        <Card
          elevation={0}
          sx={{
            m: 2,
            width: 475,
            height: 95,
            border: "1px solid #ef3d32",
            backgroundColor: "#F1DEDC",
            display: "flex",
            justifyContent: "center",
            alignItems: "left",
            flexDirection: "column",
            position: "relative",
            ":hover": {
              boxShadow: "0 0 0 0px #fff, 0 0 0 2px #ef3d32",
              cursor: "pointer",
            },
          }}
          onClick={createFirstMenu}
        >
          <Stack direction={"row"} alignItems={"center"} marginLeft={3} spacing={2}>
            <AddBox
              sx={{
                width: 50,
                height: 50,
                color: palette.primary.main,
              }}
            />
            <Typography
              sx={{
                color: palette.primary.main,
                fontWeight: 500,
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              Crear Menú
            </Typography>
          </Stack>
        </Card>
      </Container>
    );
  }
};
