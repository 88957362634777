import { Avatar, Box, Card, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";
import { DriverSchedule } from "types/DriverSchedule";
import { AvatarDriver } from "./AvatarDriver";

type Props = {
  schedule: DriverSchedule;
};

export default function DriversListItem({ schedule }: Props) {
  const { driver } = schedule;
  const driverName = driver.user.name;

  const readableDate = useMemo(() => {
    return `${dayjs(driver.updatedAt).format("HH:mm a")}`;
  }, [schedule]);

  return (
    <Card sx={{ m: 1, width: 220, height: 70 }}>
      <ListItem key={driver.id}>
        <ListItemAvatar>
          <AvatarDriver
            url={driver.user.profileImage ? driver.user.profileImage : undefined}
            isBusy={driver.currentDeliveryOfferId !== null}
          />
        </ListItemAvatar>
        <ListItemText primary={driverName} secondary={readableDate} />
      </ListItem>
    </Card>
  );
}
