import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Branch } from "types/Branch";
import { Dayjs } from "dayjs";
import { BranchOrdersTable } from "./BranchOrdersTable/BranchOrdersTable";

type BranchDetailsCardProps = {
  fromDate: Dayjs;
  toDate: Dayjs;
  branch: Branch;
};

export const BranchDetailsCard: React.FC<BranchDetailsCardProps> = ({ fromDate, toDate, branch }) => {
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{branch.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BranchOrdersTable fromDate={fromDate} toDate={toDate} branch={branch} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
