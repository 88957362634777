import React from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField, Typography, useTheme } from "@mui/material";

type Props = {
  control: any;
  name: string;
  label: string;
  variant?: "filled" | "outlined" | "standard";
  gridSize?: number;
  sx?: any;
  disabled?: boolean;
};

const ReusableTextInput: React.FC<Props> = ({
  control,
  name,
  label,
  variant = "standard",
  gridSize = 6,
  disabled,
  sx,
}) => {
  const { palette } = useTheme();

  return (
    <Grid item xs={gridSize} sx={sx}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <TextField
              {...field}
              label={label}
              variant={variant}
              fullWidth
              disabled={disabled}
            />
            {fieldState.error && (
              <Typography fontSize={12} color={palette.error.main}>
                {fieldState.error.message}
              </Typography>
            )}
          </>
        )}
      />
    </Grid>
  );
};

export default ReusableTextInput;
