import {
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useSelectedSector from "hooks/useSelectedSector";
import React, { useState } from "react";
import TransportDriverService from "services/TransportDriverService";
import { PersonalDataModal } from "./components/PersonalDataModal";
import { CompletedTravelsModal } from "./components/CompletedTravelsModal";
import { Travel } from "types/Travel";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { License } from "types/Licenses";
import { Vehicle } from "types/Vehicles";

export const TransportDrivers = () => {
  const { selectedSectorId } = useSelectedSector();
  const { data: transportDrivers } = TransportDriverService.useGetTransportDriversQuery(selectedSectorId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [personalDataModal, setPersonalDataModal] = useState(false);
  const [travelsModal, setTravelsModal] = useState(false);
  const [travels, setTravels] = useState<Travel[] | null>(null);
  const [licences, setLicenses] = useState<License[] | null>(null);
  const [vehicles, setVehicles] = useState<Vehicle[] | null>(null);
  const [lastLocation, setLastLocation] = useState<google.maps.LatLngLiteral | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenPersonalDataModal = (vehicles: Vehicle[], licenses: License[], lastLocation: any) => {
    setPersonalDataModal(true);
    setAnchorEl(null);
    setVehicles(vehicles);
    setLicenses(licenses);
    setLastLocation({
      lat: lastLocation.coordinates[0],
      lng: lastLocation.coordinates[1],
    });
  };

  const handleClosePersonalDataModal = () => {
    setPersonalDataModal(false);
  };

  const handleOpenTravelsModal = (travels: Travel[]) => {
    setTravelsModal(true);
    setTravels(travels);
    setAnchorEl(null);
  };

  const handleCloseTravelsModal = () => {
    setTravelsModal(false);
    setTravels(null);
  };

  return (
    <Box>
      <Typography variant="h5">Lista de conductores</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ width: 700 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transportDrivers?.map((row) => (
              <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.user.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.user.email}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.user.phone}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.isActive ? <Chip label="Activo" color="success" /> : <Chip label="Inactivo" color="error" />}
                </TableCell>
                <TableCell>
                  {" "}
                  <div>
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      Detalles
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={() => handleOpenPersonalDataModal(row.vehicle, row.license, row.lastLocation)}>
                        Datos personales
                      </MenuItem>
                      <MenuItem onClick={() => handleOpenTravelsModal(row.travels)}>Viajes realizados</MenuItem>
                    </Menu>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {personalDataModal && vehicles && licences && (
        <PersonalDataModal
          open={personalDataModal}
          handleClose={handleClosePersonalDataModal}
          vehicles={vehicles}
          licenses={licences}
          lastLocation={lastLocation}
        />
      )}
      {travelsModal && travels && (
        <CompletedTravelsModal open={travelsModal} handleClose={handleCloseTravelsModal} travels={travels} />
      )}
    </Box>
  );
};
