import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Sector } from "types/Sector";

export interface SectorsState {
  selectedSectorId: number | null;
  selectedSectorTz: string;
}

const initialState: SectorsState = {
  selectedSectorId: null,
  selectedSectorTz: "America/El_Salvador",
};

export const sectorsSlice = createSlice({
  name: "sectors",
  initialState,
  reducers: {
    setSelectedSector: (state, action: PayloadAction<Sector>) => {
      state.selectedSectorId = action.payload.id;
      state.selectedSectorTz = action.payload.timezone;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setSelectedSector } = sectorsSlice.actions;

export default sectorsSlice;
