import React, { useState } from "react";
import { Box, Button, Card, Chip, Grid, Modal, Stack, Typography, useTheme } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import CloseIcon from "@mui/icons-material/Close";
import { formatDateWithTime, toCurrencyFormat } from "utils/formatter";
import { Order } from "types/Order";
import chaletApi from "api/chaletApi";
import toast from "react-hot-toast";
import { AddOrderDetailStep } from "./Steppers";
import { OrderDetailsView } from "./OrderDetailView";
import { OrderMap } from "./OrderMap";
import { Chat } from "@mui/icons-material";
import { ChatComponent } from "components/pages/ChatComponent";
import { OrderTimeline } from "./Steppers/OrderTimeline";

type MapMarker = {
  id: string;
  coordinates: [number, number];
  icon?: string;
};

type SectorOrderModalProps = {
  order: Order;
  handleClose: () => void;
  isModalOpen: boolean;
};

export const SectorOrderModal: React.FC<SectorOrderModalProps> = ({ order, handleClose, isModalOpen }) => {
  const [addItems, setAddItems] = useState(false);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [destinationMarker, setDestinationMarker] = useState<MapMarker | null>(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const { palette } = useTheme();
  const [cancelOrder] = chaletApi.useCancelOrderMutation();
  const [updateDestinationAndUserLocation] = chaletApi.useUpdateDestinationAndUserLocationMutation();

  const handleAddItems = () => {
    setAddItems((prevAddItems) => !prevAddItems);
  };

  const handleCancelOrder = async (id: number) => {
    const confirmation = window.confirm("¿Está seguro de cancelar la orden?");
    if (confirmation) {
      try {
        await cancelOrder(id);
        toast.success("Orden cancelada");
      } catch (error) {
        toast.error("Error al cancelar la orden");
      }
    }
  };

  const handleToggleEditLocation = () => {
    setIsEditingLocation((prev) => !prev);
  };

  const handleToggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  const handleSave = () => {
    try {
      const [longitude, latitude] = destinationMarker?.coordinates || [];

      updateDestinationAndUserLocation({
        id: order.id,
        location: {
          latitude,
          longitude,
        },
      })
        .unwrap()
        .then(() => {
          toast.success("Ubicación actualizada");
        })
        .catch(() => {
          toast.error("Error al actualizar la ubicación");
        });

      setIsEditingLocation(false);
    } catch (err) {
      toast.error("Error al actualizar la ubicación");
    }
  };

  const handleMarkersUpdate = (marker: MapMarker) => {
    setDestinationMarker(marker);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isChatOpen && order.currentOrderProcessStep.status === "out_for_delivery" ? (
        <ChatComponent orderId={`${order.id}`} userId={`${order.user.id}`} onClose={handleToggleChat} />
      ) : (
        <Card
          sx={{
            position: "relative",
            height: "100%",
            maxHeight: "90vh",
            overflow: "scroll",
          }}
          elevation={4}
        >
          <Box height="100%">
            <Typography variant="h4" mb={1} textAlign="center" fontWeight="bold">
              Detalles de la orden
            </Typography>
            <Grid container height="100%" p={4}>
              <Grid item xs={12} md={6}>
                <Box height="100%">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography textAlign="left" variant="subtitle2" color="secondary">
                      Preparado para: {formatDateWithTime(order.preparedByDate)}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" mt={2}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          marginRight: "0.5rem",
                        }}
                      >
                        Tienda:
                      </Typography>
                      <Typography sx={{ fontSize: 18 }}>{order.shop.name}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          marginRight: "0.5rem",
                        }}
                      >
                        Cliente:
                      </Typography>
                      <Typography sx={{ fontSize: 18 }}>{order.user.name}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          marginRight: "0.5rem",
                        }}
                      >
                        Telefono:
                      </Typography>
                      <Typography sx={{ fontSize: 18 }}>{order.user.phone}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          marginRight: "0.5rem",
                        }}
                      >
                        Dirección:
                      </Typography>
                      <Typography sx={{ fontSize: 18 }}>{order.route.destination.address}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          marginRight: "0.5rem",
                        }}
                      >
                        Referencia:
                      </Typography>
                      <Typography sx={{ fontSize: 18 }}>
                        {order.route.destination.reference || "Sin referencia"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={1} flex={1}>
                    <Box flex={1} ml={1} mr={1} mt={2}>
                      <Typography variant="h5" fontWeight="bold" mb={2}>
                        Productos
                      </Typography>
                      <OrderDetailsView details={order.details} />
                      <Box sx={{ mt: 1 }}>
                        <Box display={"flex"} sx={{ mt: 1 }}>
                          <Typography flex={1} fontWeight={"bold"}>
                            Delivery:{" "}
                          </Typography>
                          <Typography flex={0}>{toCurrencyFormat(order.deliveryPrice)}</Typography>
                        </Box>
                        <Box display={"flex"} sx={{ mt: 1 }}>
                          <Typography flex={1} fontWeight={"bold"}>
                            Total:{" "}
                          </Typography>
                          <Typography flex={0}>{toCurrencyFormat(order.total)}</Typography>
                        </Box>
                        {
                          <Button
                            variant={addItems ? "outlined" : "contained"}
                            onClick={handleAddItems}
                            sx={{
                              display: "block",
                              width: "50%",
                              margin: "auto",
                              mt: 2,
                              mb: 2,
                            }}
                          >
                            {addItems ? "Cancelar" : "Agregar productos"}
                          </Button>
                        }
                        {addItems && <AddOrderDetailStep branchId={order.branchId} orderId={order.id} />}
                      </Box>
                    </Box>
                  </Box>
                  <OrderTimeline timestamps={{ ...order.orderStatusTimes, createdAt: order.createdAt }} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} flex={1} alignItems="center" justifyContent="center" flexDirection="column">
                <OrderMap
                  order={order}
                  isEditingLocation={isEditingLocation}
                  onDestinationMarkerUpdate={handleMarkersUpdate}
                />
                <Box
                  sx={{
                    display: "flex",
                    mt: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<MapIcon />}
                      onClick={isEditingLocation ? handleSave : handleToggleEditLocation}
                    >
                      {isEditingLocation ? "Guardar ubicación" : "Cambiar ubicación de entrega"}
                    </Button>
                    {order.currentOrderProcessStep.status === "out_for_delivery" && (
                      <Button variant="outlined" color="primary" startIcon={<Chat />} onClick={handleToggleChat}>
                        Chat en tiempo real
                      </Button>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DoDisturbIcon />}
                    onClick={() => handleCancelOrder(order.id)}
                  >
                    Cancelar orden
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              backgroundColor: palette.primary.main,
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: "0px 0px 0px 0px",
            }}
          >
            <Typography color="white" fontWeight={600}>
              {order.id}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              backgroundColor: palette.primary.main,
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: "0px 0px 0px 0px",
            }}
          >
            <CloseIcon
              sx={{
                color: "white",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>
        </Card>
      )}
    </Modal>
  );
};

export default SectorOrderModal;
