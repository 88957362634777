import { AddBox, Edit } from "@mui/icons-material";
import { Card, IconButton, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import AffiliatesModal from "./AffiliatesModal/AffiliatesModal";

type props = {
  productTypeId: number;
};

export const NewAffiliate = ({ productTypeId }: props) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Card
        onClick={handleOpen}
        elevation={0}
        sx={{
          borderColor: palette.primary.main,
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: "12px",
          width: 170,
          height: 95,
          mb: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          ":hover": {
            boxShadow: "0 0 0 0px #fff, 0 0 0 2px #ef3d32",
            cursor: "pointer",
          },
        }}
      >
        <AddBox sx={{ width: 45, height: 45 }} />
        <Typography mt={1} fontWeight={"fontWeightMedium"}>
          Nuevo Afiliado
        </Typography>
      </Card>
      <AffiliatesModal open={open} handleClose={handleClose} productTypeId={productTypeId} />
    </>
  );
};
