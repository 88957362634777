import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Branch, BranchById } from "types/Branch";
import { ChaletApiResponse } from "types/ChaletApiResponse";
import { Country } from "types/Country";
import { Driver, DriverWithUser } from "types/Driver";
import { DriverDeposit } from "types/DriverDeposit";
import { DriverSchedule } from "types/DriverSchedule";
import { LoginResponse } from "types/Login";
import { Message } from "types/Message";

import { Order, ProductVariation, VariationOption } from "types/Order";
import { Product } from "types/Product";
import { ProductCategory } from "types/ProductCategory";
import { ProductType, SectorProductType } from "types/ProductType";
import { Sector } from "types/Sector";
import { Shift } from "types/Shift";
import { Shop, Menu, MenuCategory } from "types/Shop";
import { Device, User, UserWithDevice } from "types/User";
import { CreateDriverRequest } from "types/api/CreateDriverRequest copy";
import { GetAllDriverResponse } from "types/api/GetAllDriversResponse";
import { GetOffersBySectorResponse } from "types/api/GetOffersBySectorResponse";
import { ProductTypeRequest } from "types/api/ProductTypeRequest";

const chaletApi = createApi({
  reducerPath: "chaletApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    prepareHeaders(headers, api) {
      const store: any = api.getState();
      headers.set("Authorization", `Bearer ${store.auth.token}`);
    },
  }),
  tagTypes: [
    "orders",
    "shops",
    "shop",
    "branches",
    "sector",
    "sectors",
    "drivers",
    "shifts-in-date",
    "driver-schedules",
    "active-orders",
    "shop-menus",
    "sector-product-types",
    "complete-menu",
    "shop-product",
    "customersService",
    "product-types",
    "product-categories",
    "vehicleType",
    "users",
    "invoices",
  ],
  endpoints: (builder) => ({
    //Login
    login: builder.mutation<ChaletApiResponse<LoginResponse>, { email: string; password: string }>({
      query: ({ email, password }) => ({
        method: "POST",
        url: "/auth/login/admin",
        body: { email, password },
      }),
    }),
    loginWithToken: builder.mutation<ChaletApiResponse<LoginResponse>, string>({
      query: (token) => ({
        method: "POST",
        url: "/auth/login/token",
        body: { token },
      }),
    }),
    //Orders
    getActiveOrders: builder.query<ChaletApiResponse<Order[]>, number>({
      query: (shopId) => ({
        method: "GET",
        url: "/orders/active",
        params: { shopId, withorderProcesses: true },
      }),
    }),
    getOrdersInRange: builder.query<ChaletApiResponse<Order[]>, { from: string; to: string; shopId: number }>({
      query: ({ from, to, shopId }) => ({
        method: "GET",
        url: "/orders/all-in-dates",
        params: { from, to, shopId },
      }),
    }),
    getOrder: builder.query<ChaletApiResponse<Order>, number>({
      query: (orderId) => ({
        method: "GET",
        url: `/orders/${orderId}`,
      }),
    }),
    //Users
    getUserById: builder.query<ChaletApiResponse<User>, number>({
      query: (userId) => ({
        method: "GET",
        url: `/users/${userId}`,
      }),
    }),
    //Shops
    getShopById: builder.query<Shop, number>({
      query: (shopId) => ({
        method: "GET",
        url: `/shops/${shopId}`,
      }),
      providesTags: ["shop"],
      transformResponse: (response: ChaletApiResponse<Shop>) => {
        return response.data;
      },
    }),
    getAllShops: builder.query<Shop[], void>({
      query: () => ({
        method: "GET",
        url: `/shops/all`,
      }),
      transformResponse: (response: ChaletApiResponse<Shop[]>) => {
        return response.data;
      },
      providesTags: ["shops"],
    }),
    createShop: builder.mutation<ChaletApiResponse<Shop>, Shop>({
      query: (shop) => ({
        method: "POST",
        url: `/shops`,
        body: shop,
      }),
    }),
    updateShop: builder.mutation<ChaletApiResponse<Shop>, { shop: Shop; id: number }>({
      query: ({ shop, id }) => ({
        method: "PUT",
        url: `/shops/${id}`,
        body: shop,
      }),
    }),
    deleteShop: builder.mutation<ChaletApiResponse<Shop>, number>({
      query: (id) => ({
        method: "DELETE",
        url: `/shops/${id}`,
      }),
      invalidatesTags: ["shops"],
    }),
    getProductTypes: builder.query<ChaletApiResponse<ProductType[]>, void>({
      query: () => ({
        method: "GET",
        url: `/product-types`,
      }),
      providesTags: ["product-types"],
    }),
    getSectors: builder.query<Sector[], number>({
      query: (countryId: number) => ({
        method: "GET",
        url: `/sectors`,
        params: { countryId },
      }),
      providesTags: ["sectors"],
      transformResponse: (response: ChaletApiResponse<Sector[]>) => {
        return response.data;
      },
    }),
    getCountries: builder.query<Country[], void>({
      query: () => ({
        method: "GET",
        url: `/countries`,
      }),
      transformResponse: (response: ChaletApiResponse<Country[]>) => {
        return response.data;
      },
    }),
    getSector: builder.query<Sector, number>({
      query: (id) => ({
        method: "GET",
        url: `/sectors/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<Sector>) => {
        return response.data;
      },
      providesTags: ["sector"],
    }),
    createBranchWithAdmin: builder.mutation({
      query: (body) => ({
        method: "Post",
        url: `/branches/createBranchWithAdmin`,
        body: body,
      }),
      transformResponse: (response: ChaletApiResponse<Branch>) => {
        return response.data;
      },
      invalidatesTags: ["branches", "shops", "sector"],
    }),
    updateBranchWithAdmin: builder.mutation({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `/branches/updateBranchWithAdmin/${id}`,
        body,
      }),
      invalidatesTags: ["branches", "shops", "sector"],
      transformResponse: (response: ChaletApiResponse<Branch>) => {
        return response.data;
      },
    }),
    getAllDrivers: builder.query<GetAllDriverResponse, void>({
      query: () => ({
        method: "GET",
        url: `/drivers`,
      }),
      transformResponse: (response: ChaletApiResponse<GetAllDriverResponse>) => {
        return response.data;
      },
      providesTags: ["drivers"],
    }),
    createDriver: builder.mutation<Driver, CreateDriverRequest>({
      query: (body) => ({
        method: "POST",
        url: `/drivers`,
        body,
      }),
      transformResponse: (response: ChaletApiResponse<Driver | string>) => {
        if (typeof response.data === "string") throw new Error(response.data);
        return response.data;
      },
      invalidatesTags: ["drivers"],
    }),
    getAllActiveDriversInSector: builder.query<GetAllDriverResponse, number>({
      query: (sectorId: number) => ({
        method: "GET",
        url: `/drivers/active`,
        params: {
          sectorId,
        },
      }),
      transformResponse: (response: ChaletApiResponse<GetAllDriverResponse>) => {
        return response.data;
      },
      providesTags: ["drivers"],
    }),
    getAllShiftsInDate: builder.query<Shift[], { sectorId: number; startOfWeek: string }>({
      query: ({ sectorId, startOfWeek }) => ({
        method: "GET",
        url: `/shifts`,
        params: {
          sectorId,
          start: startOfWeek,
        },
      }),
      transformResponse: (response: ChaletApiResponse<Shift[]>) => {
        return response.data;
      },
      providesTags: ["shifts-in-date"],
    }),
    createWeekShifts: builder.mutation<any, { date: string; sectorId: number }>({
      query: (body) => ({
        method: "POST",
        url: `/shifts/week`,
        body,
      }),
      transformResponse: (response: ChaletApiResponse<any>) => {
        if (response.success === false) throw new Error(response.message);
      },
      invalidatesTags: ["shifts-in-date"],
    }),
    getDriverSchedules: builder.query<DriverSchedule[], number>({
      query: (shiftId: number) => ({
        method: "GET",
        url: `/driver-schedules`,
        params: {
          shiftId,
        },
      }),
      transformResponse: (response: ChaletApiResponse<DriverSchedule[]>) => {
        if (response.success === false) throw new Error(response.message);
        return response.data;
      },
      providesTags: ["driver-schedules"],
    }),
    createSector: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: `/sectors`,
        body,
      }),
      invalidatesTags: ["sectors"],
    }),
    updateSector: builder.mutation({
      query: ({ sectorId, body }) => ({
        method: "PUT",
        url: `/sectors/${sectorId}`,
        body,
      }),
      invalidatesTags: ["sectors"],
    }),
    getActiveOrdersBySector: builder.query<Order[], number>({
      query: (id) => ({
        method: "GET",
        url: `/sectors/sector-active-orders/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<Order[]>) => {
        return response.data;
      },
      providesTags: ["active-orders"],
    }),
    updateOrderDetailById: builder.mutation({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `/order-details/${id}`,
        body,
      }),
      invalidatesTags: ["active-orders"],
    }),
    deleteProductInOrder: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `/order-details/${id}`,
      }),
      invalidatesTags: ["active-orders"],
    }),
    cancelOrder: builder.mutation({
      query: (id) => ({
        method: "PUT",
        url: `/orders/cancel/sector-admin`,
        body: { reason: "Cancelado por el Administrador", orderId: id },
      }),
      invalidatesTags: ["active-orders"],
    }),
    addOrderDetail: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: `/order-details`,
        body,
      }),
    }),
    getShopMenuById: builder.query({
      query: (id) => ({
        method: "GET",
        url: `/shop-menus/forShop/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<Menu[]>) => {
        return response.data;
      },
    }),
    getProductById: builder.query<Product, number>({
      query: (id) => ({
        method: "GET",
        url: `/shop-products/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<Product>) => {
        return response.data;
      },
      providesTags: (result) => {
        return result?.id ? [{ type: "shop-product", id: result.id }] : ["shop-product"];
      },
    }),
    createOrderDetail: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: `/order-details`,
        body,
      }),
      invalidatesTags: ["active-orders"],
    }),
    getSectorProductTypes: builder.query({
      query: (id) => ({
        method: "GET",
        url: `/sector-product-types/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<SectorProductType[]>) => {
        return response.data;
      },
      providesTags: ["sector-product-types"],
    }),
    createSectorProductType: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: `/sector-product-types`,
        body,
      }),
      invalidatesTags: ["sector-product-types"],
    }),
    createAffiliate: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: `/shops/create/affiliate`,
        body,
      }),
      invalidatesTags: ["shops"],
    }),
    getCompleteMenu: builder.query({
      query: (shopId) => ({
        method: "GET",
        url: `/shop-menus/completeMenu/${shopId}`,
      }),
      providesTags: ["complete-menu"],
      transformResponse: (response: ChaletApiResponse<[Menu]>) => {
        // Ordenar menuCategories por correlative de menor a mayor
        const sortedMenus = response.data.map((menu) => ({
          ...menu,
          menuCategories: menu.menuCategories.sort((a, b) => a.correlative - b.correlative),
        }));

        return sortedMenus;
      },
    }),
    saveMenuCategory: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: `/shop-menu-categories`,
        body,
      }),
      invalidatesTags: ["complete-menu"],
    }),
    getProductCategories: builder.query<ProductCategory[], ProductTypeRequest | undefined>({
      query: (type) => ({
        method: "GET",
        url: `/product-categories?type=` + type,
      }),
      transformResponse: (response: ChaletApiResponse<ProductCategory[]>, meta, arg) => response.data,
    }),
    updateShopMenuCategory: builder.mutation({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `/shop-menu-categories/${id}`,
        body,
      }),
      invalidatesTags: ["complete-menu"],
      transformResponse: (response: ChaletApiResponse<MenuCategory>) => response.data,
    }),
    saveShopProduct: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: `/shop-products`,
        body,
      }),
      invalidatesTags: ["complete-menu"],
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) => response.data,
    }),
    editShopProduct: builder.mutation({
      query: ({ productId, body }) => ({
        method: "PUT",
        url: `/shop-products/${productId}`,
        body,
      }),
      invalidatesTags: ["complete-menu", "shop-product"],
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) => response.data,
    }),
    saveProductVariation: builder.mutation<ProductVariation, Partial<ProductVariation>>({
      query: (body) => ({
        method: "POST",
        url: `/product-variations`,
        body,
      }),
      invalidatesTags: ["shop-product"],
      transformResponse: (response: ChaletApiResponse<ProductVariation>, meta, arg) => response.data,
    }),
    saveProductVariationOption: builder.mutation<VariationOption, Partial<VariationOption>>({
      query: (body) => ({
        method: "POST",
        url: `/product-variation-options`,
        body,
      }),
      invalidatesTags: (result) => {
        return ["shop-product"];
      },
      transformResponse: (response: ChaletApiResponse<VariationOption>, meta, arg) => response.data,
    }),
    deleteShopProduct: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `/shop-products/${id}`,
      }),
      invalidatesTags: ["complete-menu"],
    }),
    updateIsAvailable: builder.mutation({
      query: (body) => ({
        method: "PUT",
        url: `/shop-products/update/isAvailable`,
        body,
      }),
      invalidatesTags: ["complete-menu"],
    }),

    updateProductVariation: builder.mutation({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `/product-variations/${id}`,
        body,
      }),
      invalidatesTags: ["shop-product"],
    }),
    deleteProductVariation: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `/product-variations/${id}`,
      }),
      invalidatesTags: ["shop-product"],
    }),
    updateProductVariationOption: builder.mutation({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `/product-variation-options/${id}`,
        body,
      }),
      invalidatesTags: ["shop-product"],
    }),
    deleteProductVariationOption: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `/product-variation-options/${id}`,
      }),
      invalidatesTags: ["shop-product"],
    }),
    CreateMenu: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "/shop-menus/",
        body,
      }),
      invalidatesTags: ["complete-menu"],
    }),
    getOrdersInRangebyBranch: builder.query<
      ChaletApiResponse<{
        currentPage: number;
        orders: Order[];
        totalOrders: 0;
        totalPages: 0;
      }>,
      { from: string; to: string; branchId: number }
    >({
      query: ({ from, to, branchId }) => ({
        method: "GET",
        url: "/orders/all-in-dates-by-branch",
        params: { from, to, branchId, limit: 50 },
      }),
    }),
    getSectorDashboard: builder.query({
      query: ({ from, to, sectorId }) => ({
        method: "GET",
        url: `/sectors/dashboard/${sectorId}`,
        params: { from, to },
      }),
    }),
    getCountryDashboard: builder.query({
      query: ({ from, to, countryId }) => ({
        method: "GET",
        url: `/countries/dashboard/${countryId}`,
        params: { from, to },
      }),
    }),
    createCustomerService: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: `/sectors/customer-service`,
        body,
      }),
      invalidatesTags: ["customersService"],
    }),
    getCustomersServices: builder.query({
      query: (id) => ({
        method: "GET",
        url: `/sectors/customers-services/${id}`,
      }),
      providesTags: ["customersService"],
    }),
    updateDestinationAndUserLocation: builder.mutation({
      query: ({ id, location }) => ({
        method: "POST",
        url: `/orders/${id}/update-location`,
        body: {
          location,
        },
      }),
      invalidatesTags: ["active-orders"],
    }),
    findDriverById: builder.query<DriverWithUser, number>({
      query: (id) => ({
        method: "GET",
        url: `/drivers/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<DriverWithUser>, meta, arg) => response.data,
    }),
    createDriverDeposit: builder.mutation<DriverDeposit, any>({
      query: (body: any) => ({
        method: "POST",
        url: `/driver-deposits/create`,
        body,
      }),
      transformResponse: (response: ChaletApiResponse<DriverDeposit>, meta, arg) => response.data,
    }),
    deleteDriver: builder.mutation<Driver, number>({
      query: (id) => ({
        method: "DELETE",
        url: `/drivers/${id}`,
      }),
      invalidatesTags: ["drivers"],
    }),
    updateDriver: builder.mutation<
      Driver,
      {
        id: number;
        name: string;
        email: string;
        phone: string;
        password?: string;
      }
    >({
      query: ({ id, name, email, phone, password }) => ({
        method: "PUT",
        url: `/drivers/${id}`,
        body: {
          name,
          email,
          phone,
          password,
        },
      }),
      invalidatesTags: ["drivers"],
    }),
    getBranchById: builder.query<BranchById, number>({
      query: (branchId) => ({
        method: "GET",
        url: `/branches/${branchId}`,
      }),
      providesTags: ["branches"],
      transformResponse: (response: ChaletApiResponse<BranchById>) => {
        return response.data;
      },
    }),
    createNewProductType: builder.mutation({
      query: (body) => ({
        method: "Post",
        url: `/product-types`,
        body: body,
      }),
      invalidatesTags: ["product-types"],
    }),
    getProductCategoriesByProductType: builder.query({
      query: (id) => ({
        method: "GET",
        url: `/product-categories/product-type/${id}`,
      }),
      transformResponse: (response: ChaletApiResponse<ProductCategory[]>) => {
        return response.data;
      },
      providesTags: ["product-categories"],
    }),
    createProductCategory: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: `/product-categories`,
        body,
      }),
      invalidatesTags: ["product-categories"],
    }),
    updateProductCategory: builder.mutation({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `/product-categories/${id}`,
        body,
      }),
      invalidatesTags: ["product-categories"],
    }),
    updateProductsCategoriesCorrelatives: builder.mutation({
      query: (body) => ({
        method: "PUT",
        url: `/product-categories/update/correlatives`,
        body,
      }),
      invalidatesTags: ["product-categories"],
    }),
    deleteProductCategory: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `/product-categories/${id}`,
      }),
      invalidatesTags: ["product-categories"],
    }),
    createCustomNotificationBySectorId: builder.mutation({
      query: ({ title, message, sectorId, type }) => ({
        method: "POST",
        url: `/sectors/create-custom-notification`,
        body: {
          title,
          message,
          sectorId,
          type,
        },
      }),
    }),
    getAllUsers: builder.query<ChaletApiResponse<UserWithDevice[]>, void>({
      query: () => ({
        method: "GET",
        url: `/users`,
      }),
    }),
    getAllDevices: builder.query<ChaletApiResponse<Device[]>, void>({
      query: () => ({
        method: "GET",
        url: `/devices`,
      }),
    }),
    getChatMessages: builder.query<
      ChaletApiResponse<{
        messages: Message[];
        order: Order;
      }>,
      string
    >({
      query: (orderId) => ({
        method: "GET",
        url: `/messages/get-chat-messages/${orderId}`,
      }),
    }),
    getAllOffersBySector: builder.query<ChaletApiResponse<GetOffersBySectorResponse>, number>({
      query: (sectorId) => ({
        method: "GET",
        url: `/delivery-offers/sector/${sectorId}`,
      }),
    }),
    getAssigningOrders: builder.query<ChaletApiResponse<any>, number>({
      query: (sectorId) => ({
        method: "GET",
        url: `/delivery-offers/assigning-orders/${sectorId}`,
      }),
    }),
  }),
});

export default chaletApi;
