import { Box, Button, Card, Checkbox, Stack, TextField, Typography } from "@mui/material";
import chaletApi from "api/chaletApi";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ProductVariation } from "types/Order";

type AddVariationFormProps = {
  productId: number;
  electionsQty?: number;
  onClose: () => void;
  variation?: ProductVariation;
};

const variationSchema = yup.object().shape({
  name: yup.string().required("el nombre es requerido"),
  type: yup.string().required("Debes marcar una opción"),
  required: yup.boolean(),
});

export const AddVariationForm: React.FC<AddVariationFormProps> = ({ productId, electionsQty, onClose, variation }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(variationSchema),
  });

  const [isEditing, setIsEditing] = useState(false);
  const [saveVariation, result] = chaletApi.useSaveProductVariationMutation();
  const [updateVariation] = chaletApi.useUpdateProductVariationMutation();

  useEffect(() => {
    if (variation) {
      setIsEditing(true);
    }
  }, [variation]);

  const onSubmit = async (data: any) => {
    try {
      if (isEditing && variation) {
        //edit mode
        const variationData = {
          name: data.name,
          required: data.required,
          type: data.type,
          included: data.required === true ? 1 : 0,
        };

        updateVariation({ id: variation.id, body: variationData })
          .unwrap()
          .then((payload) => {
            if (payload.success) {
              toast.success("Selección editada con correctamente");
              onClose();
            } else {
              toast.error("Ha ocurrido un error");
            }
          })
          .catch(() => {
            toast.error("Ha ocurrido un error, intentalo de nuevo");
          });
      } else {
        //save mode
        const variationData = {
          name: data.name,
          required: data.required,
          type: data.type,
          included: data.required === true ? 1 : 0,
          correlative: electionsQty ? electionsQty + 1 : 1,
          productId: productId,
          isDeleted: false,
        };
        saveVariation(variationData);
      }
    } catch (error) {
      toast.error("Error guardando variación");
    }
  };

  useEffect(() => {
    if (result.isSuccess) {
      toast("Variacion guardada");
      onClose();
    }
    if (result.isError) {
      toast("Error guardando variacion");
    }
  }, [result]);

  return (
    <Card sx={{ p: 2 }} elevation={1}>
      <Typography variant="h6" fontWeight={"bold"} justifyContent={"center"} display={"flex"} mb={2}>
        {isEditing ? "Editando Selección" : "Creando Selección"}
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-around"} alignItems={"center"}>
          <Stack direction={"column"} spacing={2}>
            <Controller
              name="name"
              control={control}
              defaultValue={variation?.name || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nombre"
                  placeholder="Ej: Tipo de pan"
                  type=""
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Stack>
          <Box flexDirection="row" display="flex" alignItems="center">
            <Typography>Requerido</Typography>
            <Controller
              name="required"
              control={control}
              defaultValue={variation?.required || false}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
              )}
            />
          </Box>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box flex={1}>
                <Typography>Múltiple</Typography>
              </Box>
              <Controller
                name="type"
                control={control}
                defaultValue={variation?.type || "multiple"}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value === "multiple"}
                    onChange={(e) => field.onChange(e.target.checked ? "multiple" : "single")}
                  />
                )}
              />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box flex={1}>
                <Typography>Único</Typography>
              </Box>
              <Controller
                name="type"
                control={control}
                defaultValue={variation?.type || "single"}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value === "single"}
                    onChange={(e) => field.onChange(e.target.checked ? "single" : "multiple")}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Stack direction={"row"} spacing={1} justifyContent={"center"}>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="contained" type="submit">
            {isEditing ? "Actualizar" : "Guardar"}
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};
