import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Menu } from "./Menu/Menu";
import { Outlet } from "react-router-dom";

export const DashboardTab = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
      }}
    >
      <Menu />

      <Outlet />
    </Box>
  );
};
