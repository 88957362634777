import { Box, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { BarChart, mangoFusionPalette } from "@mui/x-charts";
import React from "react";

type DataItem = {
  sectorId: string;
  name: string;
  completed: number;
  canceled: number;
  rejected: number;
};

type props = {
  data: DataItem[];
};

export const SectorOrdersChart = ({ data }: props) => {
  return (
    <Stack
      sx={{
        backgroundColor: grey[100],
        padding: 2,
        borderRadius: 8,
        height: 400,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ fontSize: "1.2rem", fontWeight: "600", textAlign: "center" }}
      >
        Distribución de Órdenes Completadas, Canceladas y Rechazadas por Sector
      </Typography>
      <BarChart
        dataset={data}
        yAxis={[{ scaleType: "band", dataKey: "name" }]}
        series={[
          {
            dataKey: "completed",
            label: "Ordenes Completadas",
            color: "#0277bd",
          },
          {
            dataKey: "canceled",
            label: "Ordenes Canceladas",
            color: "#c62828",
          },
          {
            dataKey: "rejected",
            label: "Ordenes Rechazadas",
            color: "#ef6c00",
          },
        ]}
        borderRadius={5}
        colors={mangoFusionPalette}
        layout="horizontal"
        grid={{ vertical: true }}
        height={450}
      />
    </Stack>
  );
};
