// ErrorMessageSnackbar.js
import React from "react";
import { Snackbar, Alert } from "@mui/material";

type ErrorMessageSnackbarProps = {
  open: boolean;
  errorMessage?: string | null;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
};

const ErrorMessageSnackbar: React.FC<ErrorMessageSnackbarProps> = ({
  open,
  errorMessage,
  handleClose,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorMessageSnackbar;
