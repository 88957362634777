import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAddCategoryModalOpen: false,
  isAddProductModalOpen: false,
  isEditProductModalOpen: false,
};

export const modalSlice = createSlice({
  name: "modal-slice",
  initialState,
  reducers: {
    openAddCategoryModal: (state) => {
      state.isAddCategoryModalOpen = true;
    },
    closeAddCategoryModal: (state) => {
      state.isAddCategoryModalOpen = false;
    },
    openAddProductModal: (state) => {
      state.isAddProductModalOpen = true;
    },
    closeAddProductModal: (state) => {
      state.isAddProductModalOpen = false;
    },
    openEditProductModal: (state) => {
      state.isEditProductModalOpen = true;
    },
    closeEditProductModal: (state) => {
      state.isEditProductModalOpen = false;
    },
  },
});

export const {
  openAddCategoryModal,
  closeAddCategoryModal,
  openAddProductModal,
  closeAddProductModal,
  openEditProductModal,
  closeEditProductModal,
} = modalSlice.actions;

export default modalSlice;
