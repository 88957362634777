import { IconButton, Modal, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { GoogleMap, InfoWindow, Marker, Polygon } from "@react-google-maps/api";
import { Branch } from "types/Branch";
import { Sector } from "types/Sector";
import EditIcon from "@mui/icons-material/Edit";
import shopIcon from "../../../../../../../assets/ic_store.svg";
import CreateEditBranch from "./CreateEditBranch";

type Props = {
  sector: Sector;
  branches: Branch[];
  selectedBranch?: Branch | null;
  changeBranch: (branch: Branch) => void;
};

export default function BranchesMap({ branches, selectedBranch, sector, changeBranch }: Props) {
  const mapRef = useRef<GoogleMap | null>(null);
  const theme = useTheme();
  const [windowAnchor, setWindowAnchor] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sectorPolygon = sector.area.map((point) => ({
    lat: point[1],
    lng: point[0],
  }));

  const handelEditBranch = () => {
    setIsModalOpen(true);
    setWindowAnchor(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const keyedBranches = useMemo(() => {
    if (branches) {
      const keyedBranches: any = {};
      branches?.forEach((branch) => {
        keyedBranches[branch.id] = branch;
      });
      return keyedBranches;
    }
    return {};
  }, [branches]);

  const branchesMarkers = useMemo(() => {
    const markers: any[] = [];
    branches?.forEach(({ location, id }) => {
      if (location) {
        markers.push({
          id,
          position: {
            lat: location && location.coordinates[1],
            lng: location && location.coordinates[0],
          },
        });
      }
    });
    return markers;
  }, [branches]);
  const infoWindowBranch = useMemo(() => {
    if (windowAnchor) {
      return keyedBranches[windowAnchor.branchId];
    }
    return null;
  }, [windowAnchor]);

  useEffect(() => {
    if (!selectedBranch) return;
    const newCenter = {
      lat: selectedBranch.location?.coordinates[1],
      lng: selectedBranch.location?.coordinates[0],
    };
    if (!newCenter.lat || !newCenter.lng) return;
    setWindowAnchor({ position: newCenter, branchId: selectedBranch.id });
  }, [selectedBranch]);

  const mapCenter = useMemo(() => {
    if (selectedBranch) {
      return {
        lat: selectedBranch.location.coordinates[1],
        lng: selectedBranch.location.coordinates[0],
      };
    }
    return { lat: 14.039797784213622, lng: -88.93481126520795 };
  }, [selectedBranch]);

  console.log("center", mapCenter);

  const handleMarkerClick = (position: any, branchId: number) => {
    const branch = branches.find((b) => b.id === branchId);
    if (branch) {
      changeBranch(branch);
    }
    setWindowAnchor({ position, branchId });
  };
  return (
    <>
      <GoogleMap ref={mapRef} center={mapCenter} zoom={15} mapContainerStyle={{ width: "100%", height: "750px" }}>
        <Polygon
          options={{
            fillColor: theme.palette.primary.light,
            strokeColor: theme.palette.primary.main,
          }}
          editable={false}
          draggable={false}
          path={sectorPolygon}
        />
        {branchesMarkers.map((branchMarkerInfo) => {
          const { id, position } = branchMarkerInfo;
          return (
            <Marker
              onClick={() => handleMarkerClick(position, id)}
              key={id}
              position={position}
              icon={{ url: shopIcon }}
            />
          );
        })}
        {windowAnchor && (
          <InfoWindow
            key={windowAnchor.branchId}
            position={windowAnchor.position}
            onCloseClick={() => setWindowAnchor(null)}
          >
            <>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h6">{infoWindowBranch?.name}</Typography>
                <IconButton color="primary" onClick={handelEditBranch}>
                  <EditIcon />
                </IconButton>
              </Stack>

              <Typography variant="body1">{infoWindowBranch?.address}</Typography>
            </>
          </InfoWindow>
        )}
      </GoogleMap>
      {selectedBranch && (
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            <CreateEditBranch branch={selectedBranch} onCancel={handleCloseModal} />
          </>
        </Modal>
      )}
    </>
  );
}
