import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { toCurrencyFormat } from "utils/formatter";
import { Order } from "types/Order";

type BranchOrdersTableRowProps = {
  row: Order;
};

export const BranchOrdersTableRow: React.FC<BranchOrdersTableRowProps> = ({ row }) => {
  const [open, setOpen] = React.useState(true);
  const time = new Intl.DateTimeFormat("es-ES", {
    dateStyle: "short",
    timeStyle: "short",
    hour12: true,
    timeZone: "America/El_Salvador",
  }).format(new Date(row.createdAt));

  const deliveryTime = new Intl.DateTimeFormat("es-ES", {
    dateStyle: "short",
    timeStyle: "short",
    hour12: true,
    timeZone: "America/El_Salvador",
  }).format(new Date(row.updatedAt));

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <h3>{row.id}</h3>
        </TableCell>
        <TableCell component="th" scope="row">
          {String(row.isCanceled)}
        </TableCell>

        <TableCell component="th" scope="row">
          {time}
        </TableCell>

        <TableCell component="th" scope="row">
          {deliveryTime}
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography>{toCurrencyFormat(row.productsTotal)}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Producto</TableCell>
                    <TableCell>Cantitad</TableCell>
                    <TableCell>Total ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map(({ name, quantity, price }, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {name}
                      </TableCell>
                      <TableCell>{quantity}</TableCell>
                      <TableCell>
                        <Typography variant="h6">{toCurrencyFormat(quantity * price)}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
