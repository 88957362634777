import React, { useState } from "react";
import { Box, Card, Chip, Stack, Typography, useTheme, Button, Modal } from "@mui/material";
import { Person, Home, LocationOn, Chat } from "@mui/icons-material";
import { formatDateWithTime } from "utils/formatter";
import { Order } from "types/Order";
import { ChatComponent } from "components/pages/ChatComponent";

type OrderOrdersProps = {
  order: Order;
};

const CardOrders: React.FC<OrderOrdersProps> = ({ order }) => {
  const { palette } = useTheme();

  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleChatOpen = () => {
    setIsChatOpen(true);
  };

  return (
    <>
      <Card
        sx={{
          margin: 1,
          display: "flex",
          position: "relative",
          minWidth: "300px",
          height: "250px",
          cursor: "pointer",
        }}
        elevation={4}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            ml: 2,
            mr: 2,
            mt: 2,
          }}
        >
          <Box>
            <Typography textAlign="left" variant="subtitle2" color="primary">
              {formatDateWithTime(order.updatedAt)}
            </Typography>
          </Box>

          <Box display="flex" mt={1}>
            <Stack direction={"row"} display={"flex"} alignItems={"center"} spacing={1}>
              <Typography variant="subtitle2">Estado actual:</Typography>
              <Chip label={order.currentOrderProcessStep.name} color="primary" />
            </Stack>
          </Box>

          <Box
            mt={1}
            flex={1}
            sx={{
              overflowX: "hidden",
            }}
          >
            <Typography variant="subtitle2" mb={1} textAlign="center" fontWeight="bold">
              Detalles del Pedido
            </Typography>
            <Stack spacing={1} ml={1} mr={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Person sx={{ color: palette.primary.main }} />
                <Typography variant="body2">
                  <strong>Cliente:</strong> {order.user.name}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Home sx={{ color: palette.primary.main }} />
                <Typography variant="body2">
                  <strong>Dirección:</strong> {order.route.destination.address}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <LocationOn sx={{ color: palette.primary.main }} />
                <Typography variant="body2">
                  <strong>Referencia:</strong> {order.route.destination.reference || "Sin referencia"}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            backgroundColor: palette.primary.main,
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2,
            borderRadius: "0px 0px 0px 10px",
          }}
        >
          <Typography color="white" fontWeight={600}>
            {order.id}
          </Typography>
        </Box>

        {order.currentOrderProcessStep.status === "out_for_delivery" && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<Chat />}
            sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
              padding: "8px 12px",
              borderRadius: "20px",
              fontSize: "0.875rem",
              fontWeight: "bold",
            }}
            onClick={handleChatOpen}
          >
            Chat
          </Button>
        )}
      </Card>
      <Modal
        open={isChatOpen}
        onClose={() => setIsChatOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ChatComponent orderId={`${order.id}`} userId={`${order.user.id}`} onClose={() => setIsChatOpen(false)} />
      </Modal>
    </>
  );
};

export default CardOrders;
