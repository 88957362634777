import React from "react";
import { Card, CardMedia, Typography, useTheme, Box, IconButton } from "@mui/material";
import { ProductCategory } from "types/ProductCategory";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";

type ProductCategoryCardProps = {
  productCategory: ProductCategory;
  handleDelete: (id: number) => void;
  isLoadingDeleted: boolean;
  handleEdit: (productCategory: ProductCategory) => void;
};

const ProductCategoryCard: React.FC<ProductCategoryCardProps> = ({
  productCategory,
  handleDelete,
  isLoadingDeleted,
  handleEdit,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: productCategory.id,
  });
  const { palette } = useTheme();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: 1.5,
    width: "100%",
    height: 100,
    borderRadius: "12px",
    background: "white",
    boxShadow: "4px 4px 8px #c7c7c7, -4px -4px 8px #ffffff",
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    ":hover": {
      boxShadow: "4px 4px 12px #bababa, -4px -4px 12px #ffffff",
      cursor: "pointer",
    },
  };

  return (
    <Card ref={setNodeRef} elevation={0} sx={style} {...attributes} {...listeners}>
      <CardMedia
        component="img"
        sx={{
          width: 100,
          height: "100%",
          borderRadius: "12px 0 0 12px",
        }}
        image={productCategory.image}
        alt={productCategory.name}
      />
      <Box
        sx={{
          flexGrow: 1,
          padding: 1.5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          fontWeight="bold"
          color={palette.primary.main}
          fontSize="14px"
          textAlign="left"
          sx={{
            textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
            marginBottom: "4px",
          }}
        >
          {productCategory.name}
        </Typography>
        <Typography
          fontWeight="medium"
          color={palette.grey[700]}
          fontSize="12px"
          textAlign="left"
          sx={{
            marginBottom: "4px",
          }}
        >
          Correlativo: {productCategory.correlative}
        </Typography>
        <Typography fontWeight="regular" color={palette.grey[500]} fontSize="12px" textAlign="left">
          {productCategory.description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "8px",
        }}
      >
        <IconButton aria-label="Actualizar" disabled={isLoadingDeleted} onClick={() => handleEdit(productCategory)}>
          <UpdateIcon
            sx={{
              color: palette.primary.main,
            }}
          />
        </IconButton>
        <IconButton aria-label="Eliminar">
          <DeleteIcon
            onClick={() => handleDelete(productCategory.id)}
            sx={{
              color: palette.primary.main,
            }}
          />
        </IconButton>
      </Box>
    </Card>
  );
};

export default ProductCategoryCard;
