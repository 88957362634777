import { Delete, Fullscreen } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  GoogleMap,
  InfoWindowF,
  Marker,
  Polygon,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone-lite";
import { Controller, useForm } from "react-hook-form";
import chaletApi from "api/chaletApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
};

type LatLng = {
  lat: number;
  lng: number;
};
const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

const sectorSchema = yup.object().shape({
  sectorName: yup.string().required("El nombre del sector es requerido"),
  adminName: yup.string().required("el nombre del administrador es requerido"),
  phone: yup
    .string()
    .max(9, "8 digits max")
    .phone("SV", "numero invalido")
    .required("telefono requerido"),
  email: yup
    .string()
    .email("formato de email invalido")
    .required("email es requerido"),
  tmpPassword: yup.string().required("La contraseña temporal es requerida "),
});

type props = {
  handleClose: () => void;
  countryId: number;
  isCreateModalOpen: boolean;
};

export const CreateSectorForm: React.FC<props> = ({
  handleClose,
  countryId,
  isCreateModalOpen,
}) => {
  const [markers, setMarkers] = useState<LatLng[]>([]);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null,
  );
  const [center, setCenter] = useState<google.maps.LatLng | undefined>(
    new window.google.maps.LatLng(14.04127919725104, -88.93810918198251),
  );
  const [createSector] = chaletApi.useCreateSectorMutation();
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sectorSchema),
  });

  useEffect(() => {
    //Calculate the center of the polygon when the cord exists.
    if (markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((coord) => bounds.extend(coord));
      const center = bounds.getCenter();
      setCenter(center);
    }
  }, [markers]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const clickedCoords = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      setMarkers((prevMarkers) => [...prevMarkers, clickedCoords]);
    }
  };

  const handleMarkerDrag = (
    index: number,
    event: google.maps.MapMouseEvent,
  ) => {
    if (event.latLng) {
      const draggedCoords = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      const updatedMarkers = [...markers];
      updatedMarkers[index] = draggedCoords;

      setMarkers(updatedMarkers);
    }
  };

  const handleMarkerRightClick = (
    event: google.maps.MapMouseEvent,
    index: number,
  ) => {
    if (event.latLng) {
      event.stop();
      setSelectedMarkerIndex(index);
    }
  };

  const handleDeleteMarker = () => {
    if (selectedMarkerIndex !== null) {
      const updatedMarkers = [...markers];
      updatedMarkers.splice(selectedMarkerIndex, 1);

      setMarkers(updatedMarkers);
    }
    setSelectedMarkerIndex(null);
  };

  const handleDeleteAll = () => {
    setMarkers([]);
  };

  const SaveSector = (data: any) => {
    if (markers.length > 0) {
      const formatedCoords = markers.map(({ lat, lng }) => [lng, lat]);

      const sectorData = {
        sectorName: data.sectorName,
        adminName: data.adminName,
        email: data.email,
        tmpPassword: data.tmpPassword,
        phone: data.phone,
        area: formatedCoords,
        countryId,
      };
      createSector(sectorData)
        .unwrap()
        .then((response) => {
          if (response.data.success === false) {
            alert(`Ha ocurrido un error: ${response.data.data}`);
          }

          handleClose();
          reset();
          setMarkers([]);
        })
        .catch((response) => {
          alert(`Ha ocurrido un error: ${response.data.data}`);
        });
    }
  };

  return (
    <Modal
      open={isCreateModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} component={"form"} onSubmit={handleSubmit(SaveSector)}>
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          sx={{ padding: 2, borderRadius: "5px" }}
        >
          <Typography>Crear nuevo sector</Typography>
          <Typography>Administrador de sector</Typography>
        </Box>
        <Divider />
        <Grid container>
          <Grid item md={5.9} pt={2} pl={2} pr={2}>
            <Box>
              <Box>
                <TextField
                  label="nombre del sector"
                  size="small"
                  fullWidth
                  error={!!errors.sectorName}
                  helperText={errors.sectorName?.message}
                  {...register("sectorName")}
                />
              </Box>
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem></Divider>
          <Grid item md={6} p={2}>
            <Stack spacing={1}>
              <Box display={"flex"}>
                <TextField
                  label="nombre"
                  size="small"
                  sx={{ flex: 1, mr: 1 }}
                  error={!!errors.adminName}
                  helperText={errors.adminName?.message}
                  {...register("adminName")}
                />
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      type="tel"
                      inputMode="numeric"
                      label="Teléfono"
                      size="small"
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                      onChange={(event) => {
                        const { value } = event.target;
                        field.onChange(MaskNumber(value));
                      }}
                      value={field.value}
                    />
                  )}
                />
              </Box>
              <TextField
                label="email"
                size="small"
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register("email")}
              />
              <TextField
                label="Contraseña temporal"
                size="small"
                fullWidth
                error={!!errors.tmpPassword}
                helperText={errors.tmpPassword?.message}
                {...register("tmpPassword")}
              />
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ position: "relative" }}>
          <GoogleMap
            zoom={12}
            options={{ fullscreenControl: false, streetViewControl: false }}
            mapContainerStyle={{ width: "100%", height: "450px" }}
            center={center}
            onClick={handleMapClick}
          >
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={marker}
                draggable={true}
                onDrag={(event) => handleMarkerDrag(index, event)}
                onRightClick={(event) => handleMarkerRightClick(event, index)}
              >
                {selectedMarkerIndex === index ? (
                  <InfoWindowF
                    onCloseClick={() => setSelectedMarkerIndex(null)}
                  >
                    <Box>
                      <IconButton onClick={handleDeleteMarker}>
                        <Delete color="error" />
                      </IconButton>
                    </Box>
                  </InfoWindowF>
                ) : null}
              </Marker>
            ))}
            {markers.length > 0 && (
              <Polygon
                paths={markers}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                }}
              />
            )}
            <Button
              variant="contained"
              style={{ position: "absolute", top: 10, right: 10 }}
              onClick={handleDeleteAll}
            >
              Limpiar Todo
            </Button>
          </GoogleMap>
          <Box
            style={{
              position: "absolute",
              top: markers.length === 0 ? -45 : -25,
              left: 10,
            }}
          >
            <Typography>Área de cobertura</Typography>
            {markers.length === 0 && (
              <Typography variant="subtitle2" color={"red"}>
                Selecciona el área de cobertura
              </Typography>
            )}
          </Box>
        </Box>
        <Divider />
        <Box display={"flex"} justifyContent={"flex-end"} gap={1} m={1}>
          <Button variant="contained" onClick={() => handleClose()}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={markers.length === 0}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
