import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddBox, CloudUpload } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import chaletApi from "api/chaletApi";
import { closeAddProductModal } from "redux/modalSlice";
import useSelectedShop from "hooks/useSelectedShop";
import { ProductTypeRequest } from "types/api/ProductTypeRequest";
import { pictureValidation } from "utils/pictureValidation";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BoxStyled = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFFFF",
  borderRadius: "0.5em",
  boxShadow: "0px 0px 50px 5px rgba(0, 0, 0, 0.5)",
  padding: 15,
  width: 700,
  height: 450,
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
  },
}));

type AddProductModalProps = {
  categoryId: number;
  productTypeId: number;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre del producto es requerido"),
  description: Yup.string()
    .required("La descripción es requerida")
    .max(240, "La descripción debe tener como máximo 240 caracteres"),
  price: Yup.number()
    .typeError("El precio debe ser un número")
    .positive("El precio debe ser un número positivo")
    .required("El precio es requerido"),
  category: Yup.number().required("Selecciona una categoría"),
});

export const AddProductModal: React.FC<AddProductModalProps> = ({ categoryId, productTypeId }) => {
  const [image, setImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { isAddProductModalOpen } = useAppSelector((s) => s.modalSlice);
  const { selectedShop } = useSelectedShop();

  const dispatch = useAppDispatch();

  const [postProduct, result] = chaletApi.useSaveShopProductMutation();
  const { data: categoriesData } = chaletApi.useGetProductCategoriesByProductTypeQuery(productTypeId);

  const handleClose = () => {
    setImage(null);
    setPreviewUrl(null);
    reset();
    dispatch(closeAddProductModal());
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      pictureValidation(file, setImage, setPreviewUrl);
    }
  };

  const saveProduct = (data: any) => {
    console.log({ data });
    try {
      if (selectedShop && image) {
        const formData = new FormData();
        formData.append("shopMenuCategoryId", String(categoryId));
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("price", data.price);
        formData.append("categoryId", data.category);
        formData.append("shopId", String(selectedShop.id));
        formData.append("productTypeId", String(selectedShop.productTypeId));
        formData.append("isDeleted", "false");
        formData.append("image", image);

        toast.promise(postProduct(formData), {
          loading: "Guardando...",
          success: <b>Producto Guardado!</b>,
          error: <b>Ha ocurrido un error.</b>,
        });
      } else if (selectedShop) {
        const productData = {
          shopMenuCategoryId: categoryId,
          name: data.name,
          description: data.description,
          price: Number(data.price),
          categoryId: Number(data.category),
          shopId: selectedShop.id,
          productTypeId: 1,
          isDeleted: false,
        };

        toast.promise(postProduct(productData), {
          loading: "Guardando...",
          success: <b>Producto Guardado!</b>,
          error: <b>Ha ocurrido un error.</b>,
        });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.errors.forEach((errorMessage: string) => toast.error(errorMessage));
      } else {
        toast.error("Error guardando producto");
      }
    }
  };

  useEffect(() => {
    if (result.isError) {
      toast("Error guardando menu");
    }
    if (result.isSuccess) {
      handleClose();
      setImage(null);
      setPreviewUrl(null);
    }
  }, [result, handleClose]);

  return (
    <Modal open={isAddProductModalOpen} onClose={handleClose}>
      <BoxStyled>
        <Typography textAlign={"center"} fontWeight={"bold"} variant="h5">
          Crear Producto
        </Typography>

        <Box display="flex" flexDirection="column">
          <Grid container>
            <Grid item md={6} p={1}>
              <TextField
                fullWidth
                sx={{ mt: 2 }}
                placeholder="Nombre"
                type="text"
                error={!!errors.name}
                helperText={errors.name?.message}
                {...register("name")}
              />

              <TextField
                fullWidth
                sx={{ mt: 2 }}
                placeholder="Descripción"
                multiline
                rows={3}
                error={!!errors.description}
                helperText={errors.description?.message}
                {...register("description")}
              />

              <TextField
                fullWidth
                sx={{ mt: 2 }}
                placeholder="Precio"
                type="number"
                error={!!errors.price}
                helperText={errors.price?.message}
                {...register("price")}
              />

              {categoriesData && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="category">Categoria</InputLabel>
                  <Controller
                    name="category"
                    control={control}
                    defaultValue={1}
                    render={({ field }) => (
                      <Select label="Categoria" {...field}>
                        {categoriesData.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />

                  <FormHelperText>{errors.category && errors.category.message}</FormHelperText>
                </FormControl>
              )}
            </Grid>
            <Grid item md={6} p={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Box>
                {previewUrl ? (
                  <Box position={"relative"} display={"flex"} justifyContent={"center"}>
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "#c7c7c7",
                        zIndex: 1,
                        "&:hover": {
                          backgroundColor: `rgba(128, 128, 128, 0.7)`,
                        },
                      }}
                      component="label"
                      color="primary"
                    >
                      <AddBox sx={{ width: 35, height: 35, color: "#534341" }} />
                      <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange} />
                    </IconButton>
                    <Avatar
                      src={previewUrl}
                      variant="square"
                      sx={{
                        width: 300,
                        height: 300,
                        backgroundColor: "#c7c7c7",
                        borderRadius: "28px",
                        m: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    />
                  </Box>
                ) : (
                  <IconButton
                    sx={{
                      width: 300,
                      height: 300,
                      backgroundColor: "#c7c7c7",
                      borderRadius: "28px",
                      m: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                    component="label"
                  >
                    <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange} />
                    <AddBox sx={{ width: 77, height: 77, color: "#534341" }} />
                    <Typography color={"#534341"}>Selecciona una foto</Typography>
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Stack direction={"row"} justifyContent={"center"} mt={2} spacing={2}>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleSubmit(saveProduct)}>
            Guardar
          </Button>
        </Stack>
      </BoxStyled>
    </Modal>
  );
};
