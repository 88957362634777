import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface LoadingIndicatorProps {
  size?: number;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  size = 40,
  color = "primary",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress size={size} color={color} />
    </Box>
  );
};

export default LoadingIndicator;
