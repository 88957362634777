import useUser from "hooks/useUser";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

type ProtectedRouteProps = {
  allowedRoles: string[];
  children?: React.ReactNode;
};

const ProtectedRoute = ({ allowedRoles, children }: ProtectedRouteProps) => {
  const user = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const { role } = user;
  useEffect(() => {
    if (role) {
      setHasAccess(allowedRoles.includes(role));
      setIsLoading(false);
    }
  }, [role, allowedRoles]);

  if (isLoading) {
    return <LoadingIndicator color="primary" />;
  }

  if (!hasAccess) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
