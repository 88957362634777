import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AppBar from "../../partials/AppBar/AppBar";
import { AdminDashboard, SectorsPage, SectorPage } from "components/pages";
import { ProtectedRoute } from "components/common";
import { ChaletRoles } from "hooks";

const appBarWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${appBarWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AdminMenu = () => {
  const [isMenuShown, setIsMenuShown] = useState(true);

  return (
    <AppBar width={appBarWidth} setIsOpen={setIsMenuShown}>
      <Main open={isMenuShown}>
        <Routes>
          <Route
            element={<ProtectedRoute allowedRoles={[ChaletRoles.admin]} />}
          >
            <Route path="/" element={<AdminDashboard />} />
          </Route>
          <Route
            element={<ProtectedRoute allowedRoles={[ChaletRoles.admin]} />}
          >
            <Route path="/sectors" element={<SectorsPage />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                allowedRoles={[
                  ChaletRoles.admin,
                  ChaletRoles.sectorAdmin,
                  ChaletRoles.customerService,
                ]}
              />
            }
          >
            <Route path="/sectors/:id/*" element={<SectorPage />} />
          </Route>
        </Routes>
      </Main>
    </AppBar>
  );
};

export default AdminMenu;
