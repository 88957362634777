import React, { useEffect, useState } from "react";
import { Box, Modal, Typography, IconButton } from "@mui/material";
import chaletApi from "api/chaletApi";
import ProductCategoryCard from "./ProductCategoryCard";
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ProductCategory } from "types/ProductCategory";
import AddIcon from "@mui/icons-material/Add";
import CreateProductCategory from "./CreateProductCategory";
import { Cancel } from "@mui/icons-material";
import toast from "react-hot-toast";
import UpdateProductCategory from "./UpdateProductCategory";

type ProductCategoriesModalProps = {
  handleClose: () => void;
  isOpen: boolean;
  productTypeId: number;
};

const ProductCategoriesModal: React.FC<ProductCategoriesModalProps> = ({ handleClose, isOpen, productTypeId }) => {
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editProductCategory, setEditProductCategory] = useState<ProductCategory | null>(null);

  const handleCreateProductCategory = () => {
    setIsCreate(true);
  };

  const handleEditProductCategory = (ProductCategory: ProductCategory) => {
    setIsEdit(true);

    setEditProductCategory(ProductCategory);
  };

  const [getProductCategories, { data: productCategories, isLoading, isError }] =
    chaletApi.useLazyGetProductCategoriesByProductTypeQuery();

  const [updateProductCategories] = chaletApi.useUpdateProductsCategoriesCorrelativesMutation();

  const [deleteProductCategory, { isLoading: isLoadingDeleted }] = chaletApi.useDeleteProductCategoryMutation();

  const [sortedCategories, setSortedCategories] = useState<ProductCategory[]>([]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  useEffect(() => {
    if (productCategories) {
      setSortedCategories([...productCategories].sort((a, b) => a.correlative - b.correlative));
    }
  }, [productCategories]);

  useEffect(() => {
    if (productTypeId !== null) {
      getProductCategories(productTypeId);
    }
  }, [productTypeId, getProductCategories]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = sortedCategories.findIndex((category) => category.id === active.id);
      const newIndex = sortedCategories.findIndex((category) => category.id === over.id);
      const newCategories = arrayMove(sortedCategories, oldIndex, newIndex);

      const updatedCategories = newCategories.map((category, index) => ({
        ...category,
        correlative: index + 1,
      }));

      setSortedCategories(updatedCategories);

      updateProductCategories({
        productCategories: updatedCategories.map((category) => ({
          id: category.id,
          correlative: category.correlative,
        })),
      });
    }
  };

  const handleDeleteProductCategory = (productCategoryId: number) => {
    toast.promise(
      deleteProductCategory(productCategoryId).unwrap(),
      {
        loading: "Eliminando ...",
        success: () => {
          const updatedCategories = sortedCategories
            .filter((category) => category.id !== productCategoryId)
            .map((category, index) => ({
              ...category,
              correlative: index + 1,
            }));

          setSortedCategories(updatedCategories);

          updateProductCategories({
            productCategories: updatedCategories.map((category) => ({
              id: category.id,
              correlative: category.correlative,
            })),
          });

          return "Categoría de producto eliminada";
        },
        error: "Error al eliminar la categoría de producto",
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 4000,
          icon: "👏",
        },
        error: {
          duration: 4000,
          icon: "❌",
        },
      },
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose();
        setSortedCategories([]);
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Box
        sx={{
          width: { xs: "90vw", sm: "70vw", md: "50vw", lg: "40vw" },
          bgcolor: "background.paper",
          borderRadius: 3,
          boxShadow: 24,
          maxHeight: "80vh",
          overflowY: "auto",
          padding: 4,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mb: 2,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {isCreate
              ? "Crear categoría de producto"
              : isEdit
              ? "Editar categoría de producto"
              : "Categorías de productos"}
          </Typography>
          <IconButton
            color="primary"
            onClick={isCreate ? () => setIsCreate(false) : handleCreateProductCategory}
            sx={{
              borderRadius: 2,
              padding: "8px",
              backgroundColor: "primary.main",
              color: "white",
              ":hover": {
                backgroundColor: "primary.secondary",
                color: "primary.main",
              },
              display: isEdit ? "none" : "flex",
            }}
          >
            {isCreate ? <Cancel /> : <AddIcon />}
          </IconButton>

          {isEdit && (
            <IconButton
              color="primary"
              onClick={() => setIsEdit(false)}
              sx={{
                borderRadius: 2,
                padding: "8px",
                backgroundColor: "primary.main",
                color: "white",
                ":hover": {
                  backgroundColor: "primary.secondary",
                  color: "primary.main",
                },
              }}
            >
              <Cancel />
            </IconButton>
          )}
        </Box>

        <Typography
          variant="body1"
          sx={{
            color: "text.secondary",
            display: isCreate || isEdit ? "none" : "block",
          }}
        >
          Arrastre para cambiar la prioridad de las categorías
        </Typography>
        {isCreate ? (
          <CreateProductCategory productTypeId={productTypeId} handleClose={handleClose} />
        ) : isEdit ? (
          editProductCategory && (
            <UpdateProductCategory handleClose={handleClose} productCategory={editProductCategory} />
          )
        ) : (
          <>
            {isLoading ? (
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                Cargando...
              </Typography>
            ) : isError ? (
              <Typography variant="body1" color="error">
                Ocurrió un error al cargar las categorías de productos
              </Typography>
            ) : sortedCategories.length ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                  <SortableContext
                    items={sortedCategories.map((category) => category.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    {sortedCategories.map((productCategory) => (
                      <ProductCategoryCard
                        isLoadingDeleted={isLoadingDeleted}
                        handleDelete={handleDeleteProductCategory}
                        key={productCategory.id}
                        productCategory={productCategory}
                        handleEdit={handleEditProductCategory}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              </Box>
            ) : (
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                No hay categorías de productos
              </Typography>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ProductCategoriesModal;
