import chaletApi from "api/chaletApi";
import { ChaletApiResponse } from "types/ChaletApiResponse";
import { TransportDriver } from "types/TransportDriver";

const TransportDriverService = chaletApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransportDrivers: builder.query<TransportDriver[], number>({
      query: (sectorId) => ({
        method: "GET",
        url: `/transport-drivers/${sectorId}`,
      }),
      transformResponse: (response: ChaletApiResponse<TransportDriver[]>) => response.data,
    }),
  }),
  overrideExisting: true,
});

export default TransportDriverService;
