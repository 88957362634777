import { Edit } from "@mui/icons-material";
import { Avatar, Card, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { Shop } from "types/Shop";

type props = {
  shop: Shop;
  onSelect: (shop: Shop) => void;
};

export const AffiliatesCard = ({ shop, onSelect }: props) => {
  const { palette } = useTheme();
  return (
    <Card
      elevation={0}
      sx={{
        borderColor: palette.primary.main,
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "12px",
        width: 170,
        height: 132,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        ":hover": {
          boxShadow: "0 0 0 0px #fff, 0 0 0 2px #ef3d32",
          cursor: "pointer",
        },
      }}
      onClick={() => onSelect(shop)}
    >
      <Avatar src={shop.profileImage} sx={{ width: 45, height: 45 }} />
      <Typography mt={1} fontWeight={"fontWeightMedium"} textAlign={"center"}>
        {shop.name}
      </Typography>
      <IconButton sx={{ position: "absolute", top: 0, right: 0 }}>
        <Edit color="primary" />
      </IconButton>
    </Card>
  );
};
