/* eslint-disable no-debugger */
import { Box, Button, CircularProgress } from "@mui/material";
import chaletApi from "api/chaletApi";
import { ErrorMessageSnackbar } from "components/common";
import dayjs from "dayjs";
import useSelectedSector from "hooks/useSelectedSector";
import { useEffect, useMemo, useState } from "react";
import { handleApiError } from "utils";
import ScheduleCalendar from "./ScheduleCalendar";

export default function SectorSchedule() {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const [createWeekShifts, createShiftsResult] = chaletApi.useCreateWeekShiftsMutation();
  const [getShifts, getShiftsResponse] = chaletApi.useLazyGetAllShiftsInDateQuery();
  const { selectedSectorId } = useSelectedSector();

  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));

  useEffect(() => {
    if (!selectedSectorId) return;
    getShifts({
      startOfWeek: startDate.toISOString(),
      sectorId: selectedSectorId,
    });
  }, []);

  useEffect(() => {
    if (!selectedSectorId) return;
    getShifts({
      startOfWeek: startDate.format(),
      sectorId: selectedSectorId,
    });
  }, [endDate, startDate]);

  useEffect(() => {
    if (!selectedSectorId) return;
    getShifts({
      startOfWeek: startDate.toISOString(),
      sectorId: selectedSectorId,
    })
      .unwrap()
      .then((shifts) => {
        if (shifts.length > 0) {
          getShifts({
            startOfWeek: startDate.toISOString(),
            sectorId: selectedSectorId,
          });
        }
      });
  }, []);

  const openSnackbar = () => {
    setIsSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  const onCreateWeekSchedule = () => {
    if (!selectedSectorId) return;

    createWeekShifts({ date: startDate.format(), sectorId: selectedSectorId })
      .unwrap()
      .then(() => {
        getShifts({
          startOfWeek: startDate.toISOString(),
          sectorId: selectedSectorId,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createShiftsView = () => {
    return (
      <Button
        variant="contained"
        onClick={onCreateWeekSchedule}
        fullWidth
        disabled={createShiftsResult.isLoading || getShiftsResponse.isLoading}
      >
        Create Week Schedule
      </Button>
    );
  };

  const errorMessage = useMemo(() => {
    if (createShiftsResult.error) {
      openSnackbar();
      return handleApiError(createShiftsResult.error);
    }
    if (getShiftsResponse.error) {
      openSnackbar();
      return handleApiError(getShiftsResponse.error);
    }
    return "";
  }, [createShiftsResult, getShiftsResponse]);

  const hasShifts = useMemo(() => {
    if (getShiftsResponse.data != null) {
      return getShiftsResponse.data.length > 0;
    }
    return false;
  }, [getShiftsResponse.data]);

  return (
    <>
      <Box display={"flex"} flexDirection={"row"}>
        <Box p={3}>
          <a>Text: </a>
          {getShiftsResponse.data?.length}
          {hasShifts ? <div>Has Shifts</div> : createShiftsView()}
        </Box>
        {getShiftsResponse.isFetching && (
          <Box alignItems="center" flex={1}>
            <CircularProgress />
          </Box>
        )}
        <Box flex={1}>
          {getShiftsResponse.data && (
            <ScheduleCalendar
              shifts={getShiftsResponse.data}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          )}
        </Box>
      </Box>
      <ErrorMessageSnackbar errorMessage={errorMessage} handleClose={closeSnackbar} open={isSnackbarOpen} />
    </>
  );
}
