import { Box, Button, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import chaletApi from "api/chaletApi";
import { FormTextInput } from "components/common";
import useUser from "hooks/useUser";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { DriverWithUser } from "types/Driver";
import * as yup from "yup";
import toast from "react-hot-toast";

const validationSchema = yup.object({
  amount: yup.number().min(0).required("required"),
});

const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async (data: any) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: "Ingrese una cantidad válida",
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );

type DepositForm = {
  amount: string;
};

export default function DriverDepositView({ driver, onComplete }: { driver: DriverWithUser; onComplete: () => void }) {
  const { palette } = useTheme();
  const { user } = useUser();
  const [createDeposit, createDepositResult] = chaletApi.useCreateDriverDepositMutation();
  const { control, handleSubmit, setValue } = useForm<DepositForm>({
    defaultValues: {
      amount: "",
    },
    resolver: useYupValidationResolver(validationSchema),
  });

  const onCreateDeposit = async ({ amount }: DepositForm) => {
    const userId = user?.id;
    const sectorBalanceId = user?.sectorWork.sector.sectorBalanceId;
    if (driver && userId && sectorBalanceId) {
      try {
        const deposit = await createDeposit({
          driverId: driver.id,
          amount,
          userId,
          sectorBalanceId,
        }).unwrap();
        setValue("amount", "");
        onComplete();
        console.log("Deposit result", deposit);
      } catch (error: any) {
        let msg = "Ha ocurrido un error";
        if (error.data && error.data.message) {
          msg += ` ${error.data.message}`;
        }
        toast.error(msg);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onCreateDeposit)}>
      <Box sx={{ backgroundColor: palette.background.default, padding: 2 }}>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Stack flexDirection="column">
            <Typography variant="h6">{driver?.user.name}</Typography>
            <Typography variant="body1">{driver?.user.phone}</Typography>
          </Stack>

          <FormTextInput
            control={control}
            label="Cantidad"
            name="amount"
            variant="outlined"
            disabled={createDepositResult.isLoading}
          />
          {createDepositResult.isLoading && <CircularProgress />}
          <Button
            variant="contained"
            disabled={createDepositResult.isLoading}
            type="submit"
            sx={{
              mt: 4,
              alignSelf: "center",
              mb: 4,
            }}
          >
            Realizar deposito
          </Button>
        </Stack>
      </Box>
    </form>
  );
}
