import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BranchTable } from "./BranchTable";
import { Branch } from "types/Branch";

type BranchCardProps = {
  branch: Branch;
};

export const BranchCard: React.FC<BranchCardProps> = ({ branch }) => {
  return (
    <>
      {branch.billingDetail && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{branch.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BranchTable branch={branch} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
