import React from "react";
import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import "yup-phone-lite";
import { useCustomForm } from "hooks";
import chaletApi from "api/chaletApi";
import useSelectedSector from "hooks/useSelectedSector";
import toast from "react-hot-toast";

const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

const customerServiceSchema = yup.object().shape({
  name: yup.string().required("El nombre del usuario es requerido"),
  phone: yup
    .string()
    .max(9, "8 dígitos máximo")
    .phone("SV", "numero inválido")
    .required("El numero de Teléfono requerido"),
  email: yup.string().email("Formato de email invalido").required("El email del usuario es requerido"),
  password: yup.string().required("La contraseña del usuario es requerido"),
});

export const CreateCustomerService = () => {
  const [createCustomerService] = chaletApi.useCreateCustomerServiceMutation();
  const { selectedSectorId } = useSelectedSector();

  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { errors },
  } = useCustomForm(
    {
      name: "",
      phone: "",
      email: "",
      password: "",
    },
    customerServiceSchema,
  );

  const create = (data: any) => {
    const customerData = {
      ...data,
      sectorId: selectedSectorId,
    };

    createCustomerService(customerData)
      .unwrap()
      .then(() => {
        toast.success("Usuario creado con éxito");
        reset();
      })
      .catch(() => {
        toast.error("Ha ocurrido un error");
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      component={"form"}
      onSubmit={handleSubmit(create)}
    >
      <Card elevation={3} sx={{ width: 350, padding: 2 }}>
        <Typography textAlign={"center"} fontSize={24} fontWeight={"bold"}>
          Crear Usuario
        </Typography>
        <Stack spacing={2} alignItems={"center"}>
          <TextField
            label="Nombre"
            variant="outlined"
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name?.message}
            sx={{ width: 300 }}
          />
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                type="tel"
                inputMode="numeric"
                label="Teléfono"
                variant="outlined"
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone?.message}
                onChange={(event) => {
                  const { value } = event.target;
                  field.onChange(MaskNumber(value));
                }}
                value={field.value}
                sx={{ width: 300 }}
              />
            )}
          />
          <TextField
            label="Email"
            variant="outlined"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            sx={{ width: 300 }}
          />
          <TextField
            type="password"
            label="Contraseña"
            variant="outlined"
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message}
            sx={{ width: 300 }}
          />
          <Button type="submit" variant="contained">
            Guardar
          </Button>
        </Stack>
      </Card>
    </Box>
  );
};
