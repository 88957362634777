import React from "react";
import Popover from "@mui/material/Popover";
import { FormTextInput } from "components/common";
import { Button, Grid, Stack } from "@mui/material";
import { useCustomForm } from "hooks";
import * as yup from "yup";

interface IEventPopupProps {
  event: any;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

type ShiftsFormType = {
  maxDrivers: number;
  deliveryFee: number;
  bonus: number;
};

const shiftSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  maxDrivers: yup.number().min(0).required("Max Drivers is required"),
  deliveryFee: yup.number().min(0).required("Delivery Fee is required"),
  bonus: yup.number().min(0).required("bonus is required"),
});

const EventPopup: React.FC<IEventPopupProps> = ({ event, anchorEl, onClose }) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { shift } = event.extendedProps;
  const { handleSubmit, control } = useCustomForm(
    {
      name: event.title,
      maxDrivers: shift.maxDrivers,
      deliveryFee: shift.deliveryFee,
      bonus: shift.bonus,
    },
    shiftSchema,
  );

  const onSubmit = async (data: ShiftsFormType) => {
    console.log("Driver DATA: ", data);
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Grid container p={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTextInput label="Event Title" control={control} gridSize={12} name="name" />
          <FormTextInput label="Delivery Fee" control={control} gridSize={12} name="deliveryFee" />
          <FormTextInput label="Bonus" control={control} gridSize={12} name="bonus" />
          <FormTextInput label="Max Drivers" control={control} gridSize={12} name="maxDrivers" />
          <Stack alignItems="center" justifyContent="flex-end" pb={2} gap={2} direction="row">
            <Button type="submit" variant="outlined" color="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Stack>
        </form>
      </Grid>
    </Popover>
  );
};

export default EventPopup;
