import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { GoogleMap, Marker } from "@react-google-maps/api";
import {
  Grid,
  Button,
  Card,
  Stack,
  Typography,
  Box,
  TextField,
  IconButton,
  styled,
  Divider,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { Branch } from "types/Branch";
import { ErrorMessageSnackbar, FormSelect, FormTextInput } from "components/common";
import useSelectedSector from "hooks/useSelectedSector";
import chaletApi from "api/chaletApi";
import { handleApiError } from "utils";
import { AddBox, Clear, Error } from "@mui/icons-material";
import { pictureValidation } from "utils/pictureValidation";
import toast from "react-hot-toast";

const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

const branchSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  email: yup.string().email("Email must be a valid format").required("Email is required"),
  minimumSale: yup.number().typeError("minimum Sale must be a number").required("Minimum Sale is required"),
  estimatedOrderTime: yup
    .number()
    .typeError("Estimated Order Time must be a number")
    .required("Estimated Order Time is required"),
  phone: yup.string().max(9, "8 digits max").phone("SV", "numero invalido").required("telefono requerido"),
  address: yup.string().required("Address is required"),
  location: yup
    .object()
    .shape({
      lat: yup.number().required(),
      lng: yup.number().required(),
    })
    .required("Location is required"),
  shopId: yup.number().required("Shop ID is required"),
  sectorId: yup.number().required("Sector ID is required"),
  adminName: yup.string().required("Admin Name is required"),
  adminPhone: yup.string().max(9, "8 digits max").phone("SV", "invalid Number").required(" Admin Phone is required"),
  adminEmail: yup.string().email("Email must be a valid format").required("Email is required"),
  tmpPassword: yup.string().required("Temporal Password is required"),
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type Props = {
  branch: Branch | null;
  onCancel: () => void;
};

type BranchFormType = Omit<
  Branch,
  "id" | "createdAt" | "updatedAt" | "location" | "profileImage" | "coverImage" | "workers" | "billingDetail"
> & {
  location?: google.maps.LatLngLiteral | null;
  adminName: string;
  adminPhone: string;
  adminEmail: string;
  tmpPassword: string;
};

const CreateEditBranch: React.FC<Props> = ({ branch, onCancel }) => {
  const { selectedSectorId } = useSelectedSector();
  const { data: shops } = chaletApi.useGetAllShopsQuery();
  const [createBranch, { isLoading, error }] = chaletApi.useCreateBranchWithAdminMutation();
  const [updateBranch, updateBranchResult] = chaletApi.useUpdateBranchWithAdminMutation();
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [previewProfileImage, setPreviewProfileImage] = useState<string | null>(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [previewCoverImage, setPreviewCoverImage] = useState<string | null>(null);

  useEffect(() => {
    if (branch) {
      setIsEditing(true);
    }
  }, [branch]);

  const useYupValidationResolver = (validationSchema: yup.ObjectSchema<BranchFormType>) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {},
            ),
          };
        }
      },
      [validationSchema],
    );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<BranchFormType>({
    defaultValues: branch
      ? {
          ...branch,
          location: {
            lat: branch.location?.coordinates[1],
            lng: branch.location?.coordinates[0],
          },

          adminName: branch.workers?.[0]?.user?.name || "",
          adminEmail: branch.workers?.[0]?.user?.email || "",
          adminPhone: branch.workers?.[0]?.user?.phone || "",
          tmpPassword: "",
        }
      : {
          name: "",
          description: "",
          email: "",
          minimumSale: 5,
          estimatedOrderTime: 20,
          phone: "",
          address: "",
          shopId: undefined,
          location: null,
          sectorId: undefined,
          adminName: "",
          adminEmail: "",
          adminPhone: "",
          tmpPassword: "",
        },
    resolver: useYupValidationResolver(branchSchema),
  });

  const handleMapClick = ({ latLng }: google.maps.MapMouseEvent) => {
    if (!latLng) return;
    const coords = { lat: latLng.lat(), lng: latLng.lng() };
    setValue("location", coords);
    console.log(coords);
  };

  const handleMarkerDrag = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const draggedCoords = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setValue("location", draggedCoords);
    }
  };

  const handleChangeProfileImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      pictureValidation(file, setProfileImage, setPreviewProfileImage);
    }
  };

  const handleChangeCoverImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      pictureValidation(file, setCoverImage, setPreviewCoverImage);
    }
  };

  const onSubmit = async (data: BranchFormType) => {
    console.log("DATA: ", data);
    if (!data.location) return;
    try {
      if (isEditing && branch) {
        //Edit Branch

        if (profileImage || coverImage) {
          const formData = new FormData();
          formData.append("name", data.name);
          formData.append("description", data.description);
          formData.append("email", data.email);
          formData.append("minimumSale", String(data.minimumSale));
          formData.append("estimatedOrderTime", String(data.estimatedOrderTime));
          formData.append("phone", String(data.phone));
          formData.append("address", data.address);
          formData.append("latitude", String(data.location.lat));
          formData.append("longitude", String(data.location.lng));
          formData.append("shopId", String(data.shopId));
          formData.append("sectorId", String(data.sectorId));
          formData.append("adminId", String(branch.workers?.[0]?.user?.id || ""));
          formData.append("adminName", String(data.adminName));
          formData.append("adminEmail", String(data.adminEmail));
          formData.append("adminPhone", String(data.adminPhone));
          formData.append("tmpPassword", String(data.tmpPassword));

          if (profileImage) {
            formData.append("profileImage", profileImage);
          }

          if (coverImage) {
            formData.append("coverImage", coverImage);
          }
          updateBranch({
            id: branch.id,
            body: formData,
          })
            .unwrap()
            .then(() => {
              console.log("success!");
              onCancel();
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          updateBranch({
            id: branch.id,
            body: {
              ...data,
              latitude: data.location?.lat,
              longitude: data.location?.lng,
              adminId: branch.workers?.[0]?.user?.id || "",
            },
          })
            .unwrap()
            .then(() => {
              console.log("success!");
              onCancel();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } else {
        //Create a new branch

        if (!profileImage || !coverImage) {
          toast.error("Imagen de portada y perfil son requeridas");
          return;
        }

        if (profileImage && coverImage) {
          const formData = new FormData();
          formData.append("name", data.name);
          formData.append("description", data.description);
          formData.append("email", data.email);
          formData.append("minimumSale", String(data.minimumSale));
          formData.append("estimatedOrderTime", String(data.estimatedOrderTime));
          formData.append("phone", String(data.phone));
          formData.append("address", data.address);
          formData.append("latitude", String(data.location.lat));
          formData.append("longitude", String(data.location.lng));
          formData.append("shopId", String(data.shopId));
          formData.append("sectorId", String(data.sectorId));
          formData.append("adminName", String(data.adminName));
          formData.append("adminEmail", String(data.adminEmail));
          formData.append("adminPhone", String(data.adminPhone));
          formData.append("tmpPassword", String(data.tmpPassword));
          formData.append("profileImage", profileImage);
          formData.append("coverImage", coverImage);

          createBranch(formData)
            .unwrap()
            .then(() => {
              console.log("success!");
              onCancel();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    } catch (error) {
      console.log("Error", error);
      setOpen(true);
    }
  };

  useEffect(() => {
    setValue("sectorId", selectedSectorId);
  }, [selectedSectorId]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const markerLocation = getValues("location");

  const errorMessage = useMemo(() => {
    if (error) {
      setOpen(true);
      return handleApiError(error);
    }
    if (updateBranchResult.error) {
      setOpen(true);
      return handleApiError(updateBranchResult.error);
    }
    return "";
  }, [error, updateBranchResult]);

  const shopOptions = useMemo(() => {
    if (shops) {
      return shops.map(({ id, name }) => ({ label: name, value: id }));
    }
  }, [shops]);

  return (
    <>
      <Card
        sx={{
          width: { sm: "70%", xs: "100%" },
          height: { sm: "80%", xs: "100%" },
          p: 2,
          overflowY: "scroll",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack flex={1}>
            <Typography variant="h5">{isEditing ? "Editar sucursal" : "Crear sucursal"}</Typography>
            <Grid container sx={{ direction: { xs: "row", sm: "row" } }} spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Box sx={{ position: "relative" }}>
                  {previewCoverImage || isEditing ? (
                    <Box sx={{ position: "relative" }}>
                      <Box>
                        <Avatar
                          variant="square"
                          sx={{
                            width: "100%",
                            height: 200,
                            borderRadius: "10px",
                          }}
                          src={previewCoverImage ? previewCoverImage : branch?.coverImage}
                        ></Avatar>
                      </Box>
                      {isEditing ? (
                        <IconButton
                          sx={{
                            position: "absolute",
                            backgroundColor: "#000000",
                            color: "#FFFFFF",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            opacity: 0.5,
                            ":hover": {
                              backgroundColor: "#656565",
                            },
                          }}
                          component="label"
                        >
                          <VisuallyHiddenInput type="file" accept="image/*" onChange={handleChangeCoverImage} />
                          <AddBox />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setPreviewCoverImage(null);
                            setCoverImage(null);
                          }}
                          sx={{
                            position: "absolute",
                            backgroundColor: "#000000",
                            color: "#FFFFFF",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            opacity: 0.5,
                            ":hover": {
                              backgroundColor: "#656565",
                            },
                          }}
                        >
                          <Clear />
                        </IconButton>
                      )}
                    </Box>
                  ) : (
                    <IconButton
                      sx={{
                        width: "100%",
                        height: 200,
                        backgroundColor: "#747474",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        ":hover": {
                          backgroundColor: "#313131",
                        },
                      }}
                      component="label"
                    >
                      <VisuallyHiddenInput type="file" accept="image/*" onChange={handleChangeCoverImage} />
                      <AddBox sx={{ width: 77, height: 77, color: "#cecece" }} />
                      <Typography color={"#cecece"}>Imagen de portada</Typography>
                    </IconButton>
                  )}

                  {previewProfileImage || isEditing ? (
                    <Box sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: -50,
                          left: 10,
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ position: "relative" }}>
                          <Box>
                            <Avatar
                              sx={{
                                width: 120,
                                height: 120,
                                borderRadius: "50%",
                                border: "3px solid #FFFFFF",
                              }}
                              src={previewProfileImage ? previewProfileImage : branch?.profileImage}
                            ></Avatar>
                            {isEditing ? (
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  backgroundColor: "#000000",
                                  color: "#FFFFFF",
                                  left: "50%",
                                  top: "50%",
                                  transform: "translate(-50%, -50%)",
                                  opacity: 0.5,
                                  ":hover": {
                                    backgroundColor: "#656565",
                                  },
                                }}
                                component="label"
                              >
                                <VisuallyHiddenInput type="file" accept="image/*" onChange={handleChangeProfileImage} />
                                <AddBox />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => {
                                  setPreviewProfileImage(null);
                                  setProfileImage(null);
                                }}
                                sx={{
                                  position: "absolute",
                                  backgroundColor: "#000000",
                                  color: "#FFFFFF",
                                  left: "50%",
                                  top: "50%",
                                  transform: "translate(-50%, -50%)",
                                  opacity: 0.5,
                                  ":hover": {
                                    backgroundColor: "#656565",
                                  },
                                }}
                              >
                                <Clear />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <IconButton
                      sx={{
                        position: "absolute",
                        width: 120,
                        height: 120,
                        backgroundColor: "#747474",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        bottom: -50,
                        left: 10,
                        border: "3px solid #FFFFFF",
                        ":hover": {
                          backgroundColor: "#313131",
                        },
                      }}
                      component="label"
                    >
                      <VisuallyHiddenInput type="file" accept="image/*" onChange={handleChangeProfileImage} />
                      <AddBox sx={{ width: 40, height: 40, color: "#cecece" }} />
                      <Typography color={"#cecece"} fontSize={12}>
                        Imagen de perfil
                      </Typography>
                    </IconButton>
                  )}
                </Box>

                <Grid item container spacing={2} sx={{ direction: { xs: "row", sm: "row" } }}>
                  <Grid item xs={12} sm={12} md={12} mt={8}>
                    <Grid item container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                          <FormTextInput
                            variant="outlined"
                            control={control}
                            label="Nombre"
                            gridSize={12}
                            name="name"
                          />
                          <FormTextInput
                            variant="outlined"
                            control={control}
                            label="Descripción"
                            gridSize={12}
                            name="description"
                          />
                          <FormTextInput
                            variant="outlined"
                            control={control}
                            label="Email"
                            gridSize={12}
                            name="email"
                          />
                          <FormTextInput
                            variant="outlined"
                            control={control}
                            label="Venta minima"
                            gridSize={12}
                            name="minimumSale"
                          />
                          <FormTextInput
                            variant="outlined"
                            control={control}
                            label="Tiempo estimado de orden"
                            gridSize={12}
                            name="estimatedOrderTime"
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                type="tel"
                                inputMode="numeric"
                                label="Teléfono"
                                variant="outlined"
                                fullWidth
                                error={!!errors.phone}
                                helperText={errors.phone?.message}
                                onChange={(event) => {
                                  const { value } = event.target;
                                  field.onChange(MaskNumber(value));
                                }}
                                value={field.value}
                              />
                            )}
                          />
                          <FormTextInput
                            variant="outlined"
                            control={control}
                            label="Dirección"
                            gridSize={12}
                            name="address"
                          />
                          <FormSelect
                            variant="outlined"
                            control={control}
                            label="Tienda perteneciente"
                            gridSize={12}
                            name="shopId"
                            options={shopOptions}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Divider sx={{ width: "100%" }}>Administrador</Divider>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    {Object.keys(branch?.workers || {}).length === 0 && isEditing && (
                      <Typography color={"red"} textAlign={"center"} fontSize={12}>
                        Esta sucursal no tiene un administrador, ingrese uno
                      </Typography>
                    )}

                    <Grid item container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Stack spacing={1}>
                          <FormTextInput
                            variant="outlined"
                            control={control}
                            label="Nombre del administrador"
                            gridSize={12}
                            name="adminName"
                          />
                          <Controller
                            name="adminPhone"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                type="tel"
                                inputMode="numeric"
                                label="Telefono del administrador"
                                variant="outlined"
                                fullWidth
                                error={!!errors.adminPhone}
                                helperText={errors.adminPhone?.message}
                                onChange={(event) => {
                                  const { value } = event.target;
                                  field.onChange(MaskNumber(value));
                                }}
                                value={field.value}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Stack spacing={1}>
                          <FormTextInput
                            variant="outlined"
                            control={control}
                            label="Email del Administrador"
                            gridSize={12}
                            name="adminEmail"
                          />
                          <FormTextInput
                            variant="outlined"
                            control={control}
                            label="Contraseña temporal"
                            gridSize={12}
                            name="tmpPassword"
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: { xs: "400px", sm: "750px" },
                    position: "relative",
                  }}
                >
                  <GoogleMap
                    center={markerLocation || { lat: 14.0398, lng: -88.9348 }}
                    zoom={15}
                    mapContainerStyle={{ height: "100%" }}
                    onClick={handleMapClick}
                  >
                    <Controller
                      name="location"
                      control={control}
                      render={({ field }) =>
                        field.value ? (
                          <Marker position={field.value} draggable={true} onDrag={(event) => handleMarkerDrag(event)} />
                        ) : (
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 30,
                              backgroundColor: "#FFFFFF",
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                              padding: 1,
                              alignItems: "center",
                            }}
                          >
                            <Error color="error" />
                            <Typography fontSize={"20px"} color={"red"}>
                              Ingresa una ubicación
                            </Typography>
                          </Box>
                        )
                      }
                    />
                  </GoogleMap>
                </Box>
              </Grid>
            </Grid>
          </Stack>
          <Stack alignItems="center" justifyContent="flex-end" pb={2} pt={2} gap={2} direction="row">
            <Button type="submit" variant="outlined" color="primary" onClick={onCancel} disabled={isLoading}>
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : "Guardar"}
            </Button>
          </Stack>
        </form>
      </Card>
      <ErrorMessageSnackbar errorMessage={errorMessage} handleClose={handleClose} open={open} />
    </>
  );
};

export default CreateEditBranch;
