import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { TravelMap } from "components/partials/TravelMap/TravelMap";
import useSelectedSector from "hooks/useSelectedSector";
import TravelOfferService from "services/TravelOfferService";
import { TravelCard } from "components/partials/TravelCard/TravelCard";

export const ActiveTravelOffers = () => {
  const { selectedSectorId } = useSelectedSector();
  const { data: travelOffers } = TravelOfferService.useGetTravelOffersActiveQuery(selectedSectorId);

  const [originCoordinates, setOriginCoordinates] = useState<google.maps.LatLngLiteral | null>(null);
  const [destinationCoordinates, setDestinationCoordinates] = useState<google.maps.LatLngLiteral | null>(null);

  const handleUpdateCoordinates = (origin: any, destination: any) => {
    setOriginCoordinates(origin);
    setDestinationCoordinates(destination);
  };

  return (
    <Grid container xl>
      <Grid item sm={3}>
        <Box
          sx={{
            height: "700px",
            overflowY: "auto",
          }}
        >
          {travelOffers?.map((travelOffer) => (
            <TravelCard
              key={travelOffer.id}
              travelOffer={travelOffer}
              showTransporDriver={false}
              setCoordinates={handleUpdateCoordinates}
            />
          ))}
        </Box>
      </Grid>
      <Grid item sm={9}>
        <TravelMap originCoordinates={originCoordinates} destinationCoordinates={destinationCoordinates} />
      </Grid>
    </Grid>
  );
};
