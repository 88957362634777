import { Box, Typography, useTheme } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payments";
import { useState } from "react";
import CreateDepositModal from "./CreateDepositModal";

export default function CreateDepositButton() {
  const { palette } = useTheme();
  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Box>
        <Box
          onClick={handleOpenModal}
          display="flex"
          flexDirection="column"
          alignItems="center"
          bgcolor="primary.main"
          borderRadius="8px"
          padding="16px"
          sx={{ cursor: "pointer" }}
        >
          <PaymentIcon fontSize="large" style={{ color: palette.common.white }} />
          <Typography color="background.paper" fontSize="large" fontWeight="medium">
            Realizar deposito
          </Typography>
        </Box>
      </Box>
      <CreateDepositModal isOpen={isModalOpen} handleClose={closeModal} />
    </>
  );
}
