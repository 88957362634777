import { Grid, Typography } from "@mui/material";

const InvoicingPage = () => {
  return (
    <>
      <Grid container p={4}>
        <Typography variant="h4">InvoicingPage</Typography>
      </Grid>
    </>
  );
};

export default InvoicingPage;
