import React from "react";
import { Alert, Box, Button, Stack } from "@mui/material";
import ReceiptLong from "@mui/icons-material/ReceiptLong";
import { Order } from "types/Order";
import { BillingDetail } from "types/BillingDetail";

import toast from "react-hot-toast";
import InvoiceService from "services/InvoiceService";
import { Dayjs } from "dayjs";

type CreateInvoiceProps = {
  orders: Order[];
  billingDetail: BillingDetail;
  fromDate: Dayjs;
  toDate: Dayjs;
};

const CreateInvoice: React.FC<CreateInvoiceProps> = ({ orders, billingDetail, fromDate, toDate }) => {
  let subTotal = 0;

  const [createInvoice, { isLoading }] = InvoiceService.useCreateInvoiceMutation();

  orders.forEach(({ productsTotal }) => {
    subTotal += Number(productsTotal);
  });

  const deliveryFee = subTotal * 0.1;
  const tax = deliveryFee * 0.13;
  const totalDeliveryFee = deliveryFee + tax;
  const total = subTotal - totalDeliveryFee;

  const handleCreateInvoice = () => {
    createInvoice({
      ordersIds: orders.map(({ id }) => id),
      billingDetailId: billingDetail.id,
      total: Number(total).toFixed(2),
      subtotal: Number(subTotal).toFixed(2),
      tax: Number(tax).toFixed(2),
      fromDateOrders: fromDate.format(),
      toDateOrders: toDate,
    })
      .then((response: any) => {
        if (response.error) {
          toast.error("Las ordenes seleccionadas ya tienen una factura generada");
        } else {
          toast.success("Factura generada correctamente");
        }
      })
      .catch(() => {
        toast.error("Error al generar factura");
      });
  };

  return (
    <Box pb={2}>
      {!billingDetail ? (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="warning">
            El comercio aún no ha creado su información de facturación, ponte en contacto con ellos para que, primero
            creen su información de facturación y puedas extenderles sus facturas
          </Alert>
        </Stack>
      ) : (
        <Button
          size="large"
          startIcon={<ReceiptLong />}
          onClick={handleCreateInvoice}
          disabled={!billingDetail || isLoading}
        >
          Generar Factura
        </Button>
      )}
    </Box>
  );
};

export default CreateInvoice;
