import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type PrivateRouteProps = {
  children: React.ReactNode;
  isAuthenticated: boolean;
};

const PrivateRoute = ({ children, isAuthenticated }: PrivateRouteProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return <>{children}</>;
  }
  return null;
};

export default PrivateRoute;
