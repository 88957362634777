import React, { useState } from "react";
import { CloudUpload, Delete, Title } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import chaletApi from "api/chaletApi";

type AddSectorProductTypeModalProps = {
  handleClose: () => void;
  isOpen: boolean;
};

type CreateSectorProductType = {
  name: string;
  key: string;
};

const schema = yup.object().shape({
  name: yup.string().required("El título es requerido"),
  key: yup.string().required("La llave de identificación es requerida"),
});

const AddSectorProductTypeModal: React.FC<AddSectorProductTypeModalProps> = ({ handleClose, isOpen }) => {
  const [createProductType, { isLoading }] = chaletApi.useCreateNewProductTypeMutation();

  const [iconImage, setIconImage] = useState<File | undefined>(undefined);
  const [previewIconImage, setPreviewIconImage] = useState<string | undefined>(undefined);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateSectorProductType>({
    defaultValues: {
      name: "",
      key: "",
    },
    resolver: yupResolver(schema),
  });

  const handleIconImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setIconImage(file);
      setPreviewIconImage(URL.createObjectURL(file));
    }
  };

  const clearIconImage = () => {
    setIconImage(undefined);
    setPreviewIconImage(undefined);
  };

  const handleCreateSectorProductType = async (data: CreateSectorProductType) => {
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("key", data.key);
    formData.append("iconImage", iconImage as Blob);

    toast.promise(createProductType(formData).unwrap(), {
      loading: "Creando tipo de producto de sector...",
      success: "Tipo de producto de sector creado exitosamente",
      error: "Error al crear el tipo de producto de sector",
    });

    handleClose();
    clearIconImage();
    reset();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          handleClose();
          clearIconImage();
          reset();
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box margin={8} component="form" onSubmit={handleSubmit(handleCreateSectorProductType)} noValidate>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              height: "auto",
              width: "20vw",
            }}
            elevation={4}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                ml: 2,
                mr: 2,
                mt: 2,
              }}
            >
              <Typography variant="h5" mb={1} textAlign="center">
                Crear nuevo tipo de Producto de Sector
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: 150,
                      height: 150,
                    }}
                  >
                    <Avatar
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 2,
                      }}
                      src={previewIconImage}
                    >
                      {!previewIconImage && (
                        <Button
                          component="label"
                          sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.7)",
                            },
                          }}
                        >
                          <CloudUpload style={{ color: "white" }} />
                          <input type="file" onChange={handleIconImage} style={{ display: "none" }} />
                        </Button>
                      )}
                    </Avatar>
                    {previewIconImage && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          color: "error.main",
                        }}
                        onClick={clearIconImage}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        required
                        {...register("name")}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Title />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Llave de identificación"
                        variant="outlined"
                        fullWidth
                        required
                        {...register("key")}
                        error={!!errors.key}
                        helperText={errors.key?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Title />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 4,
                  width: "50%",
                  alignSelf: "center",
                  mb: 4,
                }}
                disabled={!previewIconImage || isLoading}
              >
                Crear
              </Button>
            </Box>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default AddSectorProductTypeModal;
