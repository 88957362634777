import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import { Order } from "types/Order";
import InvoiceSubdetail from "./InvoiceSubdetail";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf",
      fontWeight: "semibold",
    },
    {
      src: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  tableRow: {
    flexDirection: "row",
    paddingTop: 4,
    paddingBottom: 4,
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderBottomColor: "#EF3D32",
    borderBottomWidth: 2,
    alignItems: "center",
    padding: 4,
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderBottomColor: "#EF3D32",
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    padding: 4,
  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
    fontFamily: "Open Sans",
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 10,
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  totalContainer: {
    marginTop: 24,
    textAlign: "right",
    paddingRight: 8,
  },
  totalText: {
    fontSize: 12,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    color: "#EF3D32", // Primary color for the total text
  },
  subDetailRow: {
    flexDirection: "row",
    backgroundColor: "#fac4c1", // Secondary color for subdetail row background
  },
  subDetailContainer: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  subDetailCol: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#EF3D32", // Primary color for subdetail borders
    borderWidth: 0,
    borderTopWidth: 1,
    padding: 2,
  },
  subDetailCell: {
    fontSize: 8,
    fontFamily: "Open Sans",
    textAlign: "left",
    paddingLeft: 8,
    color: "#000", // White text for readability on secondary color background
  },
  subDetailCellHeader: {
    fontSize: 8,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    textAlign: "left",
    paddingLeft: 8,
    color: "#000",
  },
});

type InvoiceDetailProps = { order: Order };

const InvoiceDetail = ({ order }: InvoiceDetailProps) => {
  const { details, productsTotal, createdAt, updatedAt, id } = order;
  const time = new Intl.DateTimeFormat("es-ES", {
    dateStyle: "short",
    timeStyle: "short",
    hour12: true,
    timeZone: "America/El_Salvador",
  }).format(new Date(createdAt));

  const deliveryTime = new Intl.DateTimeFormat("es-ES", {
    dateStyle: "short",
    timeStyle: "short",
    hour12: true,
    timeZone: "America/El_Salvador",
  }).format(new Date(updatedAt));
  return (
    <>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{id}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{time}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{deliveryTime}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{productsTotal}</Text>
        </View>
      </View>
      <View style={styles.subDetailContainer}>
        <View style={styles.subDetailRow}>
          <View style={styles.subDetailCol}>
            <Text style={styles.subDetailCellHeader}>Cantidad</Text>
          </View>
          <View style={styles.subDetailCol}>
            <Text style={styles.subDetailCellHeader}>Producto</Text>
          </View>
          <View style={styles.subDetailCol}>
            <Text style={styles.subDetailCellHeader}>Precio</Text>
          </View>
          <View style={styles.subDetailCol}>
            <Text style={styles.subDetailCellHeader}>Total</Text>
          </View>
        </View>
        {details.map((detail) => (
          <InvoiceSubdetail key={detail.id} detail={detail} />
        ))}
      </View>
    </>
  );
};

export default InvoiceDetail;
