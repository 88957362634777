import React, { useState } from "react";
import { AddBox } from "@mui/icons-material";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { AddProductTypeModal } from "./Modal";
import chaletApi from "api/chaletApi";

export const AddProductType = () => {
  const { palette } = useTheme();
  const { data: productTypes } = chaletApi.useGetProductTypesQuery();

  const [isOpenModal, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Card
        elevation={0}
        sx={{
          width: 170,
          height: 150,
          backgroundColor: "#F1DEDC",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          ":hover": {
            boxShadow: "0 0 0 0px #fff, 0 0 0 2px #ef3d32",
            cursor: "pointer",
          },
        }}
        onClick={handleOpen}
      >
        <AddBox
          sx={{
            width: 64,
            height: 64,
            color: palette.primary.main,
            marginBottom: "40px",
          }}
        />

        <Box
          sx={{
            backgroundColor: palette.primary.main,
            padding: "12px 11px 12px 11px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            height: "40px",
            bottom: 0,
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "fontWeightMedium",
              textAlign: "center",
            }}
          >
            Nuevo tipo de producto
          </Typography>
        </Box>
      </Card>
      <AddProductTypeModal handleClose={handleClose} isOpen={isOpenModal} productTypes={productTypes?.data} />
    </Box>
  );
};
