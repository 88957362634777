import React from "react";
import { Box } from "@mui/material";
import CreateDepositButton from "./CreateDepositButton";
import CreateDriverButton from "./CreateDriverButton";

export default function DriversContainer() {
  return (
    <>
      <CreateDepositButton />
      <CreateDriverButton />
    </>
  );
}
