import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "types/User";
import { LocalStorageService } from "services/LocalStorageService";
import chaletApi from "api/chaletApi";
const service = new LocalStorageService();

export interface AuthState {
  user: User | null;
  token: string | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      service.setItem("token", null);
      service.setItem("selectedCountry", null);
      state.token = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeApp.fulfilled, (state, action) => {
      const { token } = action.payload;
      state.token = token;
    });
    builder.addMatcher(
      chaletApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.success) {
          state.token = payload.data.token;
          state.user = payload.data.user;
          service.setItem("token", payload.data.token);
        }
      },
    );
    builder.addMatcher(
      chaletApi.endpoints.loginWithToken.matchFulfilled,
      (state, { payload }) => {
        if (payload.success) {
          state.token = payload.data.token;
          state.user = payload.data.user;
          service.setItem("token", payload.data.token);
        }
      },
    );
  },
});

export const initializeApp = createAsyncThunk("chaletapp", async () => {
  try {
    const token = await service.getItem<string>("token");
    const selectedCountryId = await service.getItem<number>(
      "selectedCountryId",
    );
    return { token, selectedCountryId };
  } catch (error) {
    console.log(error);
    return { token: null, selectedCountryId: null };
  }
});

export const { logOut } = authSlice.actions;

export default authSlice;
