import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { CreateEditVehicleType } from "./components/CreateEditVehicleType";
import VehicleTypesService from "services/VehicleTypesService";
import { VehicleType } from "types/VehicleType";
import { toCurrencyFormat } from "utils/formatter";

export const VehicleTypes = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [SelectedVehicleType, setSelectedVehicleType] = useState<VehicleType | null>(null);
  const { data: vehicleTypes } = VehicleTypesService.useGetVehicleTypesQuery();
  const [deleteVehicleType] = VehicleTypesService.useDeleteVehicleTypeMutation();

  const handleOpen = () => {
    setSelectedVehicleType(null);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedVehicleType(null);
  };

  const handleEditVehicleType = (type: VehicleType) => {
    setSelectedVehicleType(type);
    setIsOpen(true);
  };

  const handleDeleteVehicleType = async (id: number) => {
    await deleteVehicleType(id);
  };

  return (
    <Box>
      <Button sx={{ mb: 2 }} variant="contained" onClick={handleOpen}>
        Crear Tipo de vehiculo
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ width: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Tarifa</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vehicleTypes?.map((row) => (
              <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.type}
                </TableCell>
                <TableCell>{toCurrencyFormat(row.rates)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditVehicleType(row)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteVehicleType(row.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isOpen && <CreateEditVehicleType open={isOpen} handleClose={handleClose} vehicleType={SelectedVehicleType} />}
    </Box>
  );
};
