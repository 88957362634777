import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { Typography, Paper } from "@mui/material";
import dayjs from "dayjs";

type OrderTimestamps = {
  createdAt?: string;
  confirmedAt?: string;
  preparingAt?: string;
  readyAt?: string;
  deliveringAt?: string;
};

type Props = {
  timestamps: OrderTimestamps;
};

export const OrderTimeline: React.FC<Props> = ({ timestamps }) => {
  const formatDateWithTime = (dateString?: string) =>
    dateString ? dayjs(dateString).format("DD/MM/YYYY hh:mm A") : "N/A";

  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography variant="caption" color="textSecondary">
            Confirmed
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={timestamps.confirmedAt ? "primary" : "grey"} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} style={{ padding: "6px 16px" }}>
            <Typography variant="body2">{formatDateWithTime(timestamps.confirmedAt)}</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent>
          <Typography variant="caption" color="textSecondary">
            Preparing
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={timestamps.preparingAt ? "primary" : "grey"} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} style={{ padding: "6px 16px" }}>
            <Typography variant="body2">{formatDateWithTime(timestamps.preparingAt)}</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent>
          <Typography variant="caption" color="textSecondary">
            Ready
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={timestamps.readyAt ? "primary" : "grey"} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} style={{ padding: "6px 16px" }}>
            <Typography variant="body2">{formatDateWithTime(timestamps.readyAt)}</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent>
          <Typography variant="caption" color="textSecondary">
            Delivering
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={timestamps.deliveringAt ? "primary" : "grey"} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} style={{ padding: "6px 16px" }}>
            <Typography variant="body2">{formatDateWithTime(timestamps.deliveringAt)}</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};
