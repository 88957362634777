import React from "react";
import List from "@mui/material/List";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import { DriverSchedule } from "types/DriverSchedule";
import DriversListItem from "./DriversListItem";

interface DriverListProps {
  schedules: DriverSchedule[];
  setSelectedDriver: (driver: DriverSchedule) => void;
}

const DriverList: React.FC<DriverListProps> = ({ schedules, setSelectedDriver }) => {
  if (schedules.length === 0) {
    return <div>No drivers assigned to this shift</div>;
  }

  return (
    <Stack>
      <List sx={{ display: "flex", flexDirection: "row" }}>
        {schedules.map((schedule) => (
          <Box
            key={schedule.id}
            onClick={() => setSelectedDriver(schedule)}
            sx={{
              transition: "background-color 0.2s",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <DriversListItem schedule={schedule} />
          </Box>
        ))}
      </List>
    </Stack>
  );
};

export default DriverList;
