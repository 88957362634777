import { Box, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import { blue, cyan, green, grey, indigo } from "@mui/material/colors";
import React from "react";
import { toCurrencyFormat } from "utils/formatter";

type props = {
  totalSales: number;
  fee: number;
  iva: number;
  totalFee: number;
};
export const SalesAndProfits = ({ totalSales, fee, iva, totalFee }: props) => {
  const { palette } = useTheme();
  return (
    <Stack
      sx={{
        mt: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        backgroundColor: grey[100],
        padding: 2,
        borderRadius: 8,
        height: 350,
      }}
    >
      <Grid container direction={"row"}>
        <Grid item md={6}>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                width: 250,
                height: 150,
                mb: 2,
                color: palette.common.white,
                background: `linear-gradient(to bottom, ${green[400]}, ${green[800]})`,
              }}
            >
              <Typography>Ventas Totales</Typography>
              <Typography
                sx={{
                  fontSize: "1.8rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {toCurrencyFormat(totalSales)}
              </Typography>
            </Paper>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                width: 250,
                height: 150,
                mb: 2,
                color: palette.common.white,
                background: `linear-gradient(to bottom, ${blue[400]}, ${blue[800]})`,
              }}
            >
              <Typography>IVA</Typography>
              <Typography
                sx={{
                  fontSize: "1.8rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {toCurrencyFormat(iva)}
              </Typography>
            </Paper>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                width: 250,
                height: 150,
                mb: 2,
                color: palette.common.white,
                background: `linear-gradient(to bottom, ${cyan[400]}, ${cyan[900]})`,
              }}
            >
              <Typography>Comisión</Typography>
              <Typography
                sx={{
                  fontSize: "1.8rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {toCurrencyFormat(fee)}
              </Typography>
            </Paper>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                width: 250,
                height: 150,
                mb: 2,
                color: palette.common.white,
                background: `linear-gradient(to bottom, ${indigo[400]}, ${indigo[800]})`,
              }}
            >
              <Typography>IVA + Comisión</Typography>
              <Typography
                sx={{
                  fontSize: "1.8rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {toCurrencyFormat(totalFee)}
              </Typography>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
