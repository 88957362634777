import chaletApi from "api/chaletApi";
import { ChaletApiResponse } from "types/ChaletApiResponse";
import { Invoices } from "types/Invoices";

const InvoiceService = chaletApi.injectEndpoints({
  endpoints: (builder) => ({
    createInvoice: builder.mutation({
      query: ({ ordersIds, billingDetailId, total, subtotal, tax, fromDateOrders, toDateOrders }) => ({
        method: "POST",
        url: `/invoices`,
        body: {
          ordersIds,
          billingDetailId,
          total,
          subtotal,
          tax,
          fromDateOrders,
          toDateOrders,
        },
      }),
      invalidatesTags: ["invoices"],
    }),
    getInvoicesByBillingDetailId: builder.query<ChaletApiResponse<Invoices[]>, number>({
      query: (billingDetailId) => ({
        method: "GET",
        url: `/invoices/${billingDetailId}`,
      }),
      providesTags: ["invoices"],
    }),
  }),
  overrideExisting: false,
});

export default InvoiceService;
