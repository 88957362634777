import { ArrowRightAlt } from "@mui/icons-material";
import { Box, CircularProgress, Container, MenuItem, Select, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { Sector } from "types/Sector";
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, startOfDay, endOfDay } from "date-fns";
import { BranchDetailsCard } from "./BranchDetailsCard";

type invoicingProps = {
  sector: Sector;
};

export const Invoicing: React.FC<invoicingProps> = ({ sector }) => {
  const branches = sector.branches;
  const today = new Date();
  const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs(startOfDay(today)));
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs(endOfDay(today)));
  const [dateRange, setDateRange] = useState("Hoy");
  if (!branches) return <CircularProgress />;

  const handleDateRangeChange = (value: string) => {
    let newFromDate, newToDate;

    switch (value) {
      case "Hoy":
        newFromDate = startOfDay(today);
        newToDate = endOfDay(today);
        break;
      case "Semana":
        newFromDate = startOfWeek(today, { weekStartsOn: 1 });
        newToDate = endOfWeek(today, { weekStartsOn: 1 });
        break;
      case "Mes":
        newFromDate = startOfMonth(today);
        newToDate = endOfMonth(today);
        break;
      default:
        break;
    }
    setFromDate(dayjs(newFromDate));
    setToDate(dayjs(newToDate));
    setDateRange(value);
  };

  return (
    <Box display="flex" flex={1}>
      <Box flex={1}>
        <Typography variant="h4" mb={2}>
          Ordenes
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <Stack direction={{ xs: "column", sm: "row" }} justifyContent="center" alignItems="center" spacing={2}>
            <Select
              value={dateRange}
              onChange={(e) => handleDateRangeChange(e.target.value)}
              label="Rango de fechas"
              variant="outlined"
              style={{ minWidth: "90px" }}
            >
              <MenuItem value="Hoy">Hoy</MenuItem>
              <MenuItem value="Semana">Esta semana</MenuItem>
              <MenuItem value="Mes">Este mes</MenuItem>
            </Select>

            <Box display={"flex"} alignItems={"center"}>
              <DatePicker
                label="Fecha inicial"
                value={fromDate}
                onChange={(newValue) => {
                  setFromDate(newValue);
                }}
                sx={{ minWidth: "120px" }}
              />
              <ArrowRightAlt />
              <DatePicker
                label="Fecha final"
                value={toDate}
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
                sx={{ minWidth: "120px" }}
              />
            </Box>
          </Stack>
        </Box>
        <Box mt={2}>
          {fromDate && toDate && (
            <>
              {branches.map((branch) => (
                <BranchDetailsCard key={branch.id} fromDate={fromDate} toDate={toDate} branch={branch} />
              ))}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
