import { Card, Stack, useTheme } from "@mui/material";
import { GoogleMap, Marker, Polygon, Polyline } from "@react-google-maps/api";
import { useMemo } from "react";
import poly from "@mapbox/polyline";
import { keyBy } from "lodash";
import DriverIcon from "../../../../assets/ic_marker_driver.svg";
import ShopIcon from "../../../../../../../assets/ic_store.svg";

type Props = {
  sectorPolygon: any;
  mapCenter: any;
  showMessage: any;
  handleClose: any;
  offer: any;
};

export default function OffersMap({ sectorPolygon, mapCenter, offer }: Props) {
  const markers = useMemo(() => {
    if (!offer) return [];
    if (!offer.route) return [];
    if (!offer.route.driverWaypoints) return [];
    const marks: any[] = [];
    offer.orders.forEach(({ order }: any) => {
      console.log("order", order);
      marks.push({
        id: order.id,
        coordinates: order.branch.location.coordinates,
        icon: ShopIcon,
      });
      marks.push({
        id: order.id,
        coordinates: order.route.location.coordinates,
      });
    });
    return marks;
  }, [offer]);
  const polyline = useMemo(() => {
    if (!offer) return [];
    if (!offer.route || !offer.route.polyline) return [];
    const decodedPath = poly.decode(offer.route.polyline);
    return decodedPath.map(([latitude, longitude]) => ({
      lat: latitude,
      lng: longitude,
    }));
  }, [offer]);

  const theme = useTheme();
  return (
    <Card elevation={1}>
      <Stack>
        <GoogleMap mapContainerStyle={{ height: "700px", width: "100%" }} center={mapCenter} zoom={12}>
          <Polygon
            options={{
              fillColor: "rgba(239, 61, 50, 0.35)",
              strokeColor: theme.palette.primary.main,
            }}
            editable={false}
            draggable={false}
            path={sectorPolygon}
          />
          {markers.map((marker: any) => (
            <Marker
              key={marker.id}
              position={{
                lat: marker.coordinates[1],
                lng: marker.coordinates[0],
              }}
              icon={marker.icon}
              draggable={false}
            />
          ))}
          <Polyline
            path={polyline}
            options={{
              strokeColor: theme.palette.primary.main,
              strokeOpacity: 1,
              strokeWeight: 4,
            }}
          />
        </GoogleMap>
      </Stack>
    </Card>
  );
}
