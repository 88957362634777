import React from "react";
import { Box, Grid } from "@mui/material";
import { ListCustomerService } from "./ListCustomerService/ListCustomerService";
import { CreateCustomerService } from "./CreateCustomerService/CreateCustomerService";

export const CustomerService = () => {
  return (
    <Box display={"flex"} flex={1}>
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item sm={4} xs={12}>
          <CreateCustomerService />
        </Grid>
        <Grid item sm={8} xs={12} alignItems={"center"}>
          <ListCustomerService />
        </Grid>
      </Grid>
    </Box>
  );
};
