import chaletApi from "api/chaletApi";
import { ChaletApiResponse } from "types/ChaletApiResponse";
import { Travel } from "types/Travel";

const TravelService = chaletApi.injectEndpoints({
  endpoints: (builder) => ({
    getTravelsActive: builder.query<Travel[], number>({
      query: (sectorId) => ({
        method: "GET",
        url: `/travels/all/active/${sectorId}`,
      }),
      transformResponse: (response: ChaletApiResponse<Travel[]>) => response.data,
    }),
  }),
  overrideExisting: false,
});

export default TravelService;
