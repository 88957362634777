import { ArrowRightAlt } from "@mui/icons-material";
import { Box, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import chaletApi from "api/chaletApi";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { CardsInformation } from "./CardsInformation";
import useSelectedCountry from "hooks/useSelectedCountry";
import { SectorSalesChart } from "./Charts/SectorSalesChart";
import { CompletedOrdersChart } from "./Charts/CompletedOrdersChart";
import { SectorOrdersChart } from "./Charts/SectorOrdersChart";
import { SalesAndProfitsforCountry } from "./SalesAndProfitsforCountry";

const AdminDashboard = () => {
  const today = new Date();
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs(startOfDay(today)),
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs(endOfDay(today)));
  const [dateRange, setDateRange] = useState("Hoy");
  const { selectedCountryId } = useSelectedCountry();
  const [getCountryDashboard, result] =
    chaletApi.useLazyGetCountryDashboardQuery();

  useEffect(() => {
    if (selectedCountryId && fromDate && toDate) {
      const formatFromDate = dayjs(fromDate).format("YYYY/MM/DD");
      const formatToDate = dayjs(toDate).format("YYYY/MM/DD");

      getCountryDashboard({
        from: formatFromDate,
        to: formatToDate,
        countryId: selectedCountryId,
      });
    }
  }, [selectedCountryId, fromDate, toDate]);

  const handleDateRangeChange = (value: string) => {
    let newFromDate, newToDate;

    switch (value) {
      case "Hoy":
        newFromDate = startOfDay(today);
        newToDate = endOfDay(today);
        break;
      case "Semana":
        newFromDate = startOfWeek(today, { weekStartsOn: 1 });
        newToDate = endOfWeek(today, { weekStartsOn: 1 });
        break;
      case "Mes":
        newFromDate = startOfMonth(today);
        newToDate = endOfMonth(today);
        break;
      default:
        break;
    }
    setFromDate(dayjs(newFromDate));
    setToDate(dayjs(newToDate));
    setDateRange(value);
  };

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={5} md={5}>
        <Typography
          sx={{
            fontWeight: "800",
            fontSize: "40px",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          Dashboard
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7} md={7}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Select
            value={dateRange}
            onChange={(e) => handleDateRangeChange(e.target.value)}
            label="Rango de fechas"
            variant="outlined"
            style={{ minWidth: "90px" }}
          >
            <MenuItem value="Hoy">Hoy</MenuItem>
            <MenuItem value="Semana">Esta semana</MenuItem>
            <MenuItem value="Mes">Este mes</MenuItem>
          </Select>

          <Box display={"flex"} alignItems={"center"}>
            <DatePicker
              label="Fecha inicial"
              value={fromDate}
              onChange={(newValue) => {
                const start = dayjs(newValue).toDate();
                setFromDate(dayjs(startOfDay(start)));
              }}
              sx={{ minWidth: "120px" }}
            />
            <ArrowRightAlt />
            <DatePicker
              label="Fecha final"
              value={toDate}
              onChange={(newValue) => {
                const end = dayjs(newValue).toDate();
                setToDate(dayjs(endOfDay(end)));
              }}
              sx={{ minWidth: "120px" }}
            />
          </Box>
        </Stack>
      </Grid>
      {result.data && (
        <>
          <Grid item xs={12} sm={12} md={12}>
            <CardsInformation
              active={result.data.data.activeOrders}
              completed={result.data.data.completedOrders}
              canceled={result.data.data.canceledOrders}
              rejected={result.data.data.rejectedOrders}
              totalBranches={result.data.data.totalBranches}
              totalSectors={result.data.data.totalSectors}
            />
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item container spacing={2} xs={12} sm={12} md={12} lg={10}>
              <Grid item xs={12} sm={6} md={6}>
                <SectorOrdersChart data={result.data.data.sectorOrdersChart} />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <SectorSalesChart data={result.data.data.sectorSalesChart} />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <CompletedOrdersChart
                  orders={result.data.data.completedOrdersInRange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <SalesAndProfitsforCountry
                totalSales={result.data.data.totalSalesInRange}
                fee={result.data.data.fee}
                iva={result.data.data.iva}
                totalFee={result.data.data.totalFee}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AdminDashboard;
