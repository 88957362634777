import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import chaletApi from "api/chaletApi";
import { Sector } from "types/Sector";
import { Edit } from "@mui/icons-material";
import { useState } from "react";
import { EditSectorForm } from "./EditSectorForm";
import { SectorsMap } from "./SectorsMap";

type Props = {
  countryId: number;
  onSectorSelected: (id: Sector) => void;
};

export default function SectorsList({ countryId, onSectorSelected }: Props) {
  const { data, error, isFetching } = chaletApi.useGetSectorsQuery(countryId);
  const [sectorId, setSectorId] = useState<number | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleClose = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  if (error) {
    console.log(error);
    return <h2>error</h2>;
  }

  if (isFetching) {
    return <h1>Loading</h1>;
  }
  const sectorsList = data ?? [];

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {sectorsList.map((sector) => (
        <Box key={sector.id} sx={{ position: "relative" }}>
          <Card
            onClick={() => onSectorSelected(sector)}
            elevation={3}
            sx={{
              width: 345,
              margin: 1,
              ":hover": {
                boxShadow: "0 0 0 1px #fff, 0 0 0 3px #000",
                cursor: "pointer",
              },
            }}
          >
            <CardContent>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography flex={1} variant="h5" component="div">
                  {sector.name}
                </Typography>
              </Stack>

              <Typography variant="body2" color="text.secondary">
                Total de comercios: {sector.branches?.length}
              </Typography>
            </CardContent>
          </Card>
          <IconButton
            sx={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => {
              setSectorId(sector.id);
              setIsEditModalOpen(true);
            }}
          >
            <Edit />
          </IconButton>
        </Box>
      ))}

      {sectorId && (
        <EditSectorForm
          handleClose={() => {
            handleClose();
            setSectorId(null);
          }}
          isEditModalOpen={isEditModalOpen}
          sectorId={sectorId}
        />
      )}

      <SectorsMap sectors={sectorsList} />
    </Box>
  );
}
