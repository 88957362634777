import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Chip, Grid, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import chaletApi from "api/chaletApi";
import { Sector } from "types/Sector";
import OrdersMap from "./OrdersMap";
import { Paid, Receipt, Refresh } from "@mui/icons-material";
import { CardActiveOrders } from "./CardActiveOrders";
import DeliveryOffers from "./DeliveryOffers";
import OffersMap from "./OffersMap";
import { keys } from "lodash";

type ActiveOrdersProps = {
  sector: Sector;
};

const ActiveOrders: React.FC<ActiveOrdersProps> = ({ sector }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedOffer, setSelectedOffer] = React.useState<any>(null);

  const [getOrdersBySector, result] = chaletApi.useLazyGetActiveOrdersBySectorQuery();

  const [getAssigningOrders, assigningOrdersResult] = chaletApi.useLazyGetAssigningOrdersQuery();

  const sectorPolygon = sector.area.map((point) => ({
    lat: point[1],
    lng: point[0],
  }));

  const [showMessage, setShowMessage] = useState(true);

  const handleClose = () => {
    setShowMessage(!showMessage);
  };

  const mapCenter = useMemo(() => {
    const center = sectorPolygon.reduce(
      (acc, { lat, lng }) => {
        acc.lat += lat;
        acc.lng += lng;
        return acc;
      },
      { lat: 0, lng: 0 },
    );

    return {
      lat: center.lat / sectorPolygon.length,
      lng: center.lng / sectorPolygon.length,
    };
  }, [sectorPolygon]);

  useEffect(() => {
    getOrdersBySector(sector.id);
    getAssigningOrders(sector.id);
  }, [sector]);

  const reloadOrders = () => {
    getOrdersBySector(sector.id);
  };

  const reloadAssigningOrders = () => {
    getAssigningOrders(sector.id);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const assigningOrders = useMemo(() => {
    if (assigningOrdersResult.data) {
      return keys(assigningOrdersResult.data.data);
    }
    return [];
  }, [assigningOrdersResult]);

  if (result.data) {
    return (
      <Grid container margin="0 auto" maxWidth="xl">
        <Typography variant="h4" mt={2}>
          Active Orders
        </Typography>
        <Grid margin="0 auto" container item maxWidth={"xl"} mt={2} mb={2} gap={2}>
          <Stack flex={1}>
            {selectedTab === 0 && (
              <OrdersMap
                result={result}
                sectorPolygon={sectorPolygon}
                mapCenter={mapCenter}
                showMessage={showMessage}
                handleClose={handleClose}
              />
            )}
            {selectedTab === 1 && (
              <OffersMap
                sectorPolygon={sectorPolygon}
                mapCenter={mapCenter}
                showMessage={showMessage}
                handleClose={handleClose}
                offer={selectedOffer}
              />
            )}
          </Stack>

          {result.data.length > 0 ? (
            <Stack>
              <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
                <Tab icon={<Receipt />} label="ORDENES" />
                <Tab icon={<Paid />} label="OFERTAS DE ENTREGA" />
              </Tabs>
              {selectedTab === 0 && (
                <Stack alignItems="center" mt={2}>
                  <Button variant="contained" onClick={reloadOrders} style={{ maxWidth: 100 }}>
                    Reload
                  </Button>
                  {result.data.map((order: any) => (
                    <CardActiveOrders key={order.id} order={order} />
                  ))}
                </Stack>
              )}
              {selectedTab === 1 && (
                <Stack spacing={1}>
                  <Typography>Ordenes asignadas.</Typography>
                  <Stack direction="row" spacing={1}>
                    <IconButton onClick={reloadAssigningOrders}>
                      <Refresh />
                    </IconButton>
                    {assigningOrders.map((o: any) => (
                      <Chip label={o} color="primary" variant="outlined" />
                    ))}
                  </Stack>
                  <DeliveryOffers sectorId={sector.id} setSelectedOffer={setSelectedOffer} />
                </Stack>
              )}
            </Stack>
          ) : (
            <Typography>No active orders</Typography>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={{ width: "90vw", height: "auto" }}>
      <Typography variant="h6">No active orders</Typography>
    </Box>
  );
};

export default ActiveOrders;
