import { TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

export default function ShopOrdersTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>ID</TableCell>
        <TableCell>Cancelada</TableCell>
        <TableCell>Hora</TableCell>
        <TableCell>Entregado</TableCell>
        <TableCell>Total</TableCell>
      </TableRow>
    </TableHead>
  );
}
