import { ArrowRightAlt } from "@mui/icons-material";
import { Box, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import chaletApi from "api/chaletApi";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import useSelectedSector from "hooks/useSelectedSector";
import React, { useEffect, useState } from "react";
import { CardsInformation } from "./CardsInformation";
import { BestBranches } from "./BestBranches";
import { ProfitsAndLossesChart } from "./Charts";
import { SalesAndProfits } from "./SalesAndProfits";

export const Dashboard = () => {
  const today = new Date();
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs(startOfDay(today)),
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs(endOfDay(today)));
  const [dateRange, setDateRange] = useState("Hoy");
  const { selectedSectorId } = useSelectedSector();
  const [getDashboard, result] = chaletApi.useLazyGetSectorDashboardQuery();

  useEffect(() => {
    if (selectedSectorId && fromDate && toDate) {
      const formatFromDate = dayjs(fromDate).format("YYYY/MM/DD");
      const formatToDate = dayjs(toDate).format("YYYY/MM/DD");

      getDashboard({
        from: formatFromDate,
        to: formatToDate,
        sectorId: selectedSectorId,
      });
    }
  }, [selectedSectorId, fromDate, toDate]);

  const handleDateRangeChange = (value: string) => {
    let newFromDate, newToDate;

    switch (value) {
      case "Hoy":
        newFromDate = startOfDay(today);
        newToDate = endOfDay(today);
        break;
      case "Semana":
        newFromDate = startOfWeek(today, { weekStartsOn: 1 });
        newToDate = endOfWeek(today, { weekStartsOn: 1 });
        break;
      case "Mes":
        newFromDate = startOfMonth(today);
        newToDate = endOfMonth(today);
        break;
      default:
        break;
    }
    setFromDate(dayjs(newFromDate));
    setToDate(dayjs(newToDate));
    setDateRange(value);
  };

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={5} md={5}>
        <Typography
          sx={{
            fontWeight: "800",
            fontSize: "40px",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          Dashboard
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7} md={7}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Select
            value={dateRange}
            onChange={(e) => handleDateRangeChange(e.target.value)}
            label="Rango de fechas"
            variant="outlined"
            style={{ minWidth: "90px" }}
          >
            <MenuItem value="Hoy">Hoy</MenuItem>
            <MenuItem value="Semana">Esta semana</MenuItem>
            <MenuItem value="Mes">Este mes</MenuItem>
          </Select>

          <Box display={"flex"} alignItems={"center"}>
            <DatePicker
              label="Fecha inicial"
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              sx={{ minWidth: "120px" }}
            />
            <ArrowRightAlt />
            <DatePicker
              label="Fecha final"
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              sx={{ minWidth: "120px" }}
            />
          </Box>
        </Stack>
      </Grid>
      {result.data && (
        <>
          <Grid item xs={12} sm={12} md={12} style={{ paddingLeft: 0 }}>
            <CardsInformation
              active={result.data.activeOrders}
              completed={result.data.completedOrders}
              canceled={result.data.canceledOrders}
              rejected={result.data.rejectedOrders}
              totalBranches={result.data.totalBranches}
              totalSales={result.data.totalSalesInRange}
            />
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={6}>
              <ProfitsAndLossesChart
                totalSales={result.data.totalSalesInRange}
                totalLossesforCancel={result.data.totalLossesForCancelInRange}
                totalLossesforReject={result.data.totalLossesForRejectInRange}
                totalDelivery={result.data.totalDeliveryInRange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <SalesAndProfits
                totalSales={result.data.totalSalesInRange}
                fee={result.data.fee}
                iva={result.data.iva}
                totalFee={result.data.totalFee}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <BestBranches branches={result.data.branchesWithMostOrders} />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
