import { useMemo, useState } from "react";
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef, MRT_TableOptions } from "material-react-table";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { Add, Edit, Inventory } from "@mui/icons-material";
import chaletApi from "api/chaletApi";
import { MenuCategory } from "types/Shop";
import { ProductsTable } from "./ProductsTable";
import { openAddCategoryModal, openAddProductModal } from "redux/modalSlice";
import { useAppDispatch } from "redux/hooks";
import { AddProductModal } from "../AddProductModal";

type CategoriesTableProps = {
  data: MenuCategory[];
  productTypeId: number;
};

export const CategoriesTable: React.FC<CategoriesTableProps> = ({ data, productTypeId }) => {
  const dispatch = useAppDispatch();
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  const [categoryId, setCategoryId] = useState<number>(0);

  const columns = useMemo<MRT_ColumnDef<MenuCategory>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Categoria",
        size: 350,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.firstName,
          helperText: validationErrors?.firstName,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              firstName: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        header: "Productos Totales",
        enableGrouping: false,
        size: 350,
        enableEditing: false,
        Cell: ({ row }) => <Typography>{`Productos (${row.original.products?.length})`}</Typography>,
      },
    ],
    [validationErrors],
  );

  const [updateShopMenuCategory, { isLoading: isUpdatingMenuCategory }] = chaletApi.useUpdateShopMenuCategoryMutation();

  //update
  const handleUpdateCategory: MRT_TableOptions<MenuCategory>["onEditingRowSave"] = async ({ values, table, row }) => {
    const newValidationErrors = validateCategory(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateShopMenuCategory({ id: row.original.id, body: values });
    table.setEditingRow(null); //exit editing mode
  };

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      pagination: { pageSize: 15, pageIndex: 0 },
      showGlobalFilter: true,
    },
    editDisplayMode: "row",
    enableEditing: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableContainerProps: { sx: { maxHeight: 600 } },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.common.white,
    }),
    getRowId: (row) => String(row.id),

    //Crud Option
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleUpdateCategory,
    renderDetailPanel: ({ row }) => {
      if (row.original.products) {
        const products = row.original.products?.filter((producto) => producto.shopMenuCategoryId === row.original.id);

        const sortedProducts = products.sort((a, b) => a.id - b.id);

        if (products.length > 0) {
          return <ProductsTable data={sortedProducts} categoryId={row.original.id} productTypeId={productTypeId} />;
        } else {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h5">Esta categoria no tiene productos</Typography>
                <Button
                  sx={{ mt: 1 }}
                  startIcon={<Add />}
                  variant="contained"
                  onClick={() => {
                    dispatch(openAddProductModal());
                    setCategoryId(row.original.id);
                  }}
                >
                  Crear Producto
                </Button>
              </Box>
            </Box>
          );
        }
      }
    },
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Button
          startIcon={<Inventory />}
          variant="contained"
          onClick={() => {
            dispatch(openAddCategoryModal());
          }}
        >
          Crear Categoria
        </Button>
      </Box>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem", width: "50px" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      isSaving: isUpdatingMenuCategory,
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {categoryId != 0 && <AddProductModal categoryId={categoryId} productTypeId={productTypeId} />}
    </>
  );
};

//Function to Validate Category
const validateRequired = (value: string) => !!value.length;

function validateCategory(category: MenuCategory) {
  return {
    firstName: !validateRequired(category.name) ? "El nombre es Requerido" : "",
  };
}
