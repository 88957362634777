import { Box, Container } from "@mui/material";
import { CustomTab, CustomTabs, TabPanel } from "components/common/CustomTabs/CustomTabs";
import React, { useState } from "react";
import { Products } from "./Products/Products";
import { Services } from "./Services/Services";
import { SectorProductType } from "./Products/SectorProductType";

export const ProductsServices = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth={"xl"} sx={{ over: "auto" }}>
      <Box sx={{ width: "60%" }}>
        <CustomTabs value={value} onChange={handleChange}>
          <CustomTab label="Productos" />
          <CustomTab label="Servicios" />
        </CustomTabs>
      </Box>
      <Box>
        <SectorProductType />
      </Box>
      <TabPanel value={value} index={0}>
        <Products />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Services />
      </TabPanel>
    </Container>
  );
};
