import chaletApi from "api/chaletApi";
import { ChaletApiResponse } from "types/ChaletApiResponse";
import { TravelOffer } from "types/TravelOffer";

const TravelOfferService = chaletApi.injectEndpoints({
  endpoints: (builder) => ({
    getTravelOffersActive: builder.query<TravelOffer[], number>({
      query: (sectorId) => ({
        method: "GET",
        url: `/travel-offers/all/active/${sectorId}`,
      }),
      transformResponse: (response: ChaletApiResponse<TravelOffer[]>) => response.data,
    }),
  }),
  overrideExisting: true,
});

export default TravelOfferService;
