import { Grid } from "@mui/material";
import ActiveOrders from "./ActiveOrders";
import { Sector } from "types/Sector";

type DeliveriesTabProps = {
  sector: Sector;
};

const DeliveriesTab: React.FC<DeliveriesTabProps> = ({ sector }) => {
  return (
    <Grid container maxWidth={"xl"}>
      <ActiveOrders sector={sector} />
    </Grid>
  );
};

export default DeliveriesTab;
