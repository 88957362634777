import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";

type Props = {
  control: any;
  name: string;
  label: string;
  variant?: "filled" | "outlined" | "standard";
  gridSize?: number;
  options?: { value: string | number; label: string }[];
};

const ReusableSelect: React.FC<Props> = ({
  control,
  name,
  label,
  variant = "standard",
  gridSize = 6,
  options,
}) => {
  const { palette } = useTheme();

  if (!options) return <Typography>Loading...</Typography>;
  return (
    <Grid item xs={gridSize}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <FormControl fullWidth>
              <InputLabel>{label}</InputLabel>
              <Select variant={variant} label={label} {...field}>
                {options.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {fieldState.error && (
              <Typography fontSize={12} color={palette.error.main}>
                {fieldState.error.message}
              </Typography>
            )}
          </>
        )}
      />
    </Grid>
  );
};

export default ReusableSelect;
