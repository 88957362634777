import React from "react";
import { AppBar, Button, Card, Grid, Stack, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Sector } from "types/Sector";
import { grey } from "@mui/material/colors";
import useSelectedModule from "hooks/useSelectedModule";
import { ModuleType } from "redux/moduleSlice";

type SubAppBarProps = {
  data: Sector;
  children?: React.ReactNode;
};

const SubAppBar = ({ data, children }: SubAppBarProps) => {
  const navigate = useNavigate();

  const { changeModule } = useSelectedModule();

  const handleChangeModule = (module: ModuleType) => {
    changeModule(module);
  };

  const goTo = (route: string) => () => {
    navigate(route, { replace: false });
  };

  const redirectToDashboard = (id: number) => {
    const url = "/admin/sectors/:id/dashboard";
    const route = url.replace(":id", String(id));
    navigate(route, { replace: false });
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          top: 64,
          backgroundColor: grey[50],
          color: "black",
          zIndex: 2,
        }}
      >
        <Toolbar>
          <Grid container margin="0 auto" maxWidth="xl">
            <Grid item xs={6}>
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => redirectToDashboard(data.id)}
                variant="h5"
                fontWeight={"bold"}
                color={"primary"}
              >
                {data.name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button sx={{ fontWeight: "bold" }} size="large" onClick={() => handleChangeModule(ModuleType.Orders)}>
                  Panel de ordenes
                </Button>
                <Button sx={{ fontWeight: "bold" }} size="large" onClick={() => handleChangeModule(ModuleType.Travels)}>
                  Panel de viajes
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <div
        style={{
          marginTop: 75,
          maxHeight: "calc(100vh - 64px)",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default SubAppBar;
