import { Grid, Paper, Typography, styled } from "@mui/material";
import {
  amber,
  green,
  lightBlue,
  orange,
  purple,
  red,
} from "@mui/material/colors";
import {
  AccessTime,
  Cancel,
  Check,
  DoDisturb,
  Store,
} from "@mui/icons-material";

type props = {
  completed: number;
  active: number;
  canceled: number;
  rejected: number;
  totalBranches: number;
  totalSectors: number;
};

type paperProps = {
  color1: string;
  color2: string;
};

const CustomPaper = styled(Paper)<paperProps>(({ theme, color1, color2 }) => ({
  width: "200px",
  maxWidth: "200px",
  height: "140px",
  borderRadius: 32,
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: `linear-gradient(to bottom, ${color1}, ${color2})`,
  color: theme.palette.common.white,
  [theme.breakpoints.down("sm")]: {
    width: "120px",
    maxWidth: "120px",
    height: "140px",
    padding: "10px",
  },
}));

export const CardsInformation: React.FC<props> = ({
  completed,
  active,
  canceled,
  rejected,
  totalBranches,
  totalSectors,
}) => {
  return (
    <Grid
      container
      direction={"row"}
      spacing={1}
      gap={2}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CustomPaper elevation={3} color1={amber[400]} color2={amber[800]}>
        <AccessTime style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography textAlign={"center"}>Órdenes Activas</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {active}
        </Typography>
      </CustomPaper>

      <CustomPaper
        color1={lightBlue[400]}
        color2={lightBlue[800]}
        elevation={3}
      >
        <Check style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography textAlign={"center"}>Órdenes Completadas</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {completed}
        </Typography>
      </CustomPaper>

      <CustomPaper color1={red[400]} color2={red[800]} elevation={3}>
        <Cancel style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography textAlign={"center"}>Órdenes Canceladas</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {canceled}
        </Typography>
      </CustomPaper>
      <CustomPaper color1={orange[400]} color2={orange[800]} elevation={3}>
        <DoDisturb style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography textAlign={"center"}>Órdenes Rechazadas</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {rejected}
        </Typography>
      </CustomPaper>
      <CustomPaper color1={green[400]} color2={green[800]} elevation={3}>
        <Store style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography textAlign={"center"}>Total de Sectores</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {totalSectors}
        </Typography>
      </CustomPaper>
      <CustomPaper color1={purple[400]} color2={purple[800]} elevation={3}>
        <Store style={{ fontSize: "40px", marginBottom: "8px" }} />
        <Typography textAlign={"center"}>Total de Sucursales</Typography>
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "center" }}
        >
          {totalBranches}
        </Typography>
      </CustomPaper>
    </Grid>
  );
};
