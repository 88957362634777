import React from "react";
import {
  Avatar,
  Paper,
  Typography,
  Box,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { toCurrencyFormat } from "utils/formatter";

type props = {
  branches: [
    {
      id: string;
      name: string;
      profileImage: string;
      orderCount: number;
      totalSales: number;
    },
  ];
};

export const BestBranches: React.FC<props> = ({ branches }) => {
  const { palette } = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "10px",
        padding: "24px",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "450px",
        maxHeight: "600px",
        overflowY: "auto",
        backgroundColor: grey[100],
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.5rem",
            fontWeight: "600",
            marginBottom: "8px",
            textAlign: "center",
          }}
        >
          Mejores Sucursales
        </Typography>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        gap={2}
      ></Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Sucursal</TableCell>
              <TableCell align="right">Ordenes Completadas</TableCell>
              <TableCell align="right">Ventas Totales</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {branches?.map((branch, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  borderRadius: 100,
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Avatar src={branch.profileImage} />
                    <Typography fontWeight={"bold"}>{branch.name}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">{branch.orderCount}</TableCell>
                <TableCell align="right">
                  <Typography
                    color="#2e7d32"
                    fontWeight={"bold"}
                    fontSize={"14px"}
                  >
                    {toCurrencyFormat(branch.totalSales)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
