import { createSlice } from "@reduxjs/toolkit";
import { Shop } from "types/Shop";

export interface shopSlice {
  selectedShop: Shop | null;
}

const initialState: shopSlice = {
  selectedShop: null,
};

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setSelectedShop: (state, action) => {
      state.selectedShop = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setSelectedShop, reset } = shopSlice.actions;

export default shopSlice;
