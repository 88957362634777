import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { blue, cyan, green, grey, indigo } from "@mui/material/colors";
import React from "react";
import { toCurrencyFormat } from "utils/formatter";

type paperProps = {
  color1: string;
  color2: string;
};

const CustomPaper = styled(Paper)<paperProps>(({ theme, color1, color2 }) => ({
  width: "200px",
  height: "200px",
  minWidth: "140px",
  minHeight: "140px",
  borderRadius: 32,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: `linear-gradient(to bottom, ${color1}, ${color2})`,
  color: theme.palette.common.white,
  [theme.breakpoints.down("xl")]: {
    width: "140px",
    height: "140px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "200px",
    height: "200px",
  },
  [theme.breakpoints.down("md")]: {
    width: "150px",
    height: "150px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "140px",
    height: "140px",
  },
}));

type props = {
  totalSales: number;
  fee: number;
  iva: number;
  totalFee: number;
};
export const SalesAndProfitsforCountry = ({
  totalSales,
  fee,
  iva,
  totalFee,
}: props) => {
  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        backgroundColor: grey[100],
        borderRadius: 8,
        height: "100%",
        p: { xs: 2, sm: 2, md: 2, lg: 0 },
      }}
    >
      <Grid
        container
        direction={{ xs: "row", sm: "row", md: "column", lg: "row" }}
        gap={2}
        alignContent={"center"}
        justifyContent={"center"}
      >
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={12}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row", md: "row", lg: "column" },
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <CustomPaper elevation={2} color1={green[400]} color2={green[800]}>
            <Typography>Ventas Totales</Typography>
            <Typography
              sx={{
                fontSize: "1.8rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {toCurrencyFormat(totalSales)}
            </Typography>
          </CustomPaper>

          <CustomPaper elevation={2} color1={cyan[400]} color2={cyan[800]}>
            <Typography>Comisión</Typography>
            <Typography
              sx={{
                fontSize: "1.8rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {toCurrencyFormat(fee)}
            </Typography>
          </CustomPaper>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={12}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row", md: "row", lg: "column" },
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <CustomPaper elevation={2} color1={blue[400]} color2={blue[800]}>
            <Typography>IVA</Typography>
            <Typography
              sx={{
                fontSize: "1.8rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {toCurrencyFormat(iva)}
            </Typography>
          </CustomPaper>
          <CustomPaper elevation={2} color1={indigo[400]} color2={indigo[800]}>
            <Typography>IVA + Comisión</Typography>
            <Typography
              sx={{
                fontSize: "1.8rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {toCurrencyFormat(totalFee)}
            </Typography>
          </CustomPaper>
        </Grid>
      </Grid>
    </Stack>
  );
};
