// DriversSocket.tsx

import { useEffect, useState } from "react";
import io from "socket.io-client";
import { DriverSchedule } from "types/DriverSchedule";

interface MapType {
  driverId: number;
  coordinates: [number, number];
}

type Location = {
  driverId: number;
  latitude: number;
  longitude: number;
};

const getSocket = () => {
  return io(process.env.REACT_APP_BASE_URL as string);
};

export const useDriversSocket = (DriverSchedule: DriverSchedule[], sectorId: number) => {
  const [locations, setLocations] = useState<MapType | null>(null);
  const [socket, setSocket] = useState<any>(null);

  useEffect(() => {
    const socket = getSocket();
    socket.on("connect", () => {
      setSocket(socket);
      if (DriverSchedule.length > 0) {
        console.log("conected, waiting for driver location");
        listenDriverUpdates(sectorId, socket);
      }
    });

    return () => {
      socket.disconnect();
      setLocations(null);
      console.log("Socket disconnected");
    };
  }, [sectorId, DriverSchedule]);

  const onDriverUpdate = (locationUpdate: Location) => {
    setLocations({
      driverId: locationUpdate.driverId,
      coordinates: [locationUpdate.longitude, locationUpdate.latitude] as [number, number],
    });
  };

  const listenDriverUpdates = (sectorId: number, socket: any) => {
    socket.emit("join", "sector_" + sectorId);
    console.log("JOINED to: " + "sector_" + sectorId);
    socket.on("live_driver_location", (location: Location) => {
      console.log("NEW UPDATE", location);
      onDriverUpdate(location);
    });
  };

  return locations;
};
