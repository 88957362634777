import React, { useState } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import { Travel } from "types/Travel";
import { TravelCard } from "components/partials/TravelCard/TravelCard";
import { TravelMap } from "components/partials/TravelMap/TravelMap";

type props = {
  open: boolean;
  handleClose: () => void;
  travels: Travel[];
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export const CompletedTravelsModal: React.FC<props> = ({ open, handleClose, travels }) => {
  const [originCoordinates, setOriginCoordinates] = useState<google.maps.LatLngLiteral | null>(null);
  const [destinationCoordinates, setDestinationCoordinates] = useState<google.maps.LatLngLiteral | null>(null);

  const handleUpdateCoordinates = (origin: any, destination: any) => {
    setOriginCoordinates(origin);
    setDestinationCoordinates(destination);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="vehicle-type" aria-describedby="create-edit-vehicle-type">
      <Box sx={style}>
        <Typography id="vehicle-type" variant="h6" component="h2">
          Viajes realizados
        </Typography>
        <Grid container xl>
          <Grid item sm={4}>
            <Box
              sx={{
                height: "700px",
                overflowY: "auto",
              }}
            >
              {travels?.map((travel) => (
                <TravelCard
                  key={travel.id}
                  travel={travel}
                  travelOffer={travel.travelOffer}
                  showTransporDriver={false}
                  setCoordinates={handleUpdateCoordinates}
                />
              ))}
            </Box>
          </Grid>
          <Grid item sm={8}>
            <TravelMap originCoordinates={originCoordinates} destinationCoordinates={destinationCoordinates} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
