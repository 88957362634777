import { Route, Routes } from "react-router-dom";
import AdminPage from "components/pages/admin/AdminPage";
import { LoginPage, NotFoundPage, UnAuthorizedPage } from "components/pages";

export default function AdminApp() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/admin/*" element={<AdminPage />} />
      <Route path="/*" element={<NotFoundPage />} />
      <Route path="/unauthorized" element={<UnAuthorizedPage />} />
    </Routes>
  );
}
