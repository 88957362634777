import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { toast } from "react-hot-toast";
import { toCurrencyFormat } from "utils/formatter";
import { CircularProgress, Grid, Stack } from "@mui/material";
import { BranchOrdersTableRow } from "./BranchOrderTableRow";
import ShopOrdersTableHeader from "./ShopOrdersTableHeader";
import { Dayjs } from "dayjs";
import { Branch } from "types/Branch";
import chaletApi from "api/chaletApi";
import { CreateInvoice } from "../CreateInvoice";

type branchOrdersTableProps = {
  fromDate: Dayjs;
  toDate: Dayjs;
  branch: Branch;
};

export const BranchOrdersTable: React.FC<branchOrdersTableProps> = ({ fromDate, toDate, branch }) => {
  const { data, isError, error, isFetching } = chaletApi.useGetOrdersInRangebyBranchQuery({
    from: fromDate.format(),
    to: toDate.format(),
    branchId: branch.id,
  });

  React.useEffect(() => {
    if (isError) {
      toast("Error obteniendo ordenes " + JSON.stringify(error));
    }
  }, [isError, error]);

  if (!data) {
    return null;
  }
  let subTotal = 0;

  const orders = data.data.orders
    .filter(({ isCanceled }) => !isCanceled)
    .sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateA - dateB;
    });

  orders.forEach(({ productsTotal }) => {
    subTotal += Number(productsTotal);
  });

  const deliveryFee = subTotal * 0.1;
  const tax = deliveryFee * 0.13;
  const totalDeliveryFee = deliveryFee + tax;
  const total = subTotal - totalDeliveryFee;

  if (isFetching) {
    return <CircularProgress />;
  }

  if (orders.length === 0) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="h6">No hay ordenes para este rango de fechas</Typography>
      </Grid>
    );
  }
  return (
    <>
      <Stack direction="row" justifyContent="space-between" flex={1} alignItems="center">
        <CreateInvoice orders={orders} billingDetail={branch.billingDetail} fromDate={fromDate} toDate={toDate} />
      </Stack>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <ShopOrdersTableHeader />
          <TableBody>
            {orders.map((row) => (
              <BranchOrdersTableRow key={row.id} row={row} />
            ))}
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell>Total ventas</TableCell>
              <TableCell>
                <Typography>{toCurrencyFormat(subTotal)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell>Comisión (10%)</TableCell>
              <TableCell>
                <Typography>{toCurrencyFormat(deliveryFee)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell>IVA</TableCell>
              <TableCell>
                <Typography>{toCurrencyFormat(tax)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell>Comisión + IVA </TableCell>
              <TableCell>
                <Typography>{toCurrencyFormat(totalDeliveryFee)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell>Total a cancelar</TableCell>
              <TableCell>
                <Typography variant="h6">{toCurrencyFormat(total)}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
