import { Box, Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs } from "@mui/material";
import chaletApi from "api/chaletApi";
import dayjs, { Dayjs } from "dayjs";
import useSelectedSector from "hooks/useSelectedSector";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DriversMap } from "./DriversMap/DriversMap";
import { Sector } from "types/Sector";

type WeekDay = {
  date: string;
  value: string;
};

type DriversTabProps = {
  sector: Sector;
};

export const DriversTab: React.FC<DriversTabProps> = ({ sector }) => {
  const { selectedSectorId } = useSelectedSector();
  const [getShifts, getShiftsResponse] = chaletApi.useLazyGetAllShiftsInDateQuery();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const tabRef = useRef<any>(null);
  const [weekDays, setWeekDays] = useState<WeekDay[]>([]);
  const [selectedDay, setSelectedDay] = useState("");

  useEffect(() => {
    if (!selectedSectorId) return;
    const startDate = dayjs().startOf("week").toISOString();

    getShifts({ startOfWeek: startDate, sectorId: selectedSectorId });
  }, []);

  useEffect(() => {
    if (shiftTabs) {
      const currentDate = dayjs();
      shiftTabs.forEach((shift, index) => {
        const shiftStartDate = dayjs(shift.date);
        const shiftEndDate = dayjs(shift.end);
        if (currentDate.isAfter(shiftStartDate) && currentDate.isBefore(shiftEndDate)) {
          setSelectedTab(index);
          tabRef.current.updateScrollButtons();
          tabRef.current.updateIndicator();
        }
      });
    }
  }, [getShiftsResponse.data]);

  useEffect(() => {
    const startDate = dayjs().startOf("week"); // first day of the week
    const currentDay = dayjs().startOf("day"); // Get current day without time

    const getWeekDays = (startDate: Dayjs) => {
      const days: WeekDay[] = [];
      for (let i = 0; i < 7; i++) {
        const currentDate = startDate.add(i, "day");
        days.push({
          date: currentDate.format("MM/DD/YYYY"),
          value: currentDate.format(),
        });
      }
      return days;
    };

    const currentWeekDays = getWeekDays(startDate);

    setWeekDays(currentWeekDays);
    setSelectedDay(currentDay ? currentDay.format() : "");
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const shiftTabs = useMemo(() => {
    if (getShiftsResponse.data && selectedDay) {
      const filteredShifts = getShiftsResponse.data.filter((shift) => {
        const shiftDate = new Date(shift.date);
        const selectedDate = new Date(selectedDay);

        return shiftDate.toDateString() === selectedDate.toDateString();
      });

      return filteredShifts;
    }
    return null;
  }, [getShiftsResponse, selectedDay]);

  const handleDayChange = (event: SelectChangeEvent<string>) => {
    setSelectedDay(event.target.value);
  };

  return (
    <Container sx={{ maxWidth: { xs: 320, sm: 480, md: 600, lg: 1000 }, mt: 2 }}>
      <Box gap={2} display={"flex"} flexDirection={"row"} justifyContent={"center"}>
        <Box
          sx={{
            maxWidth: { xs: "100vw", sm: "100%" },
            bgcolor: "background.paper",
          }}
        >
          <Tabs action={tabRef} variant="scrollable" scrollButtons="auto" value={selectedTab} onChange={handleChange}>
            {shiftTabs && shiftTabs.map(({ id, name, start, end }) => <Tab key={id} label={name} />)}
          </Tabs>
        </Box>
        <Box>
          <FormControl sx={{ width: "200px" }}>
            <InputLabel id="day-of-week-label">Día de la semana</InputLabel>
            <Select
              labelId="day-of-week-label"
              label={"Dia de la semana"}
              value={selectedDay}
              onChange={handleDayChange}
            >
              {weekDays.map((day) => (
                <MenuItem key={day.value} value={day.value}>
                  {day.date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mt: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {shiftTabs && shiftTabs[selectedTab] && <DriversMap shift={shiftTabs[selectedTab]} sector={sector} />}
      </Box>
    </Container>
  );
};
