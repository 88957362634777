import {
  Alert,
  CircularProgress,
  Card,
  Typography,
  Box,
  Icon,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import chaletApi from "api/chaletApi";
import React, { forwardRef, useMemo, useState } from "react";
import { VirtuosoGrid } from "react-virtuoso";
import { styled } from "@mui/material/styles";
import { Device } from "types/User";
import {
  AndroidRounded,
  Apple,
  LocationOnRounded,
  MessageRounded,
  Person,
  RefreshRounded,
  Sort,
} from "@mui/icons-material";
import useSelectedCountry from "hooks/useSelectedCountry";
import { keyBy } from "lodash";
import { Sector } from "types/Sector";
import dayjs from "dayjs";
import { ClockIcon } from "@mui/x-date-pickers";

// Card styles
const CardContainer = styled(Card)({
  margin: 10,
  width: 350,
  height: 200,
});

const gridComponents: any = {
  List: forwardRef(({ style, children, ...props }: any, ref: any) => (
    <div
      ref={ref}
      {...props}
      style={{
        display: "flex",
        flexWrap: "wrap",
        ...style,
      }}
    >
      {children}
    </div>
  )),
  Item: ({ children, ...props }: any) => (
    <div
      {...props}
      style={{
        alignContent: "stretch",
      }}
    >
      {children}
    </div>
  ),
};

const ItemWrapper = ({ children, ...props }: any) => (
  <div
    {...props}
    style={{
      display: "flex",
      flex: 1,
      whiteSpace: "nowrap",
    }}
  >
    {children}
  </div>
);

// Main Component
export const DevicesPage = () => {
  const { selectedCountryId } = useSelectedCountry();
  const { data, isLoading, error, refetch } = chaletApi.useGetAllDevicesQuery();
  const { data: sectors } = chaletApi.useGetSectorsQuery(selectedCountryId || 1);
  const sectorsMap = useMemo(() => {
    return sectors ? keyBy(sectors, "id") : {};
  }, [sectors]);

  const [searchTerm, setSearchTerm] = useState("");
  const [platformFilter, setPlatformFilter] = useState("");
  const [sortBy, setSortBy] = useState("lastConnection"); // Sort by default
  const [excludeNonPlatform, setExcludeNonPlatform] = useState(false);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">Error: {JSON.stringify(error)}</Alert>;
  }

  const users = data?.data;
  if (users && sectors) {
    // Filter users by search term and platform
    const filteredUsers = users
      .filter((user) => {
        const matchesPlatform = platformFilter ? user.platform === platformFilter : true;
        const matchesPlatformCondition = excludeNonPlatform ? !!user.platform : true;
        return matchesPlatform && matchesPlatformCondition;
      })
      .sort((a, b) => {
        if (sortBy === "lastConnection") {
          return dayjs(b.lastConnection).diff(dayjs(a.lastConnection));
        }
        return 0;
      });

    return (
      <>
        <Box display="flex" flex={1} width="100%" height="100%" flexDirection="column" alignItems="center">
          <Box display="flex" flexDirection="row" gap={16} alignItems="center">
            {/* Platform Filter */}
            <FormControl style={{ marginBottom: "10px", minWidth: 150 }}>
              <InputLabel id="platform-filter-label">Filter by Platform</InputLabel>
              <Select
                variant="filled"
                labelId="platform-filter-label"
                value={platformFilter}
                onChange={(e) => setPlatformFilter(e.target.value)}
              >
                <MenuItem value="">
                  <em>All Platforms</em>
                </MenuItem>
                <MenuItem value="android">Android</MenuItem>
                <MenuItem value="ios">iOS</MenuItem>
              </Select>
            </FormControl>

            {/* Sort By Dropdown */}
            <FormControl style={{ marginBottom: "10px", minWidth: 150 }}>
              <InputLabel id="sort-by-label">Sort By</InputLabel>
              <Select
                variant="filled"
                labelId="sort-by-label"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
              >
                <MenuItem value="lastConnection">Last Connection</MenuItem>
              </Select>
            </FormControl>

            <IconButton onClick={() => refetch()}>
              <RefreshRounded fontSize="large" />
            </IconButton>
            {/* 
            <FormControlLabel
              label="Móvil unicamente"
              control={<Checkbox
                value={excludeNonPlatform}
                onChange={() => {
                  setExcludeNonPlatform(!excludeNonPlatform)
                }} />}
            /> */}
          </Box>

          {/* Grid View */}
          <VirtuosoGrid
            style={{ height: "80vh", width: "100%" }}
            totalCount={filteredUsers.length}
            components={gridComponents}
            itemContent={(index) => {
              const device = filteredUsers[index];
              const sector = sectorsMap[device.sectorId];
              return (
                <ItemWrapper>
                  <UserCard device={device} sector={sector} />
                </ItemWrapper>
              );
            }}
          />
        </Box>
      </>
    );
  }
  return null;
};

// UserCard Component to display each user's information
const UserCard = ({ device, sector }: { device: Device; sector: Sector | undefined }) => {
  const isAndroid = device.platform === "android";
  const isIos = device.platform === "ios";
  const lastConnectionTime = dayjs(device.lastConnection);
  const lessThan30Minutes = lastConnectionTime.diff(dayjs(), "minutes") > -10;
  const timeSinceLastConnection = lastConnectionTime.fromNow();
  return (
    <CardContainer>
      <Box key={device.id} display="flex" flexDirection="column" padding={1} gap={1}>
        <Box display="flex" flex={1} width="100%" gap={1} alignItems="center">
          <Icon>
            <Person />
          </Icon>
          {device.user ? <Typography variant="subtitle2">{device.user.name}</Typography> : "N/A"}
        </Box>
        <Box height={44} width="100%" justifyContent="center" display="flex">
          {isAndroid && <AndroidRounded fontSize="large" />}
          {isIos && <Apple fontSize="large" />}
        </Box>

        <Box display="flex" flex={1} width="100%" gap={1} alignItems="center">
          {lessThan30Minutes ? (
            <Box
              style={{
                width: 14,
                height: 14,
                backgroundColor: "green",
                borderRadius: 7,
                marginLeft: 6,
              }}
            />
          ) : (
            <ClockIcon />
          )}
          <Typography variant="body1">Activo {timeSinceLastConnection}</Typography>
        </Box>

        <Box display="flex" flex={1} width="100%" gap={1} alignItems="center">
          <Icon>
            <LocationOnRounded />
          </Icon>
          {sector ? <Typography variant="subtitle2">{sector.name}</Typography> : "N/A"}
        </Box>
        <Box display="flex" flex={1} width="100%" gap={1}>
          <Icon>
            <MessageRounded />
          </Icon>
          {device.fcmToken ? (
            <Typography
              style={{
                whiteSpace: "normal",
                overflow: "hidden",
                wordWrap: "break-word",
              }}
              variant="caption"
            >
              {device.fcmToken}
            </Typography>
          ) : (
            "N/A"
          )}
        </Box>
      </Box>
    </CardContainer>
  );
};
