import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import React, { useState } from "react";
import { License } from "types/Licenses";
import { Vehicle } from "types/Vehicles";
import { formatDate } from "utils/formatter";

type props = {
  open: boolean;
  handleClose: () => void;
  licenses: License[];
  vehicles: Vehicle[];
  lastLocation: google.maps.LatLngLiteral | null;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export const PersonalDataModal: React.FC<props> = ({ open, handleClose, vehicles, licenses, lastLocation }) => {
  console.log(lastLocation);
  const [modalVisible, setModalVisible] = useState(true);

  const showImg = (src: string) => {
    setModalVisible(false);
    Fancybox.show([{ src }], {
      on: {
        close: () => setModalVisible(true),
      },
    });
  };

  return (
    <Modal
      open={open && modalVisible}
      onClose={handleClose}
      aria-labelledby="vehicle-type"
      aria-describedby="create-edit-vehicle-type"
    >
      <Box sx={style}>
        <Grid container xl>
          <Grid item sm={7} p={1}>
            <Typography variant="h6" component="h2">
              Licencias
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>N. Tarjeta</TableCell>
                    <TableCell>Fecha de expiración</TableCell>
                    <TableCell>Foto frontal</TableCell>
                    <TableCell>Foto trasera</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {licenses.map((license) => (
                    <TableRow key={license.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {license.number}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatDate(license.expires)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          component="a"
                          onClick={() => showImg(license.frontPhoto)}
                        >
                          Ver
                        </Button>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          component="a"
                          onClick={() => showImg(license.backPhoto)}
                        >
                          Ver
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h6" component="h2">
              Vehiculos
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Placa</TableCell>
                    <TableCell>Modelo</TableCell>
                    <TableCell>Color</TableCell>
                    <TableCell>Año</TableCell>
                    <TableCell>Pasajeros permitidos</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicles.map((vehicle) => (
                    <TableRow key={vehicle.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {vehicle.plate}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {vehicle.model}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {vehicle.color}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatDate(vehicle.year)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {vehicle.maxPassengers}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={5}>
            <GoogleMap
              center={lastLocation || { lat: 14.040564740651831, lng: -88.9359117588296 }}
              zoom={15}
              mapContainerStyle={{ height: "700px", width: "100%" }}
            >
              {lastLocation && <Marker position={lastLocation} />}
            </GoogleMap>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
