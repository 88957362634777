export const pictureValidation = (
  file: File,
  setImage: React.Dispatch<React.SetStateAction<File | null>>,
  setPreviewUrl: React.Dispatch<React.SetStateAction<string | null>>,
) => {
  const allowedExtensions = [".jpeg", ".jpg", ".png"];
  const fileExtension = "." + file.name.split(".").pop()?.toLowerCase();

  if (
    file.type.startsWith("image/") &&
    fileExtension &&
    allowedExtensions.includes(fileExtension)
  ) {
    setImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  } else {
    setImage(null);
    setPreviewUrl(null);
    alert("Por favor, selecciona una imagen válida (JPEG, JPG o PNG).");
  }
};
