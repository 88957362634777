import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayjs from "dayjs";

import { useMemo, useState } from "react";

import { Modal } from "@mui/material";
import EventPopup from "./EventPopup";
import useSelectedSector from "hooks/useSelectedSector";
import { Shift } from "types/Shift";

export default function ScheduleCalendar({ shifts, startDate, endDate, setStartDate, setEndDate }: any) {
  const { selectedSectorId } = useSelectedSector();
  const [currentView, setCurrentView] = useState("timeGridWeek");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoverEvent, setHoverEvent] = useState(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleEventClick = (mouseEnterInfo: any) => {
    console.log(mouseEnterInfo.event);
    setHoverEvent(mouseEnterInfo.event);
    setAnchorEl(mouseEnterInfo.el); // Use the event element as the anchor
  };

  const shiftEvents = useMemo(() => {
    if (!shifts) return [];
    const events: any[] = [];
    shifts.forEach((shift: Shift) => {
      events.push({
        title: shift.name,
        start: shift.date,
        end: shift.end,
        shift,
      });
    });
    return events;
  }, [shifts]);

  const handleDatesSet = (info: any) => {
    const currentView = info.view.type;
    setStartDate(dayjs(info.startStr));
    setEndDate(dayjs(info.endStr));
    setCurrentView(currentView);
  };

  const handleAddShift = () => {
    switch (currentView) {
      case "timeGridWeek":
        handleAddWeekShift();
        break;
      case "timeGridDay":
        handleAddDayShift();
        break;
      default:
        break;
    }
  };

  const handleAddWeekShift = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setHoverEvent(null);
    setAnchorEl(null);
  };

  const handleAddDayShift = () => {
    console.log("add day shift");
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const renderEventContent = (eventInfo: any) => {
    return (
      <>
        <div>{eventInfo.timeText}</div>
        <div>{eventInfo.event.title}</div>
      </>
    );
  };

  console.log(shiftEvents);
  return (
    <>
      {hoverEvent && <EventPopup event={hoverEvent} anchorEl={anchorEl} onClose={handleClosePopover} />}
      <FullCalendar
        initialDate={dayjs().toDate()}
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="timeGridWeek"
        events={shiftEvents}
        datesSet={handleDatesSet}
        eventClick={handleEventClick}
        height="auto"
      />
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <>{/* <CreateEditWeeklySchedule onCancel={handleCloseModal} /> */}</>
      </Modal>
    </>
  );
}
