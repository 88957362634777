import React from "react";
import { Box, Card, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Travel } from "types/Travel";
import { formatDateWithTime, toCurrencyFormat } from "utils/formatter";
import { TravelOffer } from "types/TravelOffer";

type TravelCardProps = {
  travel?: Travel;
  travelOffer: TravelOffer;
  showTransporDriver: boolean;
  setCoordinates: (origin: any, destination: any) => void;
};

export const TravelCard: React.FC<TravelCardProps> = ({ travel, travelOffer, setCoordinates, showTransporDriver }) => {
  const { palette } = useTheme();

  const updateCoordinates = () => {
    const data = {
      origin: {
        lat: travelOffer.origin.coordinates[0],
        lng: travelOffer.origin.coordinates[1],
      },
      destination: {
        lat: travelOffer.destination.coordinates[0],
        lng: travelOffer.destination.coordinates[1],
      },
    };
    setCoordinates(data.origin, data.destination);
  };

  return (
    <Card
      sx={{
        margin: 1,
        display: "flex",
        position: "relative",
        minWidth: "300px",
        height: "210px",
        cursor: "pointer",
      }}
      elevation={4}
      onClick={updateCoordinates}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          ml: 2,
          mr: 2,
          mt: 2,
        }}
      >
        <Box>
          <Typography textAlign="left" variant="subtitle2" color="primary">
            {formatDateWithTime(travel ? travel.createdAt : travelOffer.createdAt)}
          </Typography>
        </Box>

        <Box
          mt={1}
          flex={1}
          sx={{
            overflowX: "hidden",
          }}
        >
          <Typography variant="subtitle2" mb={1} textAlign="center">
            Detalles del viaje
          </Typography>
          <Box flex={1} ml={1} mr={1}>
            {/* Passenger details */}
            <Stack direction={"row"} spacing={1}>
              <Typography sx={{ fontSize: 14 }} fontWeight={"bold"}>
                Pasajero:
              </Typography>
              <Typography sx={{ fontSize: 14 }}>{travelOffer.user.name}</Typography>
            </Stack>

            {/* Driver details */}
            {travel && showTransporDriver && (
              <Stack direction={"row"} spacing={1}>
                <Typography sx={{ fontSize: 14 }} fontWeight={"bold"}>
                  Conductor:
                </Typography>
                <Typography sx={{ fontSize: 14 }}>{travel.transportDriver.user.name}</Typography>
              </Stack>
            )}

            {/* Origin address */}
            <Stack direction={"row"} spacing={1}>
              <Typography sx={{ fontSize: 14 }} fontWeight={"bold"}>
                Origen:
              </Typography>
              <Typography sx={{ fontSize: 14 }}>{travelOffer.originAddress}</Typography>
            </Stack>
            {/* Destination address */}
            <Stack direction={"row"} spacing={1}>
              <Typography sx={{ fontSize: 14 }} fontWeight={"bold"}>
                Destino:
              </Typography>
              <Typography sx={{ fontSize: 14 }}>{travelOffer.destinationAddress}</Typography>
            </Stack>

            <Divider sx={{ m: 1 }} />
            {/* Price info */}
            <Stack direction={"row"} spacing={1}>
              <Typography sx={{ fontSize: 14 }} fontWeight={"bold"}>
                Precio:
              </Typography>
              <Typography sx={{ fontSize: 14 }}>{toCurrencyFormat(travelOffer.price)}</Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          backgroundColor: palette.primary.main,
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 2,
          borderRadius: "0px  0px 0px 10px",
        }}
      >
        <Typography color="white" fontWeight={600}>
          {travel ? travel.id : travelOffer.id}
        </Typography>
      </Box>
    </Card>
  );
};
