import { Button, Stack } from "@mui/material";
import chaletApi from "api/chaletApi";
import { useEffect } from "react";
import OfferCard from "./OfferCard";
import { DeliveryOffer } from "types/DeliveryOffer";

type Props = {
  sectorId: number;
  setSelectedOffer: any;
};

export default function DeliveryOffers({ sectorId, setSelectedOffer }: Props) {
  const [getOffers, offerResult, a] = chaletApi.useLazyGetAllOffersBySectorQuery();
  const sortedOffers = offerResult.data?.data?.offers
    ?.slice() // Create a shallow copy of the array
    .sort((a: any, b: any) => {
      const statusOrder: any = { active: 1, accepted: 2, rejected: 3 };
      return (statusOrder[a.status] || 3) - (statusOrder[b.status] || 3);
    });

  useEffect(() => {
    reloadOffers();
  }, [sectorId]);

  const reloadOffers = () => {
    getOffers(sectorId);
  };

  const handleSelectOffer = (offer: any) => {
    setSelectedOffer(offer);
  };

  return (
    <Stack width={500} spacing={1}>
      <Button variant="contained" onClick={reloadOffers} style={{ maxWidth: 100 }}>
        Reload
      </Button>
      {sortedOffers?.map((offer: DeliveryOffer) => (
        <OfferCard
          key={offer.id}
          offer={offer}
          onSelect={handleSelectOffer}
          elapsedMaxTime={offerResult.data?.data.toleratedOfferElapsedTime || 0}
        />
      ))}
    </Stack>
  );
}
