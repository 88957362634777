import { Box, Container } from "@mui/material";
import { CustomTab, CustomTabs, TabPanel } from "components/common/CustomTabs/CustomTabs";
import React, { useState } from "react";
import { ActiveTravelOffers } from "./ActiveTravelOffers/ActiveTravelOffers";
import { ActiveTravels } from "./ActiveTravels/ActiveTravels";

export const TravelsTab = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Container maxWidth={"xl"} sx={{ over: "auto" }}>
      <Box sx={{ width: "60%" }}>
        <CustomTabs value={value} onChange={handleChange}>
          <CustomTab label="Viajes activos" />
          <CustomTab label="Ofertas de viajes activos" />
        </CustomTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ActiveTravels />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ActiveTravelOffers />
      </TabPanel>
    </Container>
  );
};
