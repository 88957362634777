export const handleApiError = (error: any) => {
  if (!error) return null;
  let msg = "Error: ";
  if ("status" in error && "data" in error) {
    msg += `${error.status} - `;
    const errorData = error.data;
    if (typeof error.data === "string") {
      msg += `${error.data} - `;
    } else {
      if ("message" in errorData) {
        msg += `${errorData?.message} - `;
      }
      if ("error" in errorData) {
        msg += `${errorData?.error} - `;
      }
      if ("data" in errorData) {
        msg += `${errorData?.data} - `;
      }
    }
  }
  if ("message" in error) {
    msg += `${error.message}`;
  }
  return msg;
};
