import { Card, CardMedia, Typography, useTheme } from "@mui/material";

import React from "react";
import { ProductType, SectorProductType } from "types/ProductType";

type ProductTypeCardProps = {
  productType: ProductType;
};

export const ProductTypeCard: React.FC<ProductTypeCardProps> = ({ productType }) => {
  const { palette } = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        width: 170,
        height: 132,
        borderRadius: "12px",
        backgroundColor: palette.primary.main,
      }}
    >
      <CardMedia component="img" height="92" image={productType.icon} alt="sectorProductType" />

      <Typography mt={1} fontWeight={"fontWeightMedium"} textAlign={"center"} color={palette.common.white}>
        {productType.name}
      </Typography>
    </Card>
  );
};
