import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import chaletApi from "api/chaletApi";
import useSelectedSector from "hooks/useSelectedSector";
import { LoadingIndicator } from "components/common";

export const ListCustomerService = () => {
  const { selectedSectorId } = useSelectedSector();
  const { data, isLoading } = chaletApi.useGetCustomersServicesQuery(selectedSectorId);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (data.data) {
    const users = data.data;
    console.log(users);
    return (
      <TableContainer variant="elevation" sx={{ width: 750, maxHeight: 393 }} elevation={3} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Teléfono</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user: any) => (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="left">{user.user.id}</TableCell>
                <TableCell align="left">{user.user.name}</TableCell>
                <TableCell align="left">{user.user.email}</TableCell>
                <TableCell align="left">{user.user.phone}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return null;
};
