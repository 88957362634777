import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  GoogleMap,
  InfoWindowF,
  Marker,
  Polygon,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone-lite";
import { Controller, useForm } from "react-hook-form";
import chaletApi from "api/chaletApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
};

type LatLng = {
  lat: number;
  lng: number;
};
const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

const sectorSchema = yup.object().shape({
  sectorName: yup.string().required("El nombre del sector es requerido"),
  adminName: yup.string().required("el nombre del administrador es requerido"),
  phone: yup
    .string()
    .max(9, "8 digits max")
    .phone("SV", "numero invalido")
    .required("telefono requerido"),
  email: yup
    .string()
    .email("formato de email invalido")
    .required("email es requerido"),
  tmpPassword: yup.string(),
});

type props = {
  handleClose: () => void;
  isEditModalOpen: boolean;
  sectorId: number;
};

export const EditSectorForm: React.FC<props> = ({
  handleClose,
  isEditModalOpen,
  sectorId,
}) => {
  const [markers, setMarkers] = useState<LatLng[]>([]);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null,
  );
  const [center, setCenter] = useState<google.maps.LatLng | undefined>(
    new window.google.maps.LatLng(14.04127919725104, -88.93810918198251),
  );
  const [updateSector] = chaletApi.useUpdateSectorMutation();
  const [getSectorById, result] = chaletApi.useLazyGetSectorQuery();
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sectorSchema),
    defaultValues: {
      sectorName: "",
      adminName: "",
      phone: "",
      email: "",
      tmpPassword: "",
    },
  });

  useEffect(() => {
    if (sectorId) {
      getSectorById(sectorId)
        .unwrap()
        .then((sector) => {
          setValue("sectorName", sector.name);

          if (sector.sectorWorkers.length > 0) {
            setValue("adminName", sector.sectorWorkers[0].user.name);
            setValue("email", sector.sectorWorkers[0].user.email);
            setValue("phone", MaskNumber(sector.sectorWorkers[0].user.phone));
          }

          const sectorPolygon = sector.area.map((point) => ({
            lat: point[1],
            lng: point[0],
          }));

          setMarkers(sectorPolygon);
        });
    }
  }, [sectorId]);

  useEffect(() => {
    //Calculate the center of the polygon when the cord exists.
    if (markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((coord) => bounds.extend(coord));
      const center = bounds.getCenter();
      setCenter(center);
    }
  }, [markers]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const clickedCoords = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      setMarkers((prevMarkers) => [...prevMarkers, clickedCoords]);
    }
  };

  const handleMarkerDrag = (
    index: number,
    event: google.maps.MapMouseEvent,
  ) => {
    if (event.latLng) {
      const draggedCoords = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      const updatedMarkers = [...markers];
      updatedMarkers[index] = draggedCoords;

      setMarkers(updatedMarkers);
    }
  };

  const handleMarkerRightClick = (
    event: google.maps.MapMouseEvent,
    index: number,
  ) => {
    if (event.latLng) {
      event.stop();
      setSelectedMarkerIndex(index);
    }
  };

  const handleDeleteMarker = () => {
    if (selectedMarkerIndex !== null) {
      const updatedMarkers = [...markers];
      updatedMarkers.splice(selectedMarkerIndex, 1);

      setMarkers(updatedMarkers);
    }
    setSelectedMarkerIndex(null);
  };

  const handleDeleteAll = () => {
    setMarkers([]);
  };

  const saveSector = (data: any) => {
    if (markers.length > 0 && result.data) {
      const formatedCoords = markers.map(({ lat, lng }) => [lng, lat]);

      const sectorData = {
        sectorName: data.sectorName,
        adminId: result.data.sectorWorkers[0].userId,
        adminName: data.adminName,
        email: data.email,
        tmpPassword: data.tmpPassword,
        phone: data.phone,
        area: formatedCoords,
      };
      updateSector({ sectorId, body: sectorData })
        .then(() => {
          closeModal();
          setMarkers([]);
        })
        .catch(() => {
          alert("Ha ocurrido un error");
        });
    }
  };

  const closeModal = () => {
    reset();
    handleClose();
  };

  return (
    <Modal
      open={isEditModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} component={"form"} onSubmit={handleSubmit(saveSector)}>
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          sx={{ padding: 2, borderRadius: "5px" }}
        >
          <Typography>Editar sector</Typography>
          <Typography>Administrador de sector</Typography>
        </Box>
        <Divider />
        <Grid container>
          <Grid item md={5.9} pt={2} pl={2} pr={2}>
            <Box>
              <Box>
                <Controller
                  name="sectorName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      label="Nombre del sector"
                      placeholder=""
                      type="text"
                      error={!!errors.sectorName}
                      helperText={
                        errors.sectorName ? errors.sectorName.message : ""
                      }
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem></Divider>
          <Grid item md={6} p={2}>
            <Stack spacing={1}>
              <Box display={"flex"}>
                <Controller
                  name="adminName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      sx={{ mr: 1 }}
                      label="Nombre"
                      placeholder=""
                      size="small"
                      type="text"
                      error={!!errors.adminName}
                      helperText={
                        errors.adminName ? errors.adminName.message : ""
                      }
                    />
                  )}
                />
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      type="tel"
                      inputMode="numeric"
                      label="Teléfono"
                      size="small"
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                      onChange={(event) => {
                        const { value } = event.target;
                        field.onChange(MaskNumber(value));
                      }}
                      value={field.value}
                    />
                  )}
                />
              </Box>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Email"
                    placeholder=""
                    size="small"
                    type="text"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                  />
                )}
              />
              <Controller
                name="tmpPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Contraseña temporal (opcional)"
                    placeholder=""
                    size="small"
                    type="text"
                    error={!!errors.tmpPassword}
                    helperText={
                      errors.tmpPassword ? errors.tmpPassword.message : ""
                    }
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ position: "relative" }}>
          <GoogleMap
            zoom={12}
            options={{ fullscreenControl: false, streetViewControl: false }}
            mapContainerStyle={{ width: "100%", height: "450px" }}
            center={center}
            onClick={handleMapClick}
          >
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={marker}
                draggable={true}
                onDrag={(event) => handleMarkerDrag(index, event)}
                onRightClick={(event) => handleMarkerRightClick(event, index)}
              >
                {selectedMarkerIndex === index ? (
                  <InfoWindowF
                    onCloseClick={() => setSelectedMarkerIndex(null)}
                  >
                    <Box>
                      <IconButton onClick={handleDeleteMarker}>
                        <Delete color="error" />
                      </IconButton>
                    </Box>
                  </InfoWindowF>
                ) : null}
              </Marker>
            ))}
            {markers.length > 0 && (
              <Polygon
                paths={markers}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                }}
              />
            )}
            <Button
              variant="contained"
              style={{ position: "absolute", top: 10, right: 10 }}
              onClick={handleDeleteAll}
            >
              Limpiar Todo
            </Button>
          </GoogleMap>
          <Box
            style={{
              position: "absolute",
              top: markers.length === 0 ? -45 : -25,
              left: 10,
            }}
          >
            <Typography>Área de cobertura</Typography>
            {markers.length === 0 && (
              <Typography variant="subtitle2" color={"red"}>
                Selecciona el área de cobertura
              </Typography>
            )}
          </Box>
        </Box>
        <Divider />
        <Box display={"flex"} justifyContent={"flex-end"} gap={1} m={1}>
          <Button variant="contained" onClick={() => handleClose()}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={markers.length === 0}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
