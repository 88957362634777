import React, { useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  styled,
  Toolbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Inbox, Mail } from "@mui/icons-material";
import useSelectedModule from "hooks/useSelectedModule";
import { ModuleType } from "redux/moduleSlice";

const CustomListItemButton = styled(ListItemButton)({
  "&.Mui-selected": {
    backgroundColor: "#AEECFF",
  },
  ":hover": {
    backgroundColor: "#AEECFF",
  },
});

const drawerWidth = 300;

const CustomListItemText = styled(ListItemText)({
  marginLeft: "30px",
});

export const Menu = () => {
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);
  const { selectedModule } = useSelectedModule();

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        zIndex: 1,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto", marginTop: "70px" }}>
        {selectedModule === ModuleType.Orders && (
          <Box>
            <List component="nav" aria-label="reports" subheader={<ListSubheader>Reportes</ListSubheader>}>
              <Link to="ordenes" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
                  <CustomListItemText primary="Ordenes" />
                </CustomListItemButton>
              </Link>
              <Link to="clientes" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                  <CustomListItemText primary="Clientes" />
                </CustomListItemButton>
              </Link>
              <Link to="devices" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
                  <CustomListItemText primary="Dispositivos" />
                </CustomListItemButton>
              </Link>
            </List>
            <Divider />
            <List component="nav" aria-label="tools" subheader={<ListSubheader>Herramientas</ListSubheader>}>
              <Link to="notificaciones" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
                  <CustomListItemText primary="Notificaciones" />
                </CustomListItemButton>
              </Link>
            </List>
            <Divider />
            <List component="nav" aria-label="sales" subheader={<ListSubheader>Ventas</ListSubheader>}>
              <Link to="facturacion" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
                  <CustomListItemText primary="Facturación" />
                </CustomListItemButton>
              </Link>
              <Link to="facturas-generadas" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton selected={selectedIndex === 5} onClick={(event) => handleListItemClick(event, 5)}>
                  <CustomListItemText primary="Facturas generadas" />
                </CustomListItemButton>
              </Link>
            </List>
            <Divider />
            <List component="nav" aria-label="sales" subheader={<ListSubheader>Administración</ListSubheader>}>
              <Link to="activeOrders" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton selected={selectedIndex === 6} onClick={(event) => handleListItemClick(event, 6)}>
                  <CustomListItemText primary="Ordenes activas" />
                </CustomListItemButton>
              </Link>
              <Link to="liveDrivers" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton selected={selectedIndex === 7} onClick={(event) => handleListItemClick(event, 7)}>
                  <CustomListItemText primary="Drivers en vivo" />
                </CustomListItemButton>
              </Link>
              <Link to="shifts" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton selected={selectedIndex === 8} onClick={(event) => handleListItemClick(event, 8)}>
                  <CustomListItemText primary="Horarios" />
                </CustomListItemButton>
              </Link>
              <Link to="branches" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton selected={selectedIndex === 9} onClick={(event) => handleListItemClick(event, 9)}>
                  <CustomListItemText primary="Sucursales" />
                </CustomListItemButton>
              </Link>
              <Link to="products-services" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton
                  selected={selectedIndex === 10}
                  onClick={(event) => handleListItemClick(event, 10)}
                >
                  <CustomListItemText primary="Productos  y Servicios" />
                </CustomListItemButton>
              </Link>
              <Link to="servicio-al-cliente" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton
                  selected={selectedIndex === 11}
                  onClick={(event) => handleListItemClick(event, 11)}
                >
                  <CustomListItemText primary="Servicio al cliente" />
                </CustomListItemButton>
              </Link>
              <Link to="drivers" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton
                  selected={selectedIndex === 12}
                  onClick={(event) => handleListItemClick(event, 12)}
                >
                  <CustomListItemText primary="Drivers" />
                </CustomListItemButton>
              </Link>
            </List>
          </Box>
        )}

        {selectedModule === ModuleType.Travels && (
          <Box>
            <List component="nav" aria-label="sales" subheader={<ListSubheader>Administración</ListSubheader>}>
              <Link to="travels" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton
                  selected={selectedIndex === 12}
                  onClick={(event) => handleListItemClick(event, 12)}
                >
                  <CustomListItemText primary="Viajes" />
                </CustomListItemButton>
              </Link>
              <Link to="transportDrivers" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton
                  selected={selectedIndex === 13}
                  onClick={(event) => handleListItemClick(event, 13)}
                >
                  <CustomListItemText primary="Conductores" />
                </CustomListItemButton>
              </Link>
              <Link to="vehicleTypes" style={{ textDecoration: "none", color: "inherit" }}>
                <CustomListItemButton
                  selected={selectedIndex === 14}
                  onClick={(event) => handleListItemClick(event, 14)}
                >
                  <CustomListItemText primary="Tipos de vehiculos" />
                </CustomListItemButton>
              </Link>
            </List>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};
